export const ActionTypes = {
    SET_TUTORIAL_LINK: "app/redux-core/help/SET_TUTORIAL_LINK",
    SET_LOGIN_GUIDE:"app/redux-core/help/SET_LOGIN_GUIDE",
    SET_LOGINACCESS_GUIDE:"app/redux-core/help/SET_LOGINACCESS_GUIDE",
    SET_TOUR_POPUP:"app/redux-core/help/SET_TOUR_POPUP",
    SET_REGISTER_GUIDE:"app/redux-core/help/SET_REGISTER_GUIDE",
    SET_VERIFY_REGISTER_GUIDE:"app/redux-core/help/SET_VERIFY_REGISTER_GUIDE",
    SET_MPIN_GUIDE:"app/redux-core/help/SET_MPIN_GUIDE",
    SET_CATEGORY_GUIDE:"app/redux-core/help/SET_CATEGORY_GUIDE",
    SET_REQUIREMENT_GUIDE:"app/redux-core/help/SET_REQUIREMENT_GUIDE",
    SET_REQUIREMENT_APPLY_GUIDE:"app/redux-core/help/SET_REQUIREMENT_APPLY_GUIDE",
    SET_REQUIREMENT_RECRUIT_GUIDE:"app/redux-core/help/SET_REQUIREMENT_RECRUIT_GUIDE",
    SET_PROFILE_GUIDE:"app/redux-core/help/SET_PROFILE_GUIDE",
    SET_EDITPROFILE_GUIDE:"app/redux-core/help/SET_EDITPROFILE_GUIDE",
    SET_MATCH_GUIDE:"app/redux-core/help/SET_MATCH_GUIDE",
    SET_EDITMATCH_GUIDE:"app/redux-core/help/SET_EDITMATCH_GUIDE",
    SET_DASHBOARD_GUIDE:"app/redux-core/help/SET_DASHBOARD_GUIDE",
    SET_DASHBOARD_COMPLETED_GUIDE:"app/redux-core/help/SET_DASHBOARD_COMPLETED_GUIDE",
    SET_TERMS_GUIDE:"app/redux-core/help/SET_TERMS_GUIDE",
    SET_USER_REGISTRATION_GUIDE:"app/redux-core/help/SET_USER_REGISTRATION_GUIDE",
    SET_HELP_GUIDE:"app/redux-core/help/SET_HELP_GUIDE",


    
}