export const editRateTour=[
    {
      selector: ".ant-collapse-header-text",
      content:()=>( <div>
        {/* {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click here to view the status/ position of your order</h4>: <h4>अपने आदेश की स्थिति देखने के लिए यहां क्लिक करें </h4>
        }  */}
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click here to view the status/ position of your order</h4>: localStorage.getItem('language')=='hi'? <h4>अपने आदेश की स्थिति देखने के लिए यहां क्लिक करें </h4>: localStorage.getItem('language')=='bn'?<h4>আপনার অর্ডারের অবস্থা/পজিশন দেখতে এখানে ক্লিক করুন</h4>:<h4>உங்கள் ஆர்டரின் நிலை/நிலையைப் பார்க்க இங்கே கிளிக் செய்யவும்</h4>
        } 
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    }
]