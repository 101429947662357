import axios from "axios";
import constants from "../constants";

axios.defaults.baseURL = constants.API_URL;

/**
 * Get all the updated count from the backend.
 * @returns
 */

  export const getUpdatedCount = async () => {
    try {
      const response = await axios.get("/api/getcount");
    //  console.log('-->>',response.countdata)
      return response;
    } catch (error) {
      throw error;
    }
  };


/**
 * set the updated count 
 * @returns
 */
export const setUpdatedCount = async () => {
  try {
    const response = await axios.put("/api/updatecount");
    return response;
  } catch (error) {
    throw error;
  }
};