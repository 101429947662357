import { Image } from "antd";

export const userRegistrationTour = [
  {
    selector: "#basic_isCompany",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Use this switch to register as individual or organization (if you
            have GST select Organization)
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            व्यक्तिगत या संगठन के रूप में पंजीकरण करने के लिए इस स्विच का उपयोग
            करें (यदि आपके पास GST चयनित संगठन है)
          </h4>
        ) : localStorage.getItem("language") == "bn" ?(
          <h4>
            ব্যক্তি বা প্রতিষ্ঠান হিসাবে নিবন্ধন করতে এই সুইচটি ব্যবহার করুন
            (যদি আপনার জিএসটি থাকে তবে সংগঠন নির্বাচন করুন)
          </h4>
        ):<h4>
        தனிநபர் அல்லது நிறுவனமாக பதிவு செய்ய இந்த சுவிட்சைப் பயன்படுத்தவும் (நீங்கள் இருந்தால்
        ஜிஎஸ்டி தேர்வு அமைப்பு வேண்டும்)
      </h4>}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-btn-primary",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Once you have filled all the details, click on this next button
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>सभी विवरण भरने के बाद, इस अगले बटन पर क्लिक करें</h4>
        ) : localStorage.getItem("language") == "bn" ?(
          <h4>
            একবার আপনি সমস্ত বিবরণ পূরণ করলে, এই পরবর্তী বোতামে ক্লিক করুন
          </h4>
        ):<h4>
        அனைத்து விவரங்களையும் பூர்த்தி செய்தவுடன், இந்த அடுத்த பொத்தானைக் கிளிக் செய்யவும்
      </h4>}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".cancel",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            If you wish not to proceed with your userRegistration details, click
            on this Cancel button.{" "}
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            यदि आप अपने उपयोगकर्ता पंजीकरण विवरण के साथ आगे नहीं बढ़ना चाहते
            हैं, तो इस रद्द करें बटन पर क्लिक करें
          </h4>
        ) : localStorage.getItem("language") == "bn" ?(
          <h4>
            আপনি যদি আপনার ব্যবহারকারীর নিবন্ধন বিবরণ নিয়ে এগিয়ে যেতে না চান,
            তাহলে এই বাতিল বোতামে ক্লিক করুন।{" "}
          </h4>
        ):<h4>உங்கள் பயனர் பதிவு விவரங்களைத் தொடர விரும்பவில்லை என்றால், இந்த ரத்துசெய் பொத்தானைக் கிளிக் செய்யவும்.{" "}</h4>}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
];
