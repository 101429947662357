// import { GoogleApiWrapper, InfoWindow, Map, Marker, GoogleMap } from "google-maps-react"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { redirectAction } from "../../redux-core/login/actions";
import { sendPostAction } from "../../redux-core/post/actions";
import { makeSelectPost } from "../../redux-core/post/selectors";
import location from "../../images/location.png";
import { makeSelectTradeMap } from "../../redux-core/trademap/selectors";
import { getTradeMapAction } from "../../redux-core/trademap/actions";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import Map from "./Map";
import { GOOGLE_MAP_KEY } from "../../utils/constants";
import { Button } from 'antd';
import './TradeMap.css'
import { withRouter } from "react-router";
import { useTranslation } from 'react-i18next';


const actionDispatch = (dispatch) => ({
  sendPost: (payload) => dispatch(sendPostAction(payload)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
  tradeDetail: (props, payload) => dispatch(getTradeMapAction(props, payload))

});


const postSelector = createSelector(makeSelectPost, (post) => ({
  post,
}));

const tradeMapStateSelector = createSelector(makeSelectTradeMap, (tradeMapDetail) => ({
  tradeMapDetail,
}));


const TradeMap = (props) => {

  const [hireData, setHireData] = useState([]);
  const [showingInfoWindow, SetWindow] = useState(true);
  const [activeMarker, SetactiveMarker] = useState({});
  const [selectedPlace, SetselectedPlace] = useState({});
  const { tradeMapDetail } = useSelector(tradeMapStateSelector);

  const { post } = useSelector(postSelector);
  const [location, SetLocation] = useState();
  const { t, i18n } = useTranslation();


  const { sendPost, redirect, tradeDetail } = actionDispatch(useDispatch())
  const params = new URLSearchParams(props.location.search);

  const getData = async () => {
    return post.filter(jobtype => jobtype.cat_name === params.get('category') && jobtype.job_type === params.get('job')).map((item, index) => (
      tradeDetail(props, {
        "cat_name": item.cat_name,
        "cat_id": item.cat_id,
        "type": item.job_type,
        "cat_unit": item.cat_unit
      })
    ))
  };

  useEffect(() => {
    setHireData(post);
    onMarkerClick();
    //alert("s");

  }, [post, tradeMapDetail]);

  const { history } = props;

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        redirect(props, '/requirement')
      }
    }
  })

  if(!window.localStorage.getItem("userToken")){
    window.location.href='/#/login'
  }

  const onMarkerClick = (props, marker, e) => {
    SetselectedPlace(props);
    SetactiveMarker(marker);
    SetWindow(true);
  }


  const onMapClicked = (props) => {
    SetWindow(false);
    SetactiveMarker(null);

  };

  //   let iconMarker = new window.google.maps.MarkerImage(
  //     location,
  //     null, /* size is determined at runtime */
  //     0,0, /* origin is 0,0 */
  //     null, /* anchor is bottom center of the scaled image */
  //     new window.google.maps.Size(70, 40)
  // );

  var iconPin = {
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
    fillColor: "blue",
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 1,
    scale: 1
  };

  const handleBackButtonClicked = () => {
    redirect(props, '/requirement')
  }

  return (
    <div className="trade-map-container">
      <Map
        googleMapURL={GOOGLE_MAP_KEY}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: '100vh', width: '100%' }} />}
        mapElement={<div style={{ width: '100%', height: `100%` }} />}
        center={{ lat: 21.9923319, lng: 76.22455 }}
        zoom={5}
        places={tradeMapDetail}
      />
      <div className="trade-map-button-container">
        <Button type="primary" className="trade-back-button" onClick={handleBackButtonClicked}><span>{t('back_to_resources')}</span></Button>
      </div>
    </div>
  );
}

export default withRouter(TradeMap)