import React from "react";
import './PendingTab.css';
import { useDispatch, useSelector } from "react-redux";
import { DELETE_ALERT } from "../../../utils/constants";
import { Card, Image, Popconfirm } from "antd";
import moment from "moment";
import { createSelector } from "reselect";
import {deleteOrderAction,getPendingOrderAction,} from "../../../redux-core/dashboard/actions";
import { redirectAction } from "../../../redux-core/login/actions";

import { makeSelectPendingOrder } from "../../../redux-core/dashboard/selectors";
import { useTranslation } from "react-i18next";
import { EyeOutlined, DeleteFilled, HomeOutlined } from "@ant-design/icons";
import Loader from "../../loader/loader";

const pendingStateSelector = createSelector(
  makeSelectPendingOrder,
  (pendingOrder) => ({
    pendingOrder,
  })
);

const actionDispatch = (dispatch) => ({
  getPendingOrder: () => dispatch(getPendingOrderAction()),
  deletePendingOrder: (pendingId) => dispatch(deleteOrderAction(pendingId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const PendingTab = (props) => {
  const { pendingOrder } = useSelector(pendingStateSelector);
  const { t, i18n } = useTranslation();
  const { getPendingOrder, deletePendingOrder, redirect } = actionDispatch(
    useDispatch()
  );

  /**
   * function for getting pending order and delete pending order
   **/

  const deleteOrderCall = (id) => {
    deletePendingOrder(id);
    getPendingOrder();
  };

  return (
    <div className="mainPendingTab">
      {pendingOrder?.data?.map((item, index) => (
        
          <Card
            bordered={true}
            key={index}
            className="dashboardCompleted_Card"
          
          >
            <div className="main_container">
              <div className="main_child_container">
                <div className="container-one">
                  <span className="name_icon">
                    <span className="category_name">
                      <b>{item.category_name} </b>
                    </span>
                  </span>
                </div>

                <div className="container-two">
                  <span className="quantity_part">
                    <span className="quantity" style={{ color: "gray" }}>
                      {t("quantity")} :{" "}
                    </span>
                    <b className="itemRate"> {item.pending} </b>
                  </span>
                  <span className="unit_part">
                    <span className="category_unit" style={{ color: "gray" }}>
                      {" "}
                      {t("rate")} / {item.cat_unit} :{" "}
                    </span>
                    <b className="itemRate">
                      {" "}
                      {t("rs")} {item.rate}
                    </b>
                  </span>
                </div>
                <div className="container-three">
                  <span className="date_part">
                    <span className="quantity" style={{ color: "gray" }}>
                      {t("order_date")} :{" "}
                    </span>
                    <b className="itemRate">
                      {" "}
                      {moment(item.date).format("YYYY-MM-DD")}{" "}
                    </b>
                  </span>
                </div>
                <div className="container_four">
                  <span className="location">
                    <b>{item.location}</b>
                  </span>
                </div>
                <div className="container-third">
                <hr></hr>

                  <span className="econtract_rating">
                    <span
                      className=" view_btn"
                      onClick={(event) => {
                        event.stopPropagation();
                        props.editrate();
                      }}
                    >
                      <EyeOutlined
                        style={
                          item.type === "Buyer"
                            ? {
                                color: "rgb(255, 150, 0)",
                              }
                            : {
                                color: "rgb(78, 138, 228)",
                              }
                        }
                      />{" "}
                      <span>
                        <b>{t('view')}</b>
                      </span>
                    </span>
                    {item.type === "Seller" ? (
                      <span className="verticalLine"></span>
                    ) : (
                      ""
                    )}
                    <Popconfirm
                      placement="rightBottom"
                      title={DELETE_ALERT}
                      onConfirm={(e) => deleteOrderCall(item._id)}
                      okText={t('yes')}
                      cancelText={t('no')}
                      icon
                    >
                      <span
                        className="cancel_order"
                        style={
                          item.type === "Buyer"
                            ? { visibility: "hidden", float: "right" }
                            : { float: "right" }
                        }
                      >
                        <DeleteFilled style={{ color: "rgb(78, 138, 228)" }} />{" "}
                        <span>
                          <b>{t('cancel_order')}</b>
                        </span>
                      </span>
                    </Popconfirm>
                  </span>
                 
                </div>
              </div>
              <div className="icon_class">
                <span className="category_icon" style={{ color: "gray" }}>
                 <Image style={{height:'60px',width:'60px'}} src={item.category_image} preview={false}/>
                </span>
               
              </div>
            </div>
          </Card>
        
      ))}
    </div>
  );
};

export default PendingTab;
