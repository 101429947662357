import { ActionTypes } from "./constants";
import { notification } from "antd";

export const setTutorialLink = (tutorialLink) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SET_TUTORIAL_LINK,
        payload: tutorialLink,
      });
    } catch (err) {
      console.error(err?.response?.data?.message);
      notification.error({
        message: "Link Not Found",
        description:
          err?.response?.data?.message ||
          "Please check your network connection",
        onClick: () => {},
      });
    }
  };
};

export const settourGuide = (actiontype, value) => {
  return async (dispatch) => {
    try {
      switch (actiontype) {
        case ActionTypes.SET_LOGIN_GUIDE:
          dispatch({
            type: ActionTypes.SET_LOGIN_GUIDE,
            payload: value,
          });
          break;
        case ActionTypes.SET_LOGINACCESS_GUIDE:
          dispatch({
            type: ActionTypes.SET_LOGINACCESS_GUIDE,
            payload: value,
          });
          break;
        case ActionTypes.SET_TOUR_POPUP:
          dispatch({
            type: ActionTypes.SET_TOUR_POPUP,
            payload: value,
          });
          break;
        case ActionTypes.SET_REGISTER_GUIDE:
          dispatch({
            type: ActionTypes.SET_REGISTER_GUIDE,
            payload: value,
          });
          break;

        case ActionTypes.SET_VERIFY_REGISTER_GUIDE:
          dispatch({
            type: ActionTypes.SET_VERIFY_REGISTER_GUIDE,
            payload: value,
          });
          break;

        case ActionTypes.SET_MPIN_GUIDE:
          dispatch({
            type: ActionTypes.SET_MPIN_GUIDE,
            payload: value,
          });
          break;

        case ActionTypes.SET_CATEGORY_GUIDE:
          dispatch({
            type: ActionTypes.SET_CATEGORY_GUIDE,
            payload: value,
          });
          break;
        case ActionTypes.SET_REQUIREMENT_GUIDE:
          dispatch({
            type: ActionTypes.SET_REQUIREMENT_GUIDE,
            payload: value,
          });
          break;
        case ActionTypes.SET_REQUIREMENT_APPLY_GUIDE:
          dispatch({
            type: ActionTypes.SET_REQUIREMENT_APPLY_GUIDE,
            payload: value,
          });
          break;
        case ActionTypes.SET_REQUIREMENT_RECRUIT_GUIDE:
          dispatch({
            type: ActionTypes.SET_REQUIREMENT_RECRUIT_GUIDE,
            payload: value,
          });
          break;
        case ActionTypes.SET_PROFILE_GUIDE:
          dispatch({
            type: ActionTypes.SET_PROFILE_GUIDE,
            payload: value,
          });
          break;
          case ActionTypes.SET_EDITPROFILE_GUIDE:
            dispatch({
              type: ActionTypes.SET_EDITPROFILE_GUIDE,
              payload: value,
            });
            break;
            case ActionTypes.SET_MATCH_GUIDE:
            dispatch({
              type: ActionTypes.SET_MATCH_GUIDE,
              payload: value,
            });
            break;
            case ActionTypes.SET_EDITMATCH_GUIDE:
            dispatch({
              type: ActionTypes.SET_EDITMATCH_GUIDE,
              payload: value,
            });
            break;
            case ActionTypes.SET_DASHBOARD_GUIDE:
            dispatch({
              type: ActionTypes.SET_DASHBOARD_GUIDE,
              payload: value,
            });
            break;
            case ActionTypes.SET_DASHBOARD_COMPLETED_GUIDE:
            dispatch({
              type: ActionTypes.SET_DASHBOARD_COMPLETED_GUIDE,
              payload: value,
            });
            break;
            case ActionTypes.SET_TERMS_GUIDE:
            dispatch({
              type: ActionTypes.SET_TERMS_GUIDE,
              payload: value,
            });
            break;
            case ActionTypes.SET_USER_REGISTRATION_GUIDE:
            dispatch({
              type: ActionTypes.SET_USER_REGISTRATION_GUIDE,
              payload: value,
            });
            break;
            case ActionTypes.SET_HELP_GUIDE:
            dispatch({
              type: ActionTypes.SET_HELP_GUIDE,
              payload: value,
            });
            break;
            
      }
    } catch (err) {
      console.error(err?.response?.data?.message);
      notification.error({
        message: "Link Not Found",
        description:
          err?.response?.data?.message ||
          "Please check your network connection",
        onClick: () => {},
      });
    }
  };
};
