import React from "react";
import './CommonHeader.css'

/**
 * Common Header Page
 * @param {*} imageUrl
 * @param {*} headerText
 * @param {*} subTitle
 * @returns 
*/
const CommonHeader = ({ imageUrl, headerText, subTitle }) => {
    return (
        <div className="common-container">
            <div className="common-header-container">
                <div className="common-header-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                <div className="common-header-content">
                    <div className="common-header-subcontent">
                        <div>
                            {headerText && <h1 className="common-text-style" >{headerText}</h1>}
                            {subTitle && <p className="common-subtext-style">{subTitle}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommonHeader