import { Tabs } from "antd";
import React from "react";
import AboutUs from "../aboutus/AboutUs";
import CancellationPolicy from "../cancellationpolicy/CancellationPolicy";
import PrivacyPolicy from "../privacypolicy/privacypolicy";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";
import { useTranslation } from "react-i18next";
import AboutUsHindi from "../aboutus/AboutUsHindi";
import AboutUsBengali from "../aboutus/AboutUsBengali";
import PrivacyPolicyHindi from "../privacypolicy/privacypolicyHindi";
import PrivacyPolicyBengali from "../privacypolicy/privacypolicyBengali";

const { TabPane } = Tabs;

function AboutCompany() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Tabs defaultActiveKey="2" className="DashboardTab" centered>
        <TabPane tab={t("aboutus")} key="2" forceRender={true}>
          {/* <AboutUs /> */}
          {localStorage.getItem("language") == "en" ||
          localStorage.getItem("language") == null ? (
            <AboutUs />
          ) : localStorage.getItem("language") == "hi" ? (
            <AboutUsHindi />
          ) : (
            <AboutUsBengali />
          )}
        </TabPane>
        <TabPane tab={t("privacy_policy")} key="3">
          {/* <PrivacyPolicy /> */}
          {localStorage.getItem("language") == "en" ||
          localStorage.getItem("language") == null ? (
            <PrivacyPolicy />
          ) : localStorage.getItem("language") == "hi" ? (
            <PrivacyPolicyHindi />
          ) : (
            <PrivacyPolicyBengali />
          )}
        </TabPane>
        <TabPane tab={t("terms_and_condition")} key="4">
          <TermsAndCondition />
        </TabPane>
        <TabPane tab={t("cancellation_policy")} key="5">
          <CancellationPolicy />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AboutCompany;
