import React from "react";
import CommonHeader from "../commonheaders/CommonHeader";
import './CancellationPolicy.css'
import { useTranslation } from 'react-i18next';


/**
 * Cancellation Policy Page
 * @param {*} props 
 * @returns 
*/
const CancellationPolicy = (props) => {
  const { t, i18n } = useTranslation();

    return (
        <div className="CancellationPolicy-container">
            <CommonHeader
                imageUrl="https://onesourcekarmayoga.com/wp-content/uploads/2021/05/vision_01_a-1.jpg"
                headerText={t('cancellation_policy')}
            />
            <div className="policy-content-container">
                <p>{t('you_may_elect_to_cancel_your_request')}</p>
                <p>{t('by_creating_an_account_you_agree_that_the_Services_may_send')}</p>
                <p>{t('your_access_and_use_of_the_services')}</p>
                <p>{t('if_you_do_not_agree_to_abide_by_these')}</p>
            </div>
        </div>
    )
}

export default CancellationPolicy