import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo_200_170.png";
import successful from "../../images/successful.gif";
import './OrderSuccessful.css';


const OrderSuccessful = () => {
  return (
    <>
      <div className="main-container">
        <div className="animatedContainer">
           <img src={successful}/> 
        </div>
        <div className="congrats-text">
        <h2>Congratulations</h2>
       <h3>Your Requirement Placed Successfully</h3>
       <Link to='/dashboard'><Button className="continue-dash-btn"><span>CONTINUE TO DASHBOARD</span></Button></Link>
        </div>
      </div>
    </>
  );
};

export default OrderSuccessful;
