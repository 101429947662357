import { createSelector } from "reselect";

const tutorialLinkState = (state) => state.help.youtubeLink;
const loginGuideState = (state) => state.help.loginGuide;
const loginAccessGuideState = (state) => state.help.loginAccessGuide;
const popupTourState = (state) => state.help.tourPopup;
const registerGuideState = (state) => state.help.registerGuide;
const registerVerifyGuideState = (state) => state.help.verifyRegisterGuide;
const mpinGuideState = (state) => state.help.mpinGuide;
const categoryGuideState = (state) => state.help.categoryGuide;
const requirementGuideState = (state) => state.help.requirementGuide;
const requirementApplyGuideState = (state) => state.help.requirementApplyGuide;
const requirementRecruitGuideState = (state) => state.help.requirementRecruitGuide;
const profileGuideState = (state) => state.help.profileGuide;
const editProfileGuideState = (state) => state.help.editProfileGuide;
const matchGuideState = (state) => state.help.matchGuide;
const editMatchGuideState = (state) => state.help.editMatchGuide;
const dashBoardGuideState = (state) => state.help.dashBoardGuide;
const dashBoardCompletedGuideState = (state) => state.help.dashBoardCompletedGuide;
const termsGuideState = (state) => state.help.termsGuide;
const userRegistrationGuideState = (state) => state.help.userRegistrationGuide;
const helpGuideState = (state) => state.help.helpGuide;




/**
 * Selector for getting the  youtube video link
 */
export const makeSetYoutubeLink = createSelector(tutorialLinkState, (youtubeLink) => youtubeLink);
export const makeSetLoginGuide = createSelector(loginGuideState, (loginGuide) => loginGuide);
export const makeSetLoginAccessGuide = createSelector(loginAccessGuideState, (loginAccessGuide) => loginAccessGuide);
export const makeSetTourPopup = createSelector(popupTourState, (tourPopup) => tourPopup);
export const makeSetRegisterGuide = createSelector(registerGuideState, (registerGuide) => registerGuide);
export const makeSetVerifyRegisterGuide = createSelector(registerVerifyGuideState, (verifyRegisterGuide) => verifyRegisterGuide);
export const makeSetMpinGuide = createSelector(mpinGuideState, (mpinGuide) => mpinGuide);
export const makeSetCategoryGuide = createSelector(categoryGuideState, (categoryGuide) => categoryGuide);
export const makeSetRequirementGuide = createSelector(requirementGuideState, (requirementGuide) => requirementGuide);
export const makeSetRequirementApplyGuide = createSelector(requirementApplyGuideState, (requirementApplyGuide) => requirementApplyGuide);
export const makeSetRequirementRecruitGuide = createSelector(requirementRecruitGuideState, (requirementRecruitGuide) => requirementRecruitGuide);
export const makeSetProfileGuide = createSelector(profileGuideState, (profileGuide) => profileGuide);
export const makeSeteditProfileGuide = createSelector(editProfileGuideState, (editProfileGuide) => editProfileGuide);
export const makeSetMatchGuide = createSelector(matchGuideState, (matchGuide) => matchGuide);
export const makeSetEditMatchGuide = createSelector(editMatchGuideState, (editMatchGuide) => editMatchGuide);
export const makeSetDashBoardGuide = createSelector(dashBoardGuideState, (dashBoardGuide) => dashBoardGuide);
export const makeSetDashBoardCompletedGuide = createSelector(dashBoardCompletedGuideState, (dashBoardCompletedGuide) => dashBoardCompletedGuide);
export const makeSetTermsGuide = createSelector(termsGuideState, (termsGuide) => termsGuide);
export const makeSetUserRegistrationGuide = createSelector(userRegistrationGuideState, (userRegistrationGuide) => userRegistrationGuide);
export const makeSetHelpGuide = createSelector(helpGuideState, (helpGuide) => helpGuide);


