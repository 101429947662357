import { ActionTypes } from "./constants";

const initialState = {
    ratingQuestion:[],
    ratingQuestionForSeller:[],
    submitRatingData:[],
    isLoading:true
};

const RatingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_RATING_QUESTION:
            return {ratingQuestion: action.payload};
        case ActionTypes.GET_RATING_QUESTION_FOR_SELLER:
            return {ratingQuestionForSeller: action.payload};
        case ActionTypes.SUBMIT_RATING_DATA:
            return { ...state, submitRatingData: action.payload};
        case ActionTypes.DELETE_RATING_DATA:
            return {ratingQuestion:[],ratingQuestionForSeller:[]}
        default:
            return state;
    }
}

export default RatingReducer;