import React from 'react'
import { Collapse, Space } from 'antd';
import './Faq.css'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

function Faq() {
  return (
    <>
    <div className='faq-container'>
    <h2 className='faq-heading'>Frequently Asked Questions </h2>
    <div className='faq-content'>
    <Space direction="horizontal">
    <Collapse accordion  ghost expandIcon={({ isActive }) => isActive? <MinusOutlined style={{ fontSize: '16px', color: '#08c' }}/>:<PlusOutlined style={{ fontSize: '16px', color: '#08c' }}/> }
    className="site-collapse-custom-collapse faq-main-collapse">
      <Panel header="After applying for the job, how long my order will be active?" key="1" className="site-collapse-custom-panel">
        <h4 className='collapse-text'>Once you apply for job under any category your order will be active life time if you interact with the application checking for the updates and keep amending the bid. If you are not interacting for three weeks or more your order will be deactived after fourth week. Keep engaged yourself on application to get better opportunities.</h4>
      </Panel>
      <Panel header="Can I delete my order once placed as a recruiter?" key="2" className="site-collapse-custom-panel">
      <h4 className='collapse-text'>Currently the system does not support deleting the order once placed as a recruiter. Any updates required you can connect to support.</h4>
      </Panel>
      <Panel header="Can I update my quantity once posted as a Recruiter/Applier?" key="3" className="site-collapse-custom-panel">
      <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4>
      </Panel>
      <Panel header="How do I create an account on KarmaYoga?" key="4" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="How to create a new access code? (or) Forgot the access code?" key="5" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="Can I register multiple profiles/companies in one login account?" key="6" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="What are the documents required to register on KarmaYoga?" key="7" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="How do I post a job on KarmaYoga?" key="8" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="I can't locate the trade for which I am hiring. Can I add it?" key="9" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="How can I hire tradespersons from cities which are not available in the dropdown list, Can I add new cities/towns?" key="10" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="Can I post a job on KarmaYoga without having an account?" key="11" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="Why are my PAN/Aadhar/GST details mandatory for registration?" key="12" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="Is my information protected?" key="13" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="Can I post multiple jobs on KarmaYoga?" key="14" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="How can I see the price quotation of my competitors?" key="15" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      <Panel header="How can I see the price quotation of tradesmen?" key="16" className="site-collapse-custom-panel">
      {/* <h4 className='collapse-text'>Once job is posted as a Recruiter/Applier you cannot update the quantity but you can add the order for same trade with price and quantity you are looking for.</h4> */}
      </Panel>
      
    </Collapse>
    </Space>
    </div>
    </div>
    </>
  )
}

export default Faq