import React from "react";
import "./CompletedTab.css";
import { Card, Image, Input, Modal, Rate } from "antd";
import { FilePdfOutlined, HomeOutlined, StarOutlined } from "@ant-design/icons";
import { getContract } from "../../../karmayoga-api/api/dashboard";
import { createSelector } from "reselect";
import { makeSelectConfirmedOrder } from "../../../redux-core/dashboard/selectors";
import { getConfirmedOrderAction } from "../../../redux-core/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import RatingForSeller from "./ratingmodalseller/RatingForSeller";
import RatingForBuyer from "./ratingmodalbuyer/RatingForBuyer";
import {
  clearRatingSellerQuestion,
  getRatingQuestionAction,
  getRatingSellerQuestion,
} from "../../../redux-core/rating/actions";
import { useEffect } from "react";

const { TextArea } = Input;

const confirmedStateSelector = createSelector(
  makeSelectConfirmedOrder,
  (confirmedOrder) => ({
    confirmedOrder,
  })
);

const actionDispatch = (dispatch) => ({
  getConfirmedOrder: () => dispatch(getConfirmedOrderAction()),
  getRatingQuestion: (id, type) => dispatch(getRatingQuestionAction(id, type)),
  getSellerRatingQuestion: (id, type) =>
    dispatch(getRatingSellerQuestion(id, type)),
  clearRatingQuestion: () => dispatch(clearRatingSellerQuestion()),
});

const CompletedTab = () => {
  const { confirmedOrder } = useSelector(confirmedStateSelector);
  const { t, i18n } = useTranslation();
  const {
    getConfirmedOrder,
    getRatingQuestion,
    getSellerRatingQuestion,
    clearRatingQuestion,
  } = actionDispatch(useDispatch());
  const [modalVisibilityBuyer, setModalVisibilityBuyer] = useState(false);
  const [modalVisibilitySeller, setModalVisibilitySeller] = useState(false);
  const [objInfo, SetObjInfo] = useState("");
  const [filled, SetFilled] = useState("");

  //useEffect to clear the rating Questions

  useEffect(() => {
    clearRatingQuestion();
  });

  /**
   * updating state for close the modal for Buyer
   **/
  const closeModalBuyer = () => {
    setModalVisibilityBuyer(false);
  };

  /**
   * updating state for close the modal for Seller
   **/

  const closeModalSeller = (e) => {
    setModalVisibilitySeller(false);
  };

  /**
   * function for updating state of modal visibility
   **/

  const displayRating = (item) => {
    SetObjInfo(item);
    // document.getElementsByClassName('mob_nav').style.zIndex='0';
    SetFilled(item.filled);

    if (item.buyer_id == item.user_id) {
      setModalVisibilitySeller(true);
      setModalVisibilityBuyer(false);
      getRatingQuestion(item.obj_id, "buyer");
    } else {
      setModalVisibilityBuyer(true);
      setModalVisibilitySeller(false);
      getSellerRatingQuestion(item.obj_id, "seller");
    }
  };
  return (
    <div className="site-card-border-less-wrapper mainCompletedTab">
      {confirmedOrder?.data?.map((item, index) => (
        <Card
          bordered={true}
          key={index}
          className="dashboardCompleted_Card"
        >
          <div className="main_container">
            <div className="main_child_container">
              <div className="container-one">
                <span className="name_icon">
                  <span className="category_name">
                    <b>{item.category_name} </b>
                  </span>
                </span>
              </div>

              <div className="container-two">
                <span className="quantity_part">
                  <span className="quantity" style={{ color: "gray" }}>
                    {t("quantity")} :{" "}
                  </span>
                  <b className="itemRate"> {item.filled} </b>
                </span>
                <span className="unit_part">
                  <span className="category_unit" style={{ color: "gray" }}>
                    {" "}
                    {t("rate")} / {item.cat_unit} :{" "}
                  </span>
                  <b className="itemRate">
                    {" "}
                    {t("rs")} {item.rate}
                  </b>
                </span>
              </div>

              <div className="container_four">
                <span className="location">
                  <b>{item.location}</b>
                </span>
              </div>

              <div className="container-third">
                <hr></hr>
                <span className="econtract_rating">
                  <span
                    className="econtract"
                    onClick={() =>
                      getContract(
                        item.obj_id,
                        `${item.category_name}_${item.location}`
                      )
                    }
                  >
                    <FilePdfOutlined
                      className={
                        item.buyer_id == item.user_id
                          ? "BuyerContract"
                          : "SellerContract"
                      }
                    />
                    <span>
                      <b>{t("econtract")}</b>
                    </span>{" "}
                  </span>
                  <span className="verticalLine"></span>
                  <span className="rating" onClick={() => displayRating(item)}>
                    <StarOutlined
                      className={
                        item.buyer_id == item.user_id
                          ? "BuyerContract"
                          : "SellerContract"
                      }
                    />
                    <span>
                      <b>{t("rating")}</b>
                    </span>{" "}
                  </span>
                </span>
              </div>
            </div>
            <div className="icon_class">
              <span className="category_icon" style={{ color: "gray" }}>
                <Image
                  style={{ height: "60px", width: "60px" }}
                  src={item.category_image}
                  preview={false}
                />
              </span>
            </div>
          </div>
        </Card>
      ))}

      <RatingForBuyer
        objInfo={objInfo}
        filled={filled}
        modalVisibilityBuyer={modalVisibilityBuyer}
        closeModalBuyer={closeModalBuyer}
      />

      <RatingForSeller
        objInfo={objInfo}
        filled={filled}
        modalVisibilitySeller={modalVisibilitySeller}
        closeModalSeller={closeModalSeller}
      />
    </div>
  );
};

export default CompletedTab;
