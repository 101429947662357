import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";
import { addClientToken } from "./redux-core/firebase/actions";
import { FIRE_UTILS, VAPID } from "./utils/constants";
import { isSupported } from "firebase/messaging";

const firebaseApp = initializeApp(FIRE_UTILS);
// const messaging = firebaseApp.messaging.isSupported() ?getMessaging(firebaseApp):null;

let messaging = null;
if (isSupported()){  
  try{
    messaging = getMessaging(firebaseApp);
  }catch(err) {
    console.log(err);
  }    
}


const actionDispatch = (dispatch) => ({
  postClientToken: (payload) => dispatch(addClientToken(payload)),
});

  export const getTokenData = (setTokenFound) => {

    return getToken(messaging, {vapidKey: VAPID}).then((currentToken) => {

      if (currentToken) {
        if (window.innerWidth >= 800) {
          var payload = {
            clientid:currentToken,
            ismobile:false
          };
          
          try {
            const response = axios.post("/api/addClientToken", payload);
            //return response.data;
          } catch (error) {
            throw error;
          }
          
        }else{
          var payload = {
            clientid:currentToken,
            ismobile:true
          };
          
          try {
            const response = axios.post("/api/addClientToken", payload);
           // return response.data;
          } catch (error) {
            throw error;
          }
        }

        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        //console.log(setTokenFound)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log("Rejected");
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
