export const loginAccessCodeTour=[
    {
      selector: ".ant-form-item-control-input-content",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null) ?<h4>Enter your Access Code here</h4>: localStorage.getItem('language')=='hi'? <h4>अपना एक्सेस कोड यहां दर्ज करें</h4>: localStorage.getItem('language')=='bn'?<h4>এখানে আপনার 
          প্রবেশাধিকার কোড লিখুন</h4>:<h4>உங்கள் அணுகல் குறியீட்டை இங்கே உள்ளிடவும்</h4>
        } 
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    },
    {
      selector: ".login-form-button",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>After entering the Access Code, Click here to verify </h4>: localStorage.getItem('language')=='hi'? <h4>एक्सेस कोड दर्ज करने के बाद, सत्यापित करने के लिए यहां क्लिक करें</h4>: localStorage.getItem('language')=='bn'?<h4>এমপিন প্রবেশ করার পর, যাচাই করতে এখানে ক্লিক করুন </h4>:<h4>அணுகல் குறியீட்டை உள்ளிட்ட பிறகு, சரிபார்க்க இங்கே கிளிக் செய்யவும்</h4>
        } 
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    },
    {
      selector: ".ant-btn-link",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click here to SignIn with verification code</h4>: localStorage.getItem('language')=='hi'? <h4>सत्यापन कोड के साथ साइन इन करने के लिए यहां क्लिक करें</h4>: localStorage.getItem('language')=='bn'?<h4>যাচাই কোড সহ সাইন ইন করতে এখানে ক্লিক করুন</h4>:<h4>சரிபார்ப்புக் குறியீட்டுடன் உள்நுழைய இங்கே கிளிக் செய்யவும்</h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    },
    {
      selector: ".goBacktoLogin-tour",
      content:()=>( <div>
        {
         (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click here to go back to login page</h4>: localStorage.getItem('language')=='hi'? <h4>लॉगिन पेज पर वापस जाने के लिए यहां क्लिक करें</h4>: localStorage.getItem('language')=='bn'?<h4>লগইন পৃষ্ঠায় ফিরে যেতে এখানে ক্লিক করুন</h4>:<h4>உள்நுழைவு பக்கத்திற்குச் செல்ல இங்கே கிளிக் செய்யவும்</h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    }
]