import React from "react";
import CommonHeader from "../commonheaders/CommonHeader";
import './AboutUs.css'
import { Image } from "antd";

/**
 * About Us Page in Bengali language
 * @param {*} props 
 * @returns 
*/
const AboutUsBengali = (props) => {
    return (
        <div className="aboutus-conatiner">
            <CommonHeader
                imageUrl="https://template-karmayoga.s3.ap-south-1.amazonaws.com/how+we+got+started.png"
                headerText="আমাদের সম্পর্কে"
                subTitle="একটি স্বচ্ছ বাজার তৈরি করা – সামাজিক পরিবর্তনের লক্ষ্যে"
            />
            <div className="aboutus-content-container">
                <div className="aboutus-title">
                আমরা কিভাবে শুরু করেছি
                </div>
                <div className="aboutus-subcontent">
                    <div>
                        <Image
                            src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/how+we+got+started.png"}
                            title={"Company"}
                            width={"100%"}
                            preview={false}
                        />
                    </div>
                    <div className="aboutus-details">
                        <p className="aboutus-details-header">আমরা উদ্যোক্তাদের একটি গ্রুপ যাদের একসাথে রিয়েল এস্টেট, নির্মাণ এবং সফ্টওয়্যার ডেভেলপমেন্টে প্রচুর অভিজ্ঞতা রয়েছে – আমাদের এই নতুন ব্যবসায়িক ধারণাটির একটি 360⁰ দৃষ্টিভঙ্গি দিচ্ছে।</p>
                        <p>আরও গুরুত্বপূর্ণ বিষয় হল আমরা সমাজকে ফিরিয়ে দেওয়ার এবং সামাজিক পরিবর্তনকে উত্সাহিত করার জন্য একটি আবেগ শেয়ার করি।</p>
                        <p>সংগঠন এই শক্তিগুলিকে কাজে লাগায় এবং এই নীতিতে কাজ করে যে পুরোটা তার অংশের যোগফলের চেয়ে অনেক বেশি।</p>
                        <p>ওয়ান সোর্স কর্ম যোগ প্রাইভেট লিমিটেড, 2020 সালের ডিসেম্বরে প্রতিষ্ঠিত হয়েছিল। আমরা একটি স্টার্ট আপ সংস্থা হিসাবে প্রত্যয়িত হয়েছি। (নীচে কপি দেখুন)।</p>
                    </div>
                </div>
                <div className="aboutus-layer-content">
                    <div className="aboutus-title custom-border">
                    ব্যবসার ধারণা
                    </div>
                    <div className="aboutus-sublayers aboutus-layer-content">
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                            <p>সংযোগ করা হচ্ছে</p> <p>&nbsp;গ্যাপ</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Connecting+the+gap.png"}
                                    title={"Company"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                                <p>আমাদের ব্যবসায়িক ধারণা শ্রমিকদের সরবরাহ এবং তাদের দক্ষতার চাহিদার সংযোগে বিশাল ব্যবধানকে সমাধান করে – যা ভৌগলিক দূরত্ব এবং বর্তমান অনানুষ্ঠানিক নিয়োগ প্রক্রিয়ার কারণে হয়।</p>
                                <p>আমাদের অ্যাপ্লিকেশান, মালিকানা অ্যালগরিদম ব্যবহার করে যাতে শ্রমিকদের সরবরাহ এবং চাহিদার সহজ এবং নির্বিঘ্ন মিল পাওয়া যায়। সরলতা আপনাকে অবাক করবে!</p>
                            </div>
                        </div>
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                            <p>স্বচ্ছ</p> <p>&nbsp;বাজার মূল্য</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Transparent+Marketprice.png"}
                                    title={"BestPrice"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                                <p>আরও গুরুত্বপূর্ণভাবে, অ্যাপটি অনেক প্রয়োজনীয় সামাজিক পরিবর্তন শুরু করবে বলে আশা করছে। এটি একটি স্বচ্ছ মুক্ত বাজারের অর্থনৈতিক নীতিগুলিকে নিয়োগ করে – যা নিয়োগকর্তা এবং কর্মী উভয়কেই পরিষেবার জন্য একটি ন্যায্য বাজার মূল্য আবিষ্কার করতে উত্সাহিত করে৷ অ্যাপটি মূল্যের বৈষম্যকেও সমাধান করে এবং এটি দক্ষতার স্তরের সাথে মূল্যের সমতাকেও উৎসাহিত করে – কর্মীদেরকে উচ্চ দক্ষতার জন্য উৎসাহিত করে।</p>
                            </div>
                        </div>
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                            <p>উন্নতি</p> <p>&nbsp;উৎপাদনশীলতা</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Improving+Productivity.png"}
                                    title={"BestPrice"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                                <p>বাজার আবিষ্কারের এই প্রক্রিয়াটি কর্মীদের জন্য আরও ভাল জীবনযাত্রাকে উত্সাহিত করে কারণ এটি প্রাসঙ্গিক দামে অনবোর্ডিং কর্মীদের জন্য প্রাসঙ্গিকতা অর্জন করে।</p>
                                <p>একটি পুণ্যময় চক্র তৈরি করে অ্যাপটি কর্মীদের উৎপাদনশীলতার উন্নতিকে উৎসাহিত করবে যা শিল্পে একটি ক্যাসকেডিং উন্নতির দিকে পরিচালিত করবে।</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutus-layer-content">
                    <div className="aboutus-title custom-border">        
                           প্রতিষ্ঠানের তথ্য
                    </div>
                    <div className="aboutus-company-info-container">
                        <Image.PreviewGroup>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/certificate+of+incorporation.jpg"}
                                    title={"Incorporationcertificate"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                                <p>নিগমকরণ শংসাপত্র</p>
                            </div>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/certificate+of+recognition.png"}
                                    title={"Startupindia"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                                <p>স্টার্টআপ ইন্ডিয়া স্বীকৃতি</p>
                            </div>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/registration.png"}
                                    title={"registration"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                                <p>জিএসটি নিবন্ধন</p>
                            </div>
                        </Image.PreviewGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsBengali