import React from "react";
import CommonHeader from "../commonheaders/CommonHeader";
import './ContactUs.css'
import { Image } from "antd";
import BlueMapIcon from '../../../images/blueLocation.png';
import EmailIcon from '../../../images/email.png';
import PhoneIcon from '../../../images/phone-call.png';

const contactUsData = [
    {
        type: 'location',
        content: <><p>Onesource Karmayoga Pvt. ltd</p><p>#859, 17th G Main Rd, 5th Block Rajajinagar, Bangalore 560010, Karnataka</p></>,
        image: BlueMapIcon,
    },
    {
        type: 'contact',
        content: <><p>08069403250</p></>,
        image: PhoneIcon,
    },
    {
        type: 'email',
        content: <><p>info@onesourcekarmayoga.com</p></>,
        image: EmailIcon,
    }
]

/**
 * Contact Us Page
 * @param {*} props 
 * @returns 
*/
const ContactUs = (props) => {
    return (
        <div className="contactus-conatiner">
            <CommonHeader
                imageUrl="https://onesourcekarmayoga.com/wp-content/uploads/2021/05/business_01.jpg"
                headerText="Get in touch"
                subTitle="We have set out on an exciting mission would be pleased to answer your queries. Let us know how we can serve you better"
            />
            <div className="contactus-sub-conatiner">
                <div>
                    <span>Reach Us</span>
                </div>
                {contactUsData.map((details) => {
                    return (
                        <div className="contact-content-container">
                            <div>
                                <Image
                                    src={details.image}
                                    title={details.type}
                                    width={"30px"}
                                    preview={false}
                                />
                            </div>
                            <div className="contact-content-sub-container">
                                {details.content}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ContactUs