import React from "react";
import CommonHeader from "../commonheaders/CommonHeader";
import './AboutUs.css'
import { Image } from "antd";

/**
 * About Us Page
 * @param {*} props 
 * @returns 
*/
const AboutUs = (props) => {
    return (
        <div className="aboutus-conatiner">
            <CommonHeader
                imageUrl="https://template-karmayoga.s3.ap-south-1.amazonaws.com/how+we+got+started.png"
                headerText="About Us"
                subTitle="To create a transparent marketplace – with the aim of social change"
            />
            <div className="aboutus-content-container">
                <div className="aboutus-title">
                    How We Got Started
                </div>
                <div className="aboutus-subcontent">
                    <div>
                        <Image
                            src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/how+we+got+started.png"}
                            title={"Company"}
                            width={"100%"}
                            preview={false}
                        />
                    </div>
                    <div className="aboutus-details">
                        <p className="aboutus-details-header">We are a group of entrepreneurs who together have enormous experience in Real Estate, construction and software development – giving us a 360⁰ perspective of this new business idea.</p>
                        <p>More importantly we share a passion for giving back to society and encouraging social change.</p>
                        <p>The organization harnesses these strengths, and works on the tenet that the whole is far greater than the sum of its parts.</p>
                        <p>One Source Karma Yoga Pvt Ltd, was established in Dec 2020. We have been certified as a start up organization. (See copy below).</p>
                    </div>
                </div>
                <div className="aboutus-layer-content">
                    <div className="aboutus-title custom-border">
                        The  Business idea
                    </div>
                    <div className="aboutus-sublayers aboutus-layer-content">
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                                <p>Connecting the</p> <p>&nbsp;Gap</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Connecting+the+gap.png"}
                                    title={"Company"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                                <p>Our business idea addresses the huge gap in connecting supply of workmen and demand for their skills – which is caused by geographical distances and the current informal recruitment process.</p>
                                <p>Our application, uses proprietary algorithms to allow easy and seamless match of supply and demand of workmen. The simplicity will surprise you!</p>
                            </div>
                        </div>
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                                <p>Transparent</p> <p>&nbsp;Marketprice</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Transparent+Marketprice.png"}
                                    title={"BestPrice"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                                <p>More importantly, the app hopes to initiate a much needed social change. It employs the economic principles of a transparent free market – which encourages both employers and workmen to discover a fair market price for services. The app also addresses price inequity, and it also encourages price parity with skill levels – Incentivising workmen to upskill.</p>
                            </div>
                        </div>
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                                <p>Improving</p> <p>&nbsp;Productivity</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Improving+Productivity.png"}
                                    title={"BestPrice"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                                <p>This process of market discovery also encourages better living conditions for workmen as these gain relevance to onboarding workmen at relevant prices.</p>
                                <p>By creating a virtuous cycle the app will encourage improvement in worker productivity leading to a cascading improvement in the industry.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutus-layer-content">
                    <div className="aboutus-title custom-border">
                        Company Info
                    </div>
                    <div className="aboutus-company-info-container">
                        <Image.PreviewGroup>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/certificate+of+incorporation.jpg"}
                                    title={"Incorporationcertificate"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                                <p>Incorporation certificate</p>
                            </div>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/certificate+of+recognition.png"}
                                    title={"Startupindia"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                                <p>StartupIndia Recognition</p>
                            </div>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/registration.png"}
                                    title={"registration"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                                <p>GST registration</p>
                            </div>
                        </Image.PreviewGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs