export const helpPageTour=[
  {
    selector: ".first",
    content:()=>( <div>
      {
        (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click here to watch the tutorial </h4>: localStorage.getItem('language')=='hi'? <h4>ट्यूटोरियल देखने के लिए यहां क्लिक करें </h4>: localStorage.getItem('language')=='bn'?<h4>টিউটোরিয়ালটি দেখতে এখানে ক্লিক করুন </h4>:<h4>டுடோரியலைப் பார்க்க இங்கே கிளிக் செய்யவும் </h4>
      }
    </div>),
    style:{
      backgroundColor:"#ff8d1a",
      color:"white",
      paddingTop:'30px'
    }
  },
  {
      selector: ".second",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Find answers here to Frequently Asked Question</h4>: localStorage.getItem('language')=='hi'? <h4>अक्सर पूछे जाने वाले प्रश्नों के उत्तर यहां पाएं</h4>: localStorage.getItem('language')=='bn'?<h4>প্রায়শই জিজ্ঞাসিত প্রশ্নের উত্তর এখানে খুঁজুন</h4>:<h4>அடிக்கடி கேட்கப்படும் கேள்விகளுக்கான பதில்களை இங்கே கண்டறியவும்</h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
  },
  {
    "selector": ".third",
    content:()=>( <div>
      {
        (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Still Need more support? Call the number, during working hours, to connect with our live customer care</h4>: localStorage.getItem('language')=='hi'? <h4>अभी भी और समर्थन चाहिए? हमारे लाइव कस्टमर केयर से जुड़ने के लिए काम के घंटों के दौरान इस नंबर पर कॉल करें</h4>: localStorage.getItem('language')=='bn'?<h4>এখনও আরো সমর্থন প্রয়োজন? আমাদের লাইভ কাস্টমার কেয়ারের সাথে সংযোগ করতে, কাজের সময়গুলিতে এই নম্বরে কল করুন</h4>:<h4>இன்னும் கூடுதல் ஆதரவு தேவையா? எங்கள் நேரடி வாடிக்கையாளர் சேவையுடன் இணைக்க, வேலை நேரத்தில், எண்ணை அழைக்கவும்</h4>
      }
    </div>),
    style:{
      backgroundColor:"#ff8d1a",
      color:"white",
      paddingTop:'30px'
    }
  },
  {
      selector: ".fourth",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click here to know more about Karmayoga and our policies. </h4>: localStorage.getItem('language')=='hi'? <h4>कर्मयोगा और हमारी नीतियों के बारे में अधिक जानने के लिए यहां क्लिक करें</h4>: localStorage.getItem('language')=='bn'?<h4>কর্মযোগ এবং আমাদের নীতিগুলি সম্পর্কে আরও জানতে এখানে ক্লিক করুন৷ </h4>:<h4>கர்மயோகம் மற்றும் எங்கள் கொள்கைகள் பற்றி மேலும் அறிய இங்கே கிளிக் செய்யவும். </h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
  },
  {
    "selector": ".fifth",
    content:()=>( <div>
      {
        (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Upgrade your skills by joining us and getting trained at our Academy of Excellence. Check out our offerings </h4>: localStorage.getItem('language')=='hi'? <h4>हमारे साथ जुड़कर और हमारी उत्कृष्टता अकादमी में प्रशिक्षण प्राप्त करके अपने कौशल का उन्नयन करें। हमारी पेशकश देखें</h4>: localStorage.getItem('language')=='bn'?<h4>আমাদের সাথে যোগদান করে এবং আমাদের একাডেমি অফ এক্সিলেন্সে প্রশিক্ষিত হয়ে আপনার দক্ষতা আপগ্রেড করুন। আমাদের অফারগুলি দেখুন </h4>:<h4>உங்கள் திறமைகளை மேம்படுத்தி எங்களுடன் சேர்ந்து எங்கள் அகாடமி ஆஃப் எக்ஸலன்ஸ் பயிற்சி பெறுங்கள். எங்கள் சலுகைகளைப் பார்க்கவும் </h4>
      }
    </div>),
    style:{
      backgroundColor:"#ff8d1a",
      color:"white",
      paddingTop:'30px'
    }
  }
]