import { createSelector } from "reselect";

const updatedCountState = (state) => state.dailyCountUpdate.countData;

/**
 * Selector for getting updated count data.
 */
 export const makeSelectUpdatedCount = createSelector(
    updatedCountState,
    (countData) => countData
  );