import { ActionTypes } from "./constants";

const initialState = {
    countData:[]
};

const updatedCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_UPDATED_COUNT:
            return { ...state, countData: action.payload};
        case ActionTypes.SET_UPDATED_COUNT:
            return { ...state, countData: action.payload};
        default:
            return state;
    }
}

export default updatedCountReducer;