export const dashboardCompletedTour=[
    {
      selector: ".dashboard-econtract-tour",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Once a match is found for your requirements, you can click to download the E-contract between the two matched parties. </h4>: localStorage.getItem('language')=='hi'? <h4>एक बार आपकी आवश्यकताओं के लिए एक मैच मिल जाने के बाद, आप दो मेल खाने वाली पार्टियों के बीच ई-अनुबंध डाउनलोड करने के लिए क्लिक कर सकते हैं</h4>: localStorage.getItem('language')=='bn'?<h4>আপনার প্রয়োজনীয়তার জন্য একটি মিল পাওয়া গেলে, আপনি দুটি মিলে যাওয়া পক্ষের মধ্যে ই-চুক্তি ডাউনলোড করতে ক্লিক করতে পারেন। </h4>:<h4>உங்கள் தேவைகளுக்குப் பொருத்தம் கண்டறியப்பட்டதும், பொருந்திய இரு தரப்பினருக்கும் இடையேயான மின் ஒப்பந்தத்தைப் பதிவிறக்க கிளிக் செய்யலாம். </h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    }
]