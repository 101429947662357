import {
  getRatingQuestion,
  postRatingData,
} from "../../karmayoga-api/api/rating";
import { ActionTypes } from "./constants";
import { notification } from "antd";

/**
 * action for get Rating question
 * @returns
 */

export const getRatingQuestionAction = (id, type) => {
  return async (dispatch) => {
    try {
      const response = await getRatingQuestion(id);
        dispatch({
          type: ActionTypes.GET_RATING_QUESTION_FOR_SELLER,
          payload: response.data,
        });
    } catch (err) {
      notification.error({
        message: "Fetch rating question failed",
        description:
          err?.response?.data?.message ||
          "Please check your network connection",
        onClick: () => {},
      });
    }
  };
};

// RatingForSeller question

export const getRatingSellerQuestion = (id, type) => {
  return async (dispatch) => {
    try {
        const response = await getRatingQuestion(id);
      
      dispatch({
        type: ActionTypes.GET_RATING_QUESTION,
        payload: response.data,
      });


    } catch (err) {
      notification.error({
        message: "Fetch rating question failed",
        description:
          err?.response?.data?.message ||
          "Please check your network connection",
        onClick: () => {},
      });
    }
  };
};


export const clearRatingSellerQuestion = () => {
  return async (dispatch) => {
    try { 
      dispatch({
        type: ActionTypes.DELETE_RATING_DATA,
        payload: [],
      });

     
    } catch (err) {
      
    }
  };
};



/**
 * action for setting updated count
 * @returns
 */

export const postRatingDataAction = (payload) => {
  return async (dispatch) => {
    try {
      const response = await postRatingData(payload);
      //  console.log("ll",response.data);
      dispatch({
        type: ActionTypes.SUBMIT_RATING_DATA,
        payload: response.data,
      });
      notification.open({
        message: response.data.message,
      });
    } catch (err) {
      notification.error({
        message: "Submit rating data failed",
        description:
          err?.response?.data?.message ||
          "Please check your network connection",
        onClick: () => {},
      });
    }
  };
};
