// In this component,seller will give rating to buyer

import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import "./RatingForBuyer.css";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Input,
  Modal,
  notification,
  Radio,
  Rate,
  Select,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectConfirmedOrder } from "../../../../redux-core/dashboard/selectors";
import { createSelector } from "reselect";
import Sheet from "react-modal-sheet";
import { makeSelectRatingQuestion } from "../../../../redux-core/rating/selectors";
import { postRatingDataAction } from "../../../../redux-core/rating/actions";
import { t } from "i18next";
import { getMasterCategoryAction } from "../../../../redux-core/categorylist/actions";

const { TextArea } = Input;
const { Option } = Select;

const confirmedStateSelector = createSelector(
  makeSelectConfirmedOrder,
  (confirmedOrder) => ({
    confirmedOrder,
  })
);

const getQuestionSelector = createSelector(
  makeSelectRatingQuestion,
  (ratingQuestion) => ({
    ratingQuestion,
  })
);

const actionDispatch = (dispatch) => ({
  submitRatingInfo: (payload) => dispatch(postRatingDataAction(payload)),
});

const RatingForBuyer = (props) => {
  const { confirmedOrder } = useSelector(confirmedStateSelector);
  const { ratingQuestion } = useSelector(getQuestionSelector);
  const { submitRatingInfo } = actionDispatch(useDispatch());
  const [starRateObj, setStarRate] = useState({});
  const [dateDiff, SetDateDiff] = useState(true);
  const [isEdit, SetisEdit] = useState(true);
  const [isSpin, SetSpin] = useState(true);
  /**
   * Using state in Object
   **/
  const [allValues, setAllValues] = useState({});
  useEffect(() => {
    getMasterCategoryAction();
    setTimeout(() => {
      SetSpin(false);
    }, 1000);
    if (ratingQuestion?.[0]) {
      SetSpin(true);
      const current = new Date();
      let date = `${current.getFullYear()}/${
        current.getMonth() + 1
      }/${current.getDate()}`;

      let oldDate = new Date(
        ratingQuestion?.[0].response.createdAt
      ).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      date = new Date(date);
      oldDate = new Date(oldDate);

      let dif = Math.floor(
        (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
          Date.UTC(
            oldDate.getFullYear(),
            oldDate.getMonth(),
            oldDate.getDate()
          )) /
          (1000 * 60 * 60 * 24)
      );

      if (ratingQuestion?.[0].response.is_edit) {
        if (dif == 0 || dif < 15) {
          SetDateDiff(false);
        } else {
          SetDateDiff(true);
        }
      } else {
        SetDateDiff(true);
      }

      if (Object.keys(ratingQuestion?.[0].response).length === 0) {
        setAllValues((prevValues) => {
          return { isYesBtnActive: false, isNoBtnActive: false };
        });
        SetisEdit(true);
      } else {
        setStarRate(
          ratingQuestion?.[0]?.response.rating
            ? ratingQuestion?.[0]?.response.rating
            : {}
        );
        SetisEdit(ratingQuestion?.[0].response.is_edit);
        setAllValues({
          isYesBtnActive:
            ratingQuestion?.[0].response &&
            ratingQuestion?.[0].response.is_called
              ? true
              : false,
          isNoBtnActive: ratingQuestion?.[0].response.is_called ? false : true,
          nocallvalue: ratingQuestion?.[0].response.call_feedback
            ? ratingQuestion?.[0].response.call_feedback
            : "",
          isDeployed: ratingQuestion?.[0].response.no_of_candidates
            ? ratingQuestion?.[0].response.no_of_candidates
            : 0,
          selectValueForBuyer:
            ratingQuestion?.[0]?.response.no_of_candidates >= 0
              ? ratingQuestion?.[0]?.response.no_of_candidates
              : "select",
          notDeployedReasonForBuyer: ratingQuestion?.[0]?.response
            .deployed_fail_feedback
            ? ratingQuestion?.[0]?.response.deployed_fail_feedback
            : [],
          maybeDeployedReasonForBuyer: ratingQuestion?.[0]?.response
            .partial_feedback
            ? ratingQuestion?.[0].response.partial_feedback
            : [],
          deployedComment: ratingQuestion?.[0].response.comments
            ? ratingQuestion?.[0].response.comments
            : "",
        });
      }
    }
  }, [ratingQuestion]);

  /**
   * create an array and push the values till deployed men in contract
   **/
  const DeployedMen = [];
  for (let i = 0; i <= props.filled; i++) {
    DeployedMen.push(i);
  }

  /**
   * updating state of 'Yes' button, when user selected "YES" Button
   **/
  const yesConnected = () => {
    //console.log(ratingQuestion[1].questions);
    setAllValues((prevValues) => {
      return { isYesBtnActive: true, isNoBtnActive: false };
    });
  };

  /**
   * updating state of 'No' button, when user selected "NO" Button
   **/
  const noConnected = () => {
    setAllValues((prevValues) => {
      return { isYesBtnActive: false, isNoBtnActive: true };
    });
  };

  /**
   * storing star rating values in state
   **/
  const onChange = (e) => {
    const { value } = e.target;
    setAllValues({
      ...allValues,
      selectValueForBuyer: value,
      maybeDeployedReasonForBuyer: [],
      notDeployedReasonForBuyer: [],
      deployedComment: "",
    });
    setStarRate({});
  };

  /**
   * storing reason of not connected in State(radio button values)
   **/
  const onValueChange = (e) => {
    setAllValues({ ...allValues, nocallvalue: e.target.value });
  };

  /**
   * storing reason of not deployed in State(multiple checkvalues)
   **/
  const onNotDeployedValue = (checkedValues) => {
    setAllValues({
      ...allValues,
      notDeployedReasonForBuyer: checkedValues,
      maybeDeployedReasonForBuyer: "",
    });
  };

  /**
   * storing reason of maybe deployed in State(multiple checkvalues)
   **/
  const onMaybeDeployedValue = (checkedValues) => {
    setAllValues({
      ...allValues,
      maybeDeployedReasonForBuyer: checkedValues,
      notDeployedReasonForBuyer: "",
    });
  };

  /**
   * method for storing Comment values in State
   **/
  const handleDeployed = (e) => {
    setAllValues({ ...allValues, deployedComment: e.target.value });
  };

  /**
   * close cross button function
   **/
  const closeRating = () => {
    props.closeModalBuyer();
    setAllValues("");
    setStarRate(ratingQuestion?.[0].response.rating);
    SetSpin(true);
  };

  /**
   * submit feedback Information
   **/
  const submitFeedback = () => {
    if (allValues.isYesBtnActive == true || allValues.isNoBtnActive == true) {
      submitRatingInfo(ratingInfo);
      props.closeModalBuyer();
      setAllValues("");
    } else {
      notification.open({
        message: "Please Select Your Input",
      });
    }
    SetSpin(true);
  };

  /**
   * get rating values
   **/
  const getRatings = (value, reason, i) => {
    if (i == 0 && value == 5) {
      //i =0 means overall
      ratingQuestion?.[1]?.questions
        .filter(
          (items) =>
            items.question_type == "star" && items.question_condition == "yes"
        )
        .map((itemvalues, i) => {
          setStarRate((preState) => ({
            ...preState,
            [itemvalues.rate_id]: value,
          }));
        });
    } else if (i == 0 && value < 5) {
      ratingQuestion?.[1]?.questions
        .filter(
          (items) =>
            items.question_type == "star" && items.question_condition == "yes"
        )
        .map((itemvalues, i) => {
          i == 0
            ? setStarRate((preState) => ({
                ...preState,
                [itemvalues.rate_id]: value,
              }))
            : setStarRate((preState) => ({
                ...preState,
                [reason]: value,
              }));
        });
    } else {
      setStarRate({ ...starRateObj, [reason]: value });
    }
  };

  /**
   * Object for storing the rating values
   **/

  const ratingInfo = {
    objid: props.objInfo.obj_id,
    userid: props.objInfo.user_id,
    counter_party_id: props.objInfo.buyer_id,
    iscalled: allValues.isYesBtnActive,
    call_feedback: allValues.nocallvalue,
    comments: allValues.deployedComment,
    no_of_candidates: allValues?.selectValueForBuyer,
    partial_feedback: allValues.maybeDeployedReasonForBuyer,
    deployed_fail_feedback: allValues.notDeployedReasonForBuyer,
    ratings: starRateObj,
  };

  return (
    <div>
      {confirmedOrder?.data
        .filter((item) => item.obj_id == props.objInfo.obj_id)
        .map((items, i) => {
          return items.obj_id == props.objInfo.obj_id ? (
            <Sheet
              key={i}
              className="SheetContainer"
              isOpen={props.modalVisibilityBuyer}
              onClose={props.closeModalBuyer}
            >
              <Sheet.Container>
                <h1 className="feedbackHead">
                  <b className="feedbackText">{t("share_your_feedback")}</b>
                  <CloseOutlined
                    onClick={closeRating}
                    style={{ fontSize: "small" }}
                    className="closeIcon"
                  />
                </h1>
                <Spin spinning={isSpin} tip="Loading...">
                  <Card>
                    <h2>
                      <b>
                        {t("did_you_connected_to")} {items.buyer_name} ?
                      </b>
                    </h2>
                    <Button
                      disabled={(!dateDiff && isEdit) || !isEdit ? true : false}
                      className={
                        allValues.isYesBtnActive
                          ? "connectBtnActive"
                          : "connectBtn"
                      }
                      onClick={yesConnected}
                    >
                      {t("yes")}
                    </Button>
                    <Button
                      disabled={(!dateDiff && isEdit) || !isEdit ? true : false}
                      className={
                        allValues.isNoBtnActive
                          ? "connectBtnActive"
                          : "connectBtn"
                      }
                      onClick={noConnected}
                    >
                      {t("no")}
                    </Button>

                    {allValues.isYesBtnActive ? (
                      <div className="numDeployed">
                        <b>{t("number_of_tradesmen_deployed")} ? </b>
                        <select
                          onChange={onChange}
                          defaultValue={allValues?.selectValueForBuyer}
                          disabled={
                            (!dateDiff && isEdit) || !isEdit ? true : false
                          }
                        >
                          <option value="select">Select</option>
                          {DeployedMen.map((count) => (
                            <option value={count}>{count}</option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                  </Card>

                  {allValues?.selectValueForBuyer == 0 ? (
                    <div>
                      <h3 className="wrongHeader">
                        <b>{t("what_went_wrong")}?</b>
                      </h3>
                      <Card>
                        <Checkbox.Group
                          onChange={onNotDeployedValue}
                          defaultValue={allValues?.notDeployedReasonForBuyer}
                        >
                          {ratingQuestion?.[1]?.questions
                            .filter(
                              (items) =>
                                items.question_type == "deploy" &&
                                items.question_condition == "no"
                            )
                            .map((itemvalues, i) => (
                              <>
                                <Checkbox value={itemvalues?.rate_id}>
                                  {itemvalues.reasons}
                                </Checkbox>
                                <hr />
                              </>
                            ))}
                          <TextArea
                            value={allValues.deployedComment}
                            onChange={handleDeployed}
                            placeholder={t("any_comments")}
                          />
                        </Checkbox.Group>
                      </Card>
                    </div>
                  ) : (
                    ""
                  )}
                  {parseInt(allValues?.selectValueForBuyer) > 0 &&
                  parseInt(allValues?.selectValueForBuyer) <
                    props.objInfo.filled ? (
                    <>
                      <div>
                        <h3 className="wrongHeader">
                          <b>{t("what_went_wrong")}?</b>
                        </h3>
                        <Card>
                          <Checkbox.Group
                            onChange={onMaybeDeployedValue}
                            defaultValue={
                              allValues?.maybeDeployedReasonForBuyer
                            }
                          >
                            {ratingQuestion?.[1]?.questions
                              .filter(
                                (items) =>
                                  items.question_type == "deploy" &&
                                  items.question_condition == "maybe"
                              )
                              .map((itemvalues, i) => (
                                <>
                                  <Checkbox value={itemvalues.rate_id}>
                                    {itemvalues.reasons}
                                  </Checkbox>
                                  <hr />
                                </>
                              ))}
                            <TextArea
                              value={allValues.deployedComment}
                              onChange={handleDeployed}
                              placeholder={t("any_comments")}
                            />
                          </Checkbox.Group>
                        </Card>
                      </div>

                      <h3 className="wrongHeader">
                        <b>{t("rate_your_counterparty")} </b>
                      </h3>
                      <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        {ratingQuestion?.[1]?.questions
                          .sort((a, b) => a.priority - b.priority)
                          .filter(
                            (items) =>
                              items.question_type == "star" &&
                              items.question_condition == "yes"
                          )
                          .map((itemvalues, i) => (
                            <>
                              <h2 className="starText">
                                {itemvalues.reasons}:{" "}
                                <Rate
                                  allowClear={false}
                                  onChange={(value, reason) => {
                                    getRatings(value, itemvalues.rate_id, i);
                                  }}
                                  value={
                                    starRateObj
                                      ? starRateObj[itemvalues.rate_id]
                                      : 0
                                  }
                                />
                              </h2>
                            </>
                          ))}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {allValues.selectValueForBuyer == props.objInfo.filled ? (
                    <div className="DeployedRate">
                      <h3 className="wrongHeader">
                        <b>{t("rate_your_counterparty")} </b>
                      </h3>
                      <div style={{ margin: "15px" }}>
                        {ratingQuestion?.[1]?.questions
                          .filter(
                            (items) =>
                              items.question_type == "star" &&
                              items.question_condition == "yes"
                          )
                          .map((itemvalues, i) => (
                            <>
                              <h2 className="starText">
                                {itemvalues.reasons}:
                                <Rate
                                  allowClear={false}
                                  onChange={(value, reason) => {
                                    getRatings(value, itemvalues.rate_id, i);
                                  }}
                                  value={
                                    starRateObj
                                      ? starRateObj[itemvalues.rate_id]
                                      : 0
                                  }
                                />
                              </h2>
                            </>
                          ))}

                        <TextArea
                          value={allValues.deployedComment}
                          onChange={handleDeployed}
                          placeholder={t("any_comments")}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* if user selected no */}
                  {allValues.isNoBtnActive ? (
                    <div>
                      <h3 className="wrongHeader">
                        <b>{t("what_went_wrong")}?</b>
                      </h3>
                      <Card>
                        <Radio.Group
                          onChange={(e) => onValueChange(e)}
                          value={allValues.nocallvalue}
                        >
                          {ratingQuestion?.[1].questions
                            .filter(
                              (items) =>
                                items.question_type == "call" &&
                                items.question_condition == "no"
                            )
                            .map((itemvalues, i) => (
                              <>
                                <Radio key={i} value={itemvalues.rate_id}>
                                  {itemvalues.reasons}
                                </Radio>
                                <hr />
                              </>
                            ))}
                        </Radio.Group>

                        <TextArea
                          value={allValues.deployedComment}
                          onChange={handleDeployed}
                          placeholder={t("any_comments")}
                        />
                      </Card>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="FeedbackDiv">
                    {!dateDiff && isEdit ? (
                      <Button className="SubmitFeedback">
                        <span className="rat_modal_text">{t("you_can_edit_your_review_after_a_month")}</span>
                      </Button>
                    ) : !isEdit ? (
                      <Button className="SubmitFeedback">
                        <span className="rat_modal_text">{t("reached_limits_to_edit")}</span>
                      </Button>
                    ) : (
                      <Button
                        onClick={submitFeedback}
                        className="SubmitFeedback"
                      >
                        <b>{t("submit_feedback")}</b>
                      </Button>
                    )}
                  </div>
                </Spin>
              </Sheet.Container>
            </Sheet>
          ) : (
            ""
          );
        })}
    </div>
  );
};

export default RatingForBuyer;
