 export const loginTour=[
    {
      selector: ".help-container",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click on help button for guidance.</h4>: localStorage.getItem('language')=='hi'? <h4>मदद की ज़रूरत है? मार्गदर्शन के लिए सहायता बटन पर क्लिक करें। </h4>: localStorage.getItem('language')=='bn'?<h4>গাইডেন্সের জন্য হেল্প বোতামে ক্লিক করুন</h4>:<h4>வழிகாட்டுதலுக்கு உதவி பொத்தானைக் கிளிக் செய்யவும்.</h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    },
    {
      selector: ".ant-radio-group",
      content:()=>( <div>
        <h4>select your language / अपनी भाषा चुनें</h4>
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
  },
    {
        selector: ".login-input-tour",
        content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Enter your mobile number to start your wonderful journey</h4>: localStorage.getItem('language')=='hi'?<h4>अपनी शानदार यात्रा शुरू करने के लिए अपना मोबाइल नंबर दर्ज करें</h4>: localStorage.getItem('language')=='bn'?<h4>আপনার চমৎকার যাত্রা শুরু করতে আপনার মোবাইল নম্বর লিখুন</h4>:<h4>உங்கள் அற்புதமான பயணத்தைத் தொடங்க உங்கள் மொபைல் எண்ணை உள்ளிடவும்</h4>
        }
        </div>),
        style:{
          backgroundColor:"#ff8d1a",
          color:"white",
          paddingTop:'30px'
        }
    },
    {
      "selector": ".login-form-button",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click on SignIn button to continue your journey</h4>:localStorage.getItem('language')=='hi'? <h4>अपनी यात्रा जारी रखने के लिए साइन इन बटन पर क्लिक करें</h4>: localStorage.getItem('language')=='bn'?<h4>আপনার যাত্রা চালিয়ে যেতে সাইনইন বোতামে ক্লিক করুন</h4>:<h4>உங்கள் பயணத்தைத் தொடர SignIn பொத்தானைக் கிளிக் செய்யவும்</h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    }
]