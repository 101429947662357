import React from "react";
import CommonHeader from "../commonheaders/CommonHeader";
import './PrivacyPolicy.css'

/**
 * Privacy Policy Page in Bengali language
 * @param {*} props 
 * @returns 
*/
const PrivacyPolicyBengali = (props) => {
    return (
        <div className="privacy-container">
            <CommonHeader
                imageUrl="https://onesourcekarmayoga.com/wp-content/uploads/2021/05/vision_01_a-1.jpg"
                headerText={"গোপনীয়তা এবং কুকিজ"}
            />
            <div className="privacy-content-container">
                <h1>গোপনীয়তা নীতি</h1>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer" >www.onesourcekarmayoga.com</a> একটি কোম্পানি, ইন্টারনেট, ওয়ার্ল্ড ওয়াইড ওয়েব এবং ডিজিটাল ওয়ার্ল্ড ব্যবহারের মাধ্যমে তার ব্যবসা পরিচালনা করে। Onesource Karma Yoga Pvt Ltd, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">www.onesourcekarmayoga.com</a> এবং <a href="https ://www.karmayoga.app/" target="_blank" rel="noreferrer">karmayoga.app</a> একে অপরের সাথে ব্যবহার করা হয় এবং একসাথে কোম্পানি, এর ওয়েবসাইট এবং এর ওয়েব অ্যাপ্লিকেশনের প্রতিনিধিত্ব করে। একটির যেকোন রেফারেন্স অন্যদের এবং তদ্বিপরীত উল্লেখ করা যেতে পারে এবং তাই সেই অনুযায়ী বিবেচনা করা হবে।</p>
                <p>আমাদের গোপনীয়তা এবং নৈতিক নীতি পর্যালোচনা করতে অনুগ্রহ করে নীচে তালিকাভুক্ত নিবন্ধটি পড়ুন। দয়া করে মনে রাখবেন যে যখনই "আমাদের, আমরা, আমাদের" বা এর মতো অন্য কোনো রেফারেন্স উল্লেখ করা হয়, এর মানে হল যে আমরা কথা বলছি <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">“http://www.onesourcekarmayoga.com”</a> এবং যখনই "আপনি, আপনার, গ্রাহক" বলা হয়, তখন আমরা "একজন গ্রাহক হিসাবে আপনি" এবং দ্বিতীয় পক্ষ হিসাবে কথা বলছি</p>
                <p>আমাদের ওয়েবসাইট পরিদর্শন করে, আপনি এখানে গোপনীয়তা নীতি বিভাগে বর্ণিত অনুশীলনগুলি গ্রহণ করেন।</p>
                <p>আমাদের পণ্যগুলিতে নিজেকে ব্যবহার করা সর্বদা নিরাপদ এবং সুরক্ষিত থাকবে। আমাদের গোপনীয়তা নীতি কঠোরভাবে প্রয়োগ করা হয়েছে তাই আপনার তথ্য সর্বদা সম্পূর্ণ গোপন রাখা হয়। আমরা আপনার ব্যক্তিগত তথ্য সহ অননুমোদিত অ্যাক্সেস প্রতিরোধ এবং ডেটা নির্ভুলতা বজায় রাখতে আমাদের সহায়তা করার জন্য বিভিন্ন সুরক্ষা কৌশল ব্যবহার করি৷</p>
                <p>এটি ব্যতীত, আমরা কিছু যোগাযোগের তথ্য ব্যবহার করি যেমন নাম, প্রকৃত ঠিকানা, টেলিফোন নম্বর, ইমেল ঠিকানা, এবং গ্রাহকের স্বেচ্ছায় দেওয়া তথ্য, যেমন জরিপ তথ্য এবং/অথবা ব্যবসার প্রয়োজনের জন্য সাইট নিবন্ধন। প্রকৃতপক্ষে, আমরা যে তথ্য সংগ্রহ করি তা আমাদের অ্যাপ্লিকেশনের পরিষেবা উন্নত করতে, আমাদের অ্যাপ্লিকেশনের আপডেট সম্পর্কে ভোক্তাদের অবহিত করতে এবং প্রচার এবং কোম্পানির ঘোষণার রেকর্ড রাখার জন্য ব্যবহার করা হয়।</p>
                <p>আপনি যদি ভবিষ্যতে ইমেল, এসএমএস, হোয়াটসঅ্যাপ মেসেজ ইত্যাদি আকারে আমাদের কাছ থেকে কোনো যোগাযোগ পেতে না চান, তাহলে অনুগ্রহ করে যোগাযোগে উপলব্ধ ইমেল ঠিকানায় আমাদের ই-মেইল পাঠিয়ে আমাদের জানান। আমাদের বিভাগ।</p>
                <p>নিরাপত্তার ক্ষেত্রে, আমাদের শারীরিক <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> -এ যথাযথ নিরাপত্তা ব্যবস্থা রয়েছে আমাদের আবেদনে আপনার কাছ থেকে সংগ্রহ করা তথ্যের ক্ষতি, অপব্যবহার বা পরিবর্তন থেকে রক্ষা করার সুবিধা। প্রকৃতপক্ষে, SSL প্রত্যয়িত এবং এটি নিরাপদ এবং নিরাপদ আর্থিক এবং তথ্য লেনদেনের প্রতিশ্রুতি দেয়। উপরন্তু, আমরা আমাদের ওয়েবসাইট <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com </a>,ব্যবহার করার পদ্ধতি সম্পর্কে তথ্য সংগ্রহ করতে পারি "কুকিজ"-এর প্রয়োগের মাধ্যমে - যা আমাদের ওয়েব সার্ভার দ্বারা নির্ধারিত ডেটার টুকরো যা আপনার মেশিনে ব্রাউজারটিকে অনন্যভাবে সনাক্ত করে৷</p>
                <p>তবে, আপনার ব্রাউজারের মাধ্যমে যে কোনো সময় কুকিজ নিষ্ক্রিয় করার বিকল্প আছে, কিন্তু তারপরে আপনি আমাদের ওয়েবসাইটে কিছু বৈশিষ্ট্য অ্যাক্সেস করতে পারবেন না।</p>
                <p>আমরা আপনার গোপনীয়তা রক্ষা করতে প্রতিশ্রুতিবদ্ধ। আপনার পক্ষ থেকে সংগৃহীত যেকোনো তথ্য বিশুদ্ধ ব্যবসায়িক উন্নতি এবং গ্রাহক সেবার জন্য; একটি দুর্দান্ত অভিজ্ঞতা অর্জন করতে এবং আমাদের বাজারকে আরও ভালভাবে বুঝতে সাহায্য করার জন্য৷</p>
                <p>ব্যক্তিগত তথ্য</p>
                <p>এই নীতি নিম্নলিখিত ব্যক্তিগত তথ্য কভার করে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনার <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> ব্যবহারের মাধ্যমে সংগ্রহ করে এবং বন্ধের সাথে সম্পর্কিত - লাইন যোগাযোগ। বিশেষ করে, Onesource Karmayoga Pvt. Ltd., অফলাইন এবং অনলাইন নিবন্ধন, ফর্ম এবং যোগাযোগ এবং মিথস্ক্রিয়া এবং বিক্রয় অনুসন্ধান এবং লেনদেন থেকে এই তথ্য সংগ্রহ করতে পারে৷</p>
                <p>যোগাযোগের তথ্য, যেমন নাম, শারীরিক ঠিকানা, টেলিফোন নম্বর, ইমেল ঠিকানা, কোম্পানি, GSTIN, PAN, আধার নম্বর, ইত্যাদি... এই বিভাগের অধীনে সংগৃহীত বেশিরভাগ তথ্য হল ব্যবসায়িক যোগাযোগ এবং যাচাইকরণ তথ্য। কোন লেনদেন সম্পন্ন করার জন্য বা দেশের আইনের প্রয়োজনে যেখানে বিশেষভাবে ব্যবহার করা প্রয়োজন তা ছাড়া এগুলি গোপনীয় থাকবে৷</p>
                <p>সামাজিক মিডিয়া তথ্য, যেমন সোশ্যাল মিডিয়া হ্যান্ডেল, বিষয়বস্তু এবং অন্যান্য ডেটা আমাদের সাথে শেয়ার করা তৃতীয় পক্ষের বৈশিষ্ট্যগুলির মাধ্যমে যা আপনি আমাদের পরিষেবাগুলিতে ব্যবহার করেন (যেমন অ্যাপস, সরঞ্জাম, অর্থপ্রদান পরিষেবা, উইজেট এবং সোশ্যাল মিডিয়া পরিষেবাগুলি দ্বারা অফার করা প্লাগইনগুলি Facebook, Google , Instagram, LinkedIn, Pinterest, Twitter এবং YouTube) বা সোশ্যাল মিডিয়া পৃষ্ঠাগুলিতে পোস্ট করা হয়েছে (যেমন আমাদের সোশ্যাল মিডিয়া পৃষ্ঠাগুলি বা আমাদের কাছে অ্যাক্সেসযোগ্য অন্যান্য পৃষ্ঠাগুলি)৷</p>
                <p>আপনার অনুরোধ, ক্রয় বা ডাউনলোড করা পণ্য এবং পরিষেবার তথ্য সহ লেনদেন সংক্রান্ত তথ্য।</p>
                <p>আর্থিক/ক্রেডিট কার্ড এবং অর্থপ্রদানের তথ্য, ইভেন্ট এবং কনফারেন্সে অংশগ্রহণের তথ্য, ওয়ানসোর্স কর্ম যোগ প্রাইভেট লিমিটেড দ্বারা আয়োজিত ইভেন্ট বা কনফারেন্স চলাকালীন উপস্থিত বা পরিদর্শন করা বুথ। যদি আমরা একটি ইভেন্টে ইলেকট্রনিক ট্র্যাকিং প্রযুক্তি ব্যবহার করি, আমরা বিজ্ঞপ্তি প্রদান করব ইভেন্ট নিবন্ধন উপকরণ এবং/অথবা চিঠিপত্রে।</p>
                <p>ইলেকট্রনিক কমিউনিকেশন প্রোটোকল এবং কুকিজ: উপরে উল্লিখিত তথ্য ছাড়াও, OneSource Karma Yoga Pvt. লিমিটেড ওয়েবসাইট আপনার ব্যবহার সম্পর্কে তথ্য সংগ্রহ করতে পারে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> অথবা আবেদন <a href="https://www.karmayoga.app/" target="_blank" rel="noreferrer">karmayoga.app</a> ইলেকট্রনিক যোগাযোগ প্রোটোকল এবং কুকিজের মাধ্যমে </p>
                <p>আপনি অধিকাংশ ওয়েব সাইট পরিদর্শন যখন সত্য, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> যোগাযোগ সংযোগের অংশ হিসাবে স্বয়ংক্রিয়ভাবে আপনার কাছ থেকে ব্যক্তিগত তথ্য পেতে পারে, যা প্রায়শই নেটওয়ার্ক রাউটিং তথ্য (আপনি যেখান থেকে এসেছেন), সরঞ্জামের তথ্য (ব্রাউজারের প্রকার), এবং তারিখ এবং সময় নিয়ে গঠিত। <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এছাড়াও কুকি, এম্বেড করা URL এবং এম্বেড করা পিক্সেল ব্যবহার করে অতিরিক্ত তথ্য ক্যাপচার</p>
                <p>Cookies: <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> সার্ভার আপনার কম্পিউটারকে জিজ্ঞাসা করবে যে "কুকিজ" পূর্বে সেট করা আছে কিনা <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> ওয়েব সাইট। একটি কুকি হল একটি ওয়েব সার্ভার দ্বারা পাঠানো তথ্যের একটি ছোট টুকরো যা একটি ওয়েব ব্রাউজারে সঞ্চয় করতে পারে যাতে এটি পরে সেই ব্রাউজার থেকে পুনরায় পড়া যায়</p>
                <p>ওয়েব সাইটের কুকিগুলি একটি অনন্য শনাক্তকারী, ব্যবহারকারীর পছন্দ এবং প্রোফাইল তথ্য এবং সদস্যতার তথ্য সংগ্রহ করতে পারে। www.phdnutrition.ae সাধারণ ব্যবহার এবং ভলিউম পরিসংখ্যানগত তথ্য সংগ্রহ করতে কুকিজও ব্যবহার করে (যা ব্যক্তিগতভাবে সনাক্তযোগ্য তথ্য অন্তর্ভুক্ত করে না)।</p>
                <p>ওয়েব সাইট ছেড়ে যাওয়ার পরে কিছু কুকি ব্যবহারকারীর কম্পিউটারে থেকে যেতে পারে এবং বেশিরভাগের মেয়াদ 365 দিনের মধ্যে শেষ হয়ে যাবে। যদিও আমরা কুকির জন্য অপ্ট-আউট অফার করি না, ব্রাউজারগুলি আপনাকে তথ্য এবং কুকিগুলির উপর নিয়ন্ত্রণ প্রদান করে। আপনি যখন একটি কুকি ব্যবহার করা হচ্ছে তখন আপনাকে সতর্ক করতে এবং কুকি গ্রহণ বা প্রত্যাখ্যান করার জন্য আপনি আপনার ওয়েব ব্রাউজার সেট করতে পারেন। এছাড়াও আপনি আপনার ব্রাউজারকে সমস্ত কুকি প্রত্যাখ্যান করার জন্য সেট করতে পারেন বা শুধুমাত্র মূল সার্ভারে ফিরে আসা কুকিগুলি গ্রহণ করতে পারেন৷</p>
                <p>ব্যবহারকারীরা সাধারণত তাদের ব্রাউজারে কুকি বৈশিষ্ট্যটি নিষ্ক্রিয় করতে পারে তাদের সাইট ব্যবহার করার ক্ষমতাকে প্রভাবিত না করে, কিছু ক্ষেত্রে যেখানে কুকিগুলিকে লেনদেন সম্পূর্ণ করার জন্য প্রয়োজনীয় নিরাপত্তা বৈশিষ্ট্য হিসাবে ব্যবহার করা হয়।</p>
                <p>Embedded URLs: <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এছাড়াও একটি ট্র্যাকিং কৌশল ব্যবহার করে যা কুকি ছাড়াই সাইটের ব্যবহারের অনুমতি দেওয়ার জন্য এমবেডেড ইউআরএল নিয়োগ করে। এমবেডেড ইউআরএলগুলি সীমিত তথ্য আপনাকে অনুসরণ করার অনুমতি দেয় যখন আপনি সাইটটি নেভিগেট করেন তবে ব্যক্তিগত তথ্যের সাথে যুক্ত নয় এবং সেশনের বাইরে ব্যবহার করা হয় না।</p>
                <p>এমবেডেড পিক্সেল: ওয়েব সাইটে, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> অনন্য ব্যবহারকারীর ভিজিট সনাক্ত করার উদ্দেশ্যে নির্বাচিত পৃষ্ঠাগুলিতে এমবেডেড পিক্সেল প্রযুক্তি ব্যবহার করতে পারে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> মোট হিটের বিপরীতে। উপরন্তু, মার্কেটিং প্রচারাভিযানের প্রতিক্রিয়াশীলতা ট্র্যাক করার জন্য ইমেলটি কখন খোলা হয়েছিল তার তথ্য প্রদান করতে এই প্রযুক্তিগুলি ইমেলে ব্যবহার করা হয়</p>
                <p>তৃতীয় পক্ষের কুকিজ: <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> ওয়েবসাইটের দর্শকদের কাছ থেকে ব্যবহার এবং ভলিউম পরিসংখ্যানগত তথ্য (যা ব্যক্তিগত তথ্য অন্তর্ভুক্ত করে না) ট্র্যাক এবং বিশ্লেষণ করতে একটি তৃতীয় পক্ষকে নিযুক্ত করেছে, যা এর পক্ষে কুকি সেট করে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এবং এর পারফরম্যান্স ট্র্যাক করতে অন্যান্য তৃতীয় পক্ষের কুকিজও ব্যবহার করে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> তাদের ওয়েবসাইটে বিজ্ঞাপন.</p>
                <p>এই তৃতীয় পক্ষের দ্বারা প্রদত্ত তথ্য ব্যক্তিগত তথ্য অন্তর্ভুক্ত করে না কিন্তু একবার ব্যক্তিগত তথ্যের সাথে পুনরায় যুক্ত হতে পারে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer ">http://www.onesourcekarmayoga.com</a> এটি গ্রহণ করে।</p>
                <p></p>
                <p>ব্যক্তিগত তথ্য ব্যবহার এবং শেয়ার করা</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> নিম্নলিখিত উদ্দেশ্যে আপনার ব্যক্তিগত তথ্য ব্যবহার করে (এবং, যেখানে নির্দিষ্ট করা আছে, শেয়ার করে)</p>
                <p>– লেনদেনে নিযুক্ত করা. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনার অর্ডার সম্পর্কে আপনার সাথে যোগাযোগ করা সহ আপনার সাথে লেনদেনে জড়িত থাকার জন্য নাম, শারীরিক ঠিকানা, টেলিফোন নম্বর, ইমেল ঠিকানা এবং কোম্পানির মতো ব্যক্তিগত তথ্য ব্যবহার করতে পারে।</p>
                <p>– লেনদেন প্রক্রিয়া করতে. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনার অর্ডার প্রক্রিয়া করতে আর্থিক/ক্রেডিট কার্ড এবং অর্থপ্রদানের তথ্য ব্যবহার করতে পারে। <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> ডেলিভারির সাথে এই তথ্যের কিছু শেয়ার করতে হতে পারে সেবা; ক্রেডিট কার্ড ক্লিয়ারিং হাউস এবং অন্যান্য অনুরূপভাবে অবস্থিত তৃতীয় পক্ষগুলি লেনদেন সম্পূর্ণ করতে।</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনার ক্রেডিট কার্ডের তথ্য সংরক্ষণ বা শেয়ার করবে না এবং সর্বদা আপনার তথ্য এনক্রিপ্ট করতে সর্বোচ্চ নিরাপত্তা ব্যবহার করবে।</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> কখনই সংরক্ষণ করবে না (একটি হ্যাশড ফর্ম্যাট ছাড়া) বা আপনার আধার তথ্য শেয়ার করবে না এবং সর্বদা আপনার তথ্য এনক্রিপ্ট করতে সর্বোচ্চ নিরাপত্তা ব্যবহার করবে।</p>
                <p>– সহায়তা বা অন্যান্য পরিষেবা প্রদান করতে। <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনার ব্যক্তিগত তথ্য ব্যবহার করতে পারে আপনাকে সহায়তা বা আপনার অর্ডার করা অন্যান্য পরিষেবা, সেইসাথে পণ্য আপডেট, পণ্য প্যাচ এবং সংশোধন এবং অন্যান্য অনুরূপ যোগাযোগ প্রদান করতে</p>
                <p>– আপনার চাহিদা অনুযায়ী বিপণন করতে এবং আপনার অনুরোধে সাড়া দিতে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনার অর্ডার থেকে তথ্য ব্যবহার করতে পারে, আপনার <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> প্রোফাইল, বা অন্যান্য নির্দিষ্ট প্রয়োজনীয়তা আপনাকে নতুন পণ্য রিলিজ এবং পরিষেবা উন্নয়নের বিজ্ঞপ্তি প্রদান করতে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এছাড়াও নিউজলেটারের জন্য নিবন্ধন বা অন্যান্য নির্দিষ্ট অনুরোধ সহ তথ্যের জন্য আপনার অনুরোধে সরাসরি সাড়া দিতে আপনার ব্যক্তিগত তথ্য ব্যবহার করতে পারে বা আপনার যোগাযোগের তথ্য যথাযথের কাছে পাঠাতে পারে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনার অনুরোধে সাড়া দিতে পরিবেশক বা রিসেলার.</p>
                <p>এছাড়াও, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনি আপনার যোগাযোগের তথ্য অন্য নির্বাচিত অংশীদারদের সাথে ভাগ করতে চান যা পরিপূরক পণ্য বা পরিষেবা সরবরাহ করে তা জিজ্ঞাসা করতে পারেন। যদি তুমি রাজি থাক, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> সেই অংশীদারদের আপনার যোগাযোগের তথ্য প্রদান করবে। অনুগ্রহ করে নির্দিষ্ট অংশীদারের সাথে আরও কোনো ইন্টারঅ্যাকশনের বিষয়ে তার গোপনীয়তা নীতি পর্যালোচনা করুন</p>
                <p>- অনলাইন ফোরাম প্রদান করতে। ওয়েব সাইটগুলিতে উপলব্ধ কিছু পরিষেবা আপনাকে ইন্টারেক্টিভ আলোচনায় অংশ নিতে বা বুলেটিন বোর্ড বা বিনিময়ে মন্তব্য বা সুযোগ পোস্ট করার অনুমতি দেয়। এই পরিষেবাগুলির মধ্যে কিছু পরিমিত; সমস্ত প্রযুক্তিগত কারণে অ্যাক্সেস করা যেতে পারে. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> ব্যবহারকারীরা এতে পোস্ট করে এমন সামগ্রী নিয়ন্ত্রণ করে না ফোরাম।</p>
                <p>আপনি এই ফোরামগুলিতে ব্যক্তিগত তথ্য জমা দিতে চান কিনা তা আপনার যত্ন সহকারে বিবেচনা করা উচিত এবং আপনার জমা দেওয়া যেকোনো বিষয়বস্তু যথাযথভাবে এবং প্রাসঙ্গিক ব্যবহারের শর্তাবলী অনুসারে তৈরি করা উচিত।</p>
                <p>– বিষয়বস্তু নির্বাচন করতে, গুণমান উন্নত করতে এবং ওয়েব সাইটগুলির ব্যবহার সহজতর করতে, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www .onesourcekarmayoga.com</a> আপনার ব্যক্তিগত তথ্য ব্যবহার করতে পারে, যার মধ্যে সাইট নেভিগেশন এবং ইলেকট্রনিক প্রোটোকল এবং কুকিজ (তৃতীয় পক্ষের কুকিজ সহ) এর ফলে সংগৃহীত তথ্য সহ, ওয়েব সাইটের বিষয়বস্তু তৈরি এবং ব্যক্তিগতকৃত করতে সাহায্য করতে, আপনার ব্যবহারের সুবিধার্থে ওয়েব সাইটগুলি (উদাহরণস্বরূপ, নেভিগেশন এবং লগইন প্রক্রিয়া সহজতর করতে, ডুপ্লিকেট ডেটা এন্ট্রি এড়াতে, নিরাপত্তা বাড়াতে, শপিং কার্ট সংযোজনের ট্র্যাক রাখা এবং সেশনগুলির মধ্যে অর্ডার তথ্য সংরক্ষণ করা), ওয়েব সাইটের গুণমান উন্নত করা, বিপণন প্রচারাভিযানের প্রতিক্রিয়াশীলতা ট্র্যাক করা এবং পৃষ্ঠার প্রতিক্রিয়া মূল্যায়ন করা হার।</p>
                <p>– বিভিন্ন প্রদান করতে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> সম্প্রদায়গুলি, যেমন অংশীদারদের, প্রাসঙ্গিক পণ্য সতর্কতা এবং আপডেট সহ। যদি আপনি একটি জন্য সাইন আপ করুন <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> সম্প্রদায় <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এছাড়াও আপনাকে তথ্য প্রদান করবে যেখানে আপনি নতুন পণ্য প্রকাশ, সতর্কতা, আপডেট, মূল্য, শর্তাবলী, বিশেষ অফার এবং সংশ্লিষ্ট প্রচারাভিযানের সাথে সম্পর্কিত আগ্রহ প্রকাশ করেছেন</p>
                <p>– যৌথ বিক্রয় বা পণ্য প্রচার ক্ষমতা. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এবং এর অংশীদাররা যৌথ বিক্রয় বা পণ্য প্রচারে নিযুক্ত হতে পারে। এই ধরনের প্রচার সবসময় জড়িত অংশীদারদের উল্লেখ করবে। উভয় <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এবং অংশীদার(দের) সেই তথ্যে অ্যাক্সেস থাকবে, এবং হয় www.phdnutrition.ae বা আমাদের অংশীদাররা আপনাকে বিক্রয় বা পণ্য প্রচারের তথ্য প্রদান করতে পারে। আপনার বিশেষ প্রয়োজন এবং উদ্বেগগুলিকে সমাধান করতে আমরা আপনাকে এই অংশীদারদের গোপনীয়তা নীতিগুলি পর্যালোচনা করার পরামর্শ দিই৷</p>
                <p>- একটি রেফারেলের সাথে যোগাযোগ করতে/"একজন বন্ধুকে বলুন"। আপনি যদি আমাদের সাইট সম্পর্কে একজন বন্ধুকে জানানোর জন্য আমাদের রেফারেল পরিষেবা ব্যবহার করতে চান, আমরা আপনাকে বন্ধুর নাম এবং ইমেল ঠিকানা জিজ্ঞাসা করব। <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> স্বয়ংক্রিয়ভাবে বন্ধুকে একবারের ইমেল পাঠাবে সাইট দেখার জন্য তাদের আমন্ত্রণ. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> পাঠানোর একমাত্র উদ্দেশ্যে এই ব্যক্তিগত তথ্য ব্যবহার করে এই এককালীন ইমেল এবং আমাদের রেফারেল প্রোগ্রামের সাফল্য ট্র্যাকিং. বন্ধুটি <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> info@onesourcekarmayoga.com-এ যোগাযোগ করতে পারে রেফারেল প্রোগ্রাম থেকে সরানো হবে।</p>
                <p>- একটি সম্মেলন বা অনুষ্ঠান সম্পর্কে আপনার সাথে যোগাযোগ করতে। আমরা বা আমাদের অংশীদাররা <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http:// দ্বারা হোস্ট করা বা সহ-স্পন্সর করা একটি সম্মেলন বা ইভেন্ট সম্পর্কে আপনার সাথে যোগাযোগ করতে পারি www.onesourcekarmayoga.com</a> অথবা আমাদের অংশীদারদের একজন। এতে ইভেন্টের বিষয়বস্তু, ইভেন্ট লজিস্টিকস, অর্থপ্রদান, আপডেট এবং কোনো অতিরিক্ত মিটিং, বিশেষ প্রদর্শন বা অন্যান্য গ্রাহক সুবিধা যা আমরা ইভেন্টের সাথে সম্পর্কিত প্রদান করতে পারি সে সম্পর্কে তথ্য অন্তর্ভুক্ত থাকতে পারে।</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> সম্পর্কে ইভেন্টের পরে আপনার সাথে যোগাযোগ করতে পারে ঘটনা এবং সম্পর্কিত পণ্য এবং পরিষেবা। <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এছাড়াও মনোনীত অংশীদার বা কনফারেন্স স্পনসরদের পাঠানোর অনুমতি দেবে আপনি একটি তৃতীয় পক্ষের বন্ডেড গুদামের পরিষেবার মাধ্যমে দুটি পর্যন্ত যোগাযোগ করতে পারেন৷</p>৷
                <p>দয়া করে মনে রাখবেন যে আমাদের অংশীদার বা কনফারেন্স স্পনসররা সরাসরি তাদের কনফারেন্স বুথ বা উপস্থাপনায় আপনার ব্যক্তিগত তথ্যের জন্য অনুরোধ করতে পারে; যাইহোক, আপনার তথ্য প্রদান করা ঐচ্ছিক, এবং আপনার প্রয়োজন এবং উদ্বেগগুলি সমাধান করার জন্য আপনাকে তাদের গোপনীয়তা নীতিগুলি পর্যালোচনা করা উচিত৷</p>
                <p>- আইনি প্রয়োজনীয়তা মেনে চলার জন্য। আপনার ব্যক্তিগত তথ্য প্রকাশ করার জন্য <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> প্রয়োজন হতে পারে আইনগতভাবে বাধ্যতামূলক রিপোর্টিং, প্রকাশ বা অন্যান্য আইনি প্রক্রিয়া প্রয়োজনীয়তা মেনে চলতে। <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> ব্যক্তিগত তথ্য সীমিত করার জন্য যুক্তিসঙ্গত প্রচেষ্টা ব্যবহার করবে এই উদ্দেশ্যে প্রদান করে।</p>
                <p>অনুগ্রহ করে মনে রাখবেন যে আপনার ব্যক্তিগত তথ্য এই নীতি অনুসারে উপরে উল্লিখিত ব্যবহারের জন্য প্রয়োজনীয় হিসাবে বিশ্বব্যাপী স্থানান্তর, অ্যাক্সেস এবং সংরক্ষণ করা হতে পারে৷</p>
                <p>নিবন্ধন, কাস্টমাইজেশন, পছন্দ এবং অপ্ট-আউট</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> বিপণন যোগাযোগের জন্য আপনাকে একটি অনলাইন নিবন্ধন প্রোফাইল স্থাপন করতে দেয় <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনি ইলেকট্রনিক তথ্য প্রাপ্তির জন্য আপনার পছন্দগুলি কাস্টমাইজ করতে এবং নিয়ন্ত্রণ করতে প্রোফাইলটি ব্যবহার করতে পারেন, সম্প্রদায়গুলিতে নির্বাচন করা (বা আউট) সহ৷</p>
                <p>আপনি আপনার নির্বাচন পরিবর্তন করতে বা আপনার প্রোফাইল পরিবর্তন করতে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer-এ যেকোনও সময় এটি কাস্টমাইজ করতে নিবন্ধন প্রোফাইলে ফিরে যেতে পারেন ">http://www.onesourcekarmayoga.com</a> অনুগ্রহ করে যথারীতি লগইন করুন এবং "আমার প্রোফাইল আপডেট করতে" প্রম্পটগুলি অনুসরণ করুন৷</p>
                <p>নির্বাচন করা হচ্ছে। <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> আপনাকে কিছু বা সমস্ত ইমেল অপ্ট আউট করতে সক্ষম করে একটি আনসাবস্ক্রাইব লিঙ্কের মাধ্যমে বিপণন। এর একটি লিঙ্ক মার্কেটিং ইমেইলে পাওয়া যাবে। এছাড়াও আপনি আমাদের সাথে যোগাযোগ করুন পৃষ্ঠার মাধ্যমে একটি ইমেল পাঠিয়ে সমস্ত ইমেল বিপণনের সদস্যতা ত্যাগ করতে পারেন৷</p>
                <p>তথ্য শেয়ারিং পছন্দ. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> ব্যবহারকারীদের হয় অপ্ট আউট বা শেয়ারিং অপ্ট ইন করার ক্ষমতা প্রদান করার পরে শুধুমাত্র নির্বাচিত কোম্পানির সাথে ব্যক্তিগত তথ্য শেয়ার করে</p>
                <p>(1) যৌথ বিক্রয় বা যৌথ ইভেন্টের ক্ষেত্রে এই ধরনের শেয়ারিং থেকে অপ্ট আউট করার সীমাবদ্ধতা রয়েছে যেখানে ডেটা উভয়ের কাছে যায় <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এবং একই সময়ে অন্য পক্ষ; (2) যেখানে গ্রাহক ফলোআপের জন্য অনুরোধ করেছেন যা জড়িত<a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> অংশীদার; এবং (3) যেখানে তৃতীয় পক্ষের লেনদেন সম্পন্ন করতে বা পরিষেবা সম্পাদনের প্রয়োজন হয়, ডেলিভারি এবং ক্রেডিট কার্ড ক্লিয়ারিং পরিষেবা সহ</p>
                <p>তৃতীয় পক্ষের অনুমতি-ভিত্তিক তালিকা.</p>
                <p>মাঝে মাঝে, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> সম্পর্কে যোগাযোগ পাঠাতে একটি তৃতীয় পক্ষের অনুমতি-ভিত্তিক তালিকা ব্যবহার করতে পারে <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> পণ্য এবং সেবা. এই তালিকাগুলি তৃতীয় পক্ষের দ্বারা রক্ষণাবেক্ষণ করা হয় যাদের ব্যবহারকারীরা অন্যান্য কোম্পানি থেকে ইমেল পেতে সম্মতি দিয়েছেন, যেমন <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a></p>
                <p>এই তালিকার নাম এবং যোগাযোগের তথ্যে আমাদের অ্যাক্সেস নেই। আমরা তালিকার মালিকদের ইমেলের মেইলার হিসাবে নিজেদেরকে চিহ্নিত করতে এবং উপযুক্ত অপ্ট-আউট প্রক্রিয়া প্রদান করতে চাই। আপনি যদি তৃতীয় পক্ষের তালিকা থেকে অপসারণ করতে চান, তাহলে আপনাকে আপনার অপ্ট-আউট অনুরোধটি তালিকার মালিকের কাছে পাঠানো উচিত, না <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a></p>
                <p>আপনার যদি অপ্ট আউট করতে সমস্যা হয়, অথবা আপনি যদি তৃতীয় পক্ষের মেইলিং সংক্রান্ত কোনো সমস্যা সম্পর্কে আমাদের সচেতন করতে চান, তাহলে আপনি আমাদের সাথে যোগাযোগ করুন পৃষ্ঠার মাধ্যমে সরাসরি আমাদের সাথে যোগাযোগ করতে পারেন। যখন তৃতীয় পক্ষের মেলিংগুলি <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> উত্তর দিয়ে পাঠানো হয় ঠিকানা, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এছাড়াও সম্পর্কে তথ্য সহ একটি বিজ্ঞপ্তি প্রদান করবে ভবিষ্যতের <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> মার্কেটিং যোগাযোগ থেকে কীভাবে অপ্ট-আউট করবেন৷ </p>
                <p>বিজ্ঞাপন</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> সীমিত সেট পরিচালনা করতে কিছু তৃতীয় পক্ষ ব্যবহার করে<a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> এর ওয়েব সাইটগুলিতে বিজ্ঞাপন। এই প্রক্রিয়ার অংশ হিসাবে বিজ্ঞাপনদাতাদের কোন ব্যক্তিগত তথ্য প্রদান করা হয় না, তবে ব্যবহারকারী সম্প্রদায়ের মতো সামগ্রিক প্রোফাইল তথ্য, ব্যবহারকারীর সাথে প্রাসঙ্গিকতা আছে কিনা তা নিশ্চিত করতে বিজ্ঞাপন নির্বাচনের ক্ষেত্রে ব্যবহার করা যেতে পারে</p>
                <p>কিছু ​​ব্যানার বিজ্ঞাপনে, একটি এমবেডেড পিক্সেল উপস্থিত থাকতে পারে, এবং এটি একটি কুকি বা অন্যান্য ব্যক্তিগত প্রোফাইল তথ্যের সাথে যুক্ত না হলেও, এটি সেশন সংযোগের তথ্য ফেরত দিতে পারে যা বিজ্ঞাপনদাতাদের আরও ভালভাবে নির্ধারণ করতে দেয় যে কতজন পৃথক ব্যবহারকারী ক্লিক করেছেন বিজ্ঞাপন ব্যানার।</p>
                <p>নিরাপত্তা</p>
                <p>আপনার ব্যক্তিগত তথ্যের নিরাপত্তা খুবই গুরুত্বপূর্ণ <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a>আপনার ব্যক্তিগত তথ্যকে অননুমোদিত অ্যাক্সেস থেকে রক্ষা করার জন্য আমাদের কাছে শারীরিক, প্রশাসনিক এবং প্রযুক্তিগত সুরক্ষা ব্যবস্থা রয়েছে। এছাড়াও, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> ক্রেডিট কার্ডের বিবরণের মতো সংবেদনশীল ডেটা ট্রান্সমিশন আদান-প্রদানের জন্য সর্বোচ্চ নিরাপত্তা প্রোটোকল এবং প্রক্রিয়া ব্যবহার করে</p>
                <p>মেনে চলার বিষয়</p>
                <p>যদি আপনি বিশ্বাস করেন যে আপনার ব্যক্তিগত তথ্য এমনভাবে ব্যবহার করা হয়েছে যা এই নীতি বা আপনার নির্দিষ্ট পছন্দের সাথে সামঞ্জস্যপূর্ণ নয়, অথবা যদি এই নীতি সম্পর্কিত আপনার আরও প্রশ্ন থাকে, তাহলে অনুগ্রহ করে আমাদের সাথে যোগাযোগ করুন পৃষ্ঠার মাধ্যমে ইমেলের মাধ্যমে আমাদের সাথে যোগাযোগ করুন৷ </p>
                <p>নীতির আপডেট</p>
                <p>আমরা সময়ে সময়ে এই নীতি আপডেট করতে পারি। আমরা এই নীতির এই বিভাগে একটি বিশিষ্ট বিজ্ঞপ্তি পোস্ট করব যাতে এটি আপডেট করা হলে ব্যবহারকারীদের অবহিত করা হয়। যদি আমরা সংগ্রহের সময় উল্লিখিত পদ্ধতির থেকে ভিন্নভাবে আপনার ব্যক্তিগত তথ্য ব্যবহার করতে যাচ্ছি, আমরা আপনাকে ইমেলের মাধ্যমে অবহিত করব, এবং আমরা এই ভিন্ন পদ্ধতিতে আপনার ব্যক্তিগত তথ্য ব্যবহার করব কি না তা আপনার কাছে একটি পছন্দ থাকবে। </p>
                <p>আমরা আপনার মন্তব্য প্রশংসা করি <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> গোপনীয়তা অনুশীলন। আমাদের সাথে যোগাযোগ করুন পৃষ্ঠার মাধ্যমে সমস্ত ই-মেইল অনুসন্ধান করা যেতে পারে।</p>
            </div>
        </div>
    )
}

export default PrivacyPolicyBengali