import React from "react";
import CommonHeader from "../commonheaders/CommonHeader";
import './AboutUs.css'
import { Image } from "antd";

/**
 * About Us Page in Hindi
 * @param {*} props 
 * @returns 
*/
const AboutUsHindi = (props) => {
    return (
        <div className="aboutus-conatiner">
            <CommonHeader
                imageUrl="https://template-karmayoga.s3.ap-south-1.amazonaws.com/how+we+got+started.png"
                headerText="हमारे बारे में"
                subTitle="सामाजिक परिवर्तन के उद्देश्य से एक पारदर्शी बाज़ार बनाना"
            />
            <div className="aboutus-content-container">
                <div className="aboutus-title">
                    हमने कैसे शुरुआत की
                </div>
                <div className="aboutus-subcontent">
                    <div>
                        <Image
                            src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/how+we+got+started.png"}
                            title={"Company"}
                            width={"100%"}
                            preview={false}
                        />
                    </div>
                    <div className="aboutus-details">
                        <p className="aboutus-details-header">हम उद्यमियों का एक समूह हैं, जिनके पास रियल एस्टेट, निर्माण और सॉफ्टवेयर विकास में एक साथ बहुत बड़ा अनुभव है - जो हमें इस नए व्यापार विचार का 360⁰ परिप्रेक्ष्य प्रदान करता है।</p>
                        <p>सबसे महत्वपूर्ण बात यह है कि हम समाज को वापस देने और सामाजिक परिवर्तन को प्रोत्साहित करने के लिए एक जुनून साझा करते हैं।</p>
                        <p>संगठन इन शक्तियों का उपयोग करता है, और इस सिद्धांत पर काम करता है कि संपूर्ण इसके भागों के योग से कहीं अधिक है।</p>
                        <p>वन सोर्स कर्म योग प्राइवेट लिमिटेड, दिसंबर 2020 में स्थापित किया गया था। हमें एक स्टार्ट अप संगठन के रूप में प्रमाणित किया गया है। (नीचे प्रति देखें)।</p>
                    </div>
                </div>
                <div className="aboutus-layer-content">
                    <div className="aboutus-title custom-border">
                         द बिजनेस आइडिया
                    </div>
                    <div className="aboutus-sublayers aboutus-layer-content">
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                                <p>कनेक्ट करना</p> <p>&nbsp;गैप</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Connecting+the+gap.png"}
                                    title={"Company"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                                <p>हमारा व्यावसायिक विचार श्रमिकों की आपूर्ति और उनके कौशल की मांग को जोड़ने में भारी अंतर को संबोधित करता है - जो भौगोलिक दूरी और वर्तमान अनौपचारिक भर्ती प्रक्रिया के कारण होता है।</p>
                                <p>हमारा एप्लिकेशन, कामगारों की आपूर्ति और मांग के आसान और निर्बाध मिलान की अनुमति देने के लिए मालिकाना एल्गोरिदम का उपयोग करता है। सादगी आपको हैरान कर देगी!</p>
                            </div>
                        </div>
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                                <p>पारदर्शी</p> <p>&nbsp;बाजार मूल्य</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Transparent+Marketprice.png"}
                                    title={"BestPrice"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                            <p>सबसे महत्वपूर्ण बात यह है कि ऐप एक बहुत ही आवश्यक सामाजिक परिवर्तन की शुरुआत करने की उम्मीद करता है। यह एक पारदर्शी मुक्त बाजार के आर्थिक सिद्धांतों को नियोजित करता है - जो नियोक्ताओं और श्रमिकों दोनों को सेवाओं के लिए उचित बाजार मूल्य खोजने के लिए प्रोत्साहित करता है। ऐप मूल्य असमानता को भी संबोधित करता है, और यह कौशल स्तरों के साथ मूल्य समानता को भी प्रोत्साहित करता है - श्रमिकों को अपस्किल करने के लिए प्रोत्साहित करना।</p>
                            </div>
                        </div>
                        <div className="aboutus-sublayer">
                            <div className="aboutus-sublayer-heading">
                            <p>सुधार</p> <p>&nbsp;उत्पादकता</p>
                            </div>
                            <div>
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/Improving+Productivity.png"}
                                    title={"BestPrice"}
                                    width={"160px"}
                                    preview={false}
                                />
                            </div>
                            <div className="aboutus-sublayer-content">
                                <p>बाजार की खोज की यह प्रक्रिया कामगारों के लिए बेहतर रहने की स्थिति को भी प्रोत्साहित करती है क्योंकि ये प्रासंगिक कीमतों पर ऑनबोर्डिंग कामगारों के लिए प्रासंगिकता प्राप्त करती हैं।</p>
                                <p>एक अच्छा चक्र बनाकर यह ऐप श्रमिकों की उत्पादकता में सुधार को प्रोत्साहित करेगा जिससे उद्योग में व्यापक सुधार होगा।</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutus-layer-content">
                    <div className="aboutus-title custom-border">
                    कंपनी की जानकारी
                    </div>
                    <div className="aboutus-company-info-container">
                        <Image.PreviewGroup>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/certificate+of+incorporation.jpg"}
                                    title={"Incorporationcertificate"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                               <p>निगमन प्रमाणपत्र</p>
                            </div>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/certificate+of+recognition.png"}
                                    title={"Startupindia"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                                <p>स्टार्टअप इंडिया की पहचान</p>
                            </div>
                            <div className="aboutus-company-info">
                                <Image
                                    src={"https://template-karmayoga.s3.ap-south-1.amazonaws.com/registration.png"}
                                    title={"registration"}
                                    width={"180px"}
                                    height={'180px'}
                                />
                                <p>जीएसटी पंजीकरण</p>
                            </div>
                        </Image.PreviewGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsHindi