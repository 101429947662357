import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useDispatch } from "react-redux";
import './CompanyProfile.css'
import { setNavIdAction } from '../../redux-core/login/actions';
import HelpPage from "../helppage/HelpPage";


const actionDispatch = (dispatch) => ({
	setNavId: (payload) => dispatch(setNavIdAction(payload)),
});

/**
 * Company Profile Page
 * @param {*} props 
 * @returns 
 */
const CompanyProfile = (props) => {
    
    const { setNavId } = actionDispatch(useDispatch())

    useEffect(()=>{
        if(props.width>='1000'){
			document.querySelectorAll('.nav_ele').forEach((ele)=>{
				ele.classList.remove('active');
			})
			setNavId('nav_ele_5')
		}
		else{
			document.querySelectorAll('.mob_ele').forEach((ele)=>{
				ele.classList.remove('active');
			})
			setNavId('mob_ele_5')
		}
    },[])

    if(!window.localStorage.getItem("userToken")){
        window.location.href='/#/login'
    }

    return (
        <div className="company-profile-container" style={{paddingTop: '30px'}}>
			<div className="page_heading">About Us</div>
            {/* <Tabs defaultActiveKey="2" className="DashboardTab" centered >
                <TabPane tab="About Us" key="2" forceRender={true}>
                    <AboutUs />
                </TabPane>
                <TabPane tab="Privacy Policy" key="3">
                    <PrivacyPolicy />
                </TabPane>
                <TabPane tab={`Terms & Condition`} key="4">
                    <TermsAndCondition />
                </TabPane>
                <TabPane tab="Cancellation Policy" key="5">
                    <CancellationPolicy />
                </TabPane>
            </Tabs>            */}
            <HelpPage/>
        </div>
    )
}

export default CompanyProfile