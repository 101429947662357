import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationHN from "./locales/hn/translation.json";
import translationBN from "./locales/bn/translation.json";
import translationTA from "./locales/ta/translation.json";


//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  hi: {
    translation: translationHN,
  },
  bn: {
    translation: translationBN,
  },
  ta: {
    translation: translationTA,
  }
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    // lng:localStorage.getItem("language"), //default language
    lng:localStorage.getItem("language")==null ? 'en':localStorage.getItem("language"), //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;