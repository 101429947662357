/* global google */
import React from "react";
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import { RUPEE_SYMBOL } from "../../utils/constants";

const {
  MarkerWithLabel
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const Map = props => {
  const labelSize = { width: 50 };
  const labelPadding = 5;

  var iconPinSeller = {
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
    fillColor: "#1890ff",
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 1,
    scale: 1
  };

  var iconPinBuy = {
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
    fillColor: "#fc8f3f",
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 1,
    scale: 1
  };

  return (
    <GoogleMap defaultZoom={props.zoom} defaultCenter={props.center}>
      {props.places.map((placedata, index) => (
        placedata.location.map((place, i) => (
          place.median_price !== 0 ?
            <MarkerWithLabel
              labelStyle={{
                textAlign: "center",
                width: labelSize.width + "px",
                backgroundColor: "#ccc",
                border: "1px solid",
                fontSize: "12px",
                borderRadius: "5px",
                padding: labelPadding + "px"
              }}
              labelClass="map-label"
              labelAnchor={{ x: labelSize.width / 2 + labelPadding, y: 70 }}
              key={i}
              icon={placedata.job_type === "Seller" ? iconPinSeller : iconPinBuy}
              position={{ lat: place.lat ? Number(place.lat) : 12.254, lng: place.long ? Number(place.long) : 77.2545 }}
            >
              <span>{RUPEE_SYMBOL}{place.median_price ? place.median_price : "0"}</span>
            </MarkerWithLabel>
            : ""

        ))
      ))}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
