import React, { useState, useRef } from "react"; import { Modal, Button, notification } from "antd";
import { useDispatch } from "react-redux";
import {
	updateProfilePictureAction,
	deleteProfilePictureAction,
} from "../../redux-core/userDetails/actions";
import { CameraOutlined, RollbackOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Webcam from "react-webcam";
import imageCompression from "browser-image-compression";
import { useTranslation } from "react-i18next";

const actionDispatch = (dispatch) => ({
	updateProfilePicture: (file, fileName, key) =>
		dispatch(updateProfilePictureAction(file, fileName, key)),
	deleteProfilePicture: (current_key) =>
		dispatch(deleteProfilePictureAction(current_key)),
});

export default function ProfilePictureModal({
	isModalVisible,
	setIsModalVisible,
	windowDimensions,
	profilePic,
}) {
	const [picture, setPicture] = useState({
		data: "",
		source: "",
	});
	const [imagePreview, setImagePreview] = useState();
	const [videoActive, setVideoActive] = useState(false);
	const { updateProfilePicture, deleteProfilePicture } = actionDispatch(
		useDispatch()
	);
	const { t, i18n } = useTranslation();

	let videoRef = useRef(null);
	let fileInput = useRef(null);

	const base64ToBlobType = (b64Data, contentType = "", sliceSize = 512) => {
		const byteCharacters = window.atob(b64Data);
		const byteArrays = [];
		for (
			let offset = 0;
			offset < byteCharacters.length;
			offset += sliceSize
		) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};

	const fileUploadHandler = async () => {
		try {
			if (picture.data.size > 1048576) {
				notification.error({
					message: "Please upload image less than 1 MB.",
				});
				return
			}
			let optimisedImage = picture.data;
			let old_key = null;
			let options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 300,
				useWebWorker: true,
			};
			if (picture.source === "webcam") {
				optimisedImage = base64ToBlobType(
					picture.data.split(",")[1]
				);
			} else {
				optimisedImage = await imageCompression(
					optimisedImage,
					options
				);
			}
			if (picture.data.size < optimisedImage.size) {
				optimisedImage = picture.data
			}
			const new_key = uuidv4();
			if (profilePic) {
				const tempArray = profilePic.split("/");
				old_key = tempArray[tempArray.length - 1];
			}
			updateProfilePicture(optimisedImage, old_key, new_key);
		} catch (error) {
			notification.error({
				message: "Failed to Upload.",
			});
		}
	};

	const videoConstraints = {
		facingMode: "user",
	};

	const handleCancel = () => {
		setVideoActive(false);
		setTimeout(() => {
			setIsModalVisible(false);
			removePreview(false);
		}, 250);
		// settimeout set to close webcam by changing the videoActive state
	};

	const fileSelectHandler = (e) => {
		if (e.target.files[0].size > 1048576) {
			notification.error({
				message: "Please upload image less than 1 MB.",
			});
			return
		}
		const objectUrl = URL.createObjectURL(e.target.files[0]);
		setPicture({ data: e.target.files[0], source: "system" });
		setImagePreview(objectUrl);
	};

	const enableSelfieVideo = () => {
		setVideoActive(true);
	};

	const takePicture = (e) => {
		setImagePreview(videoRef.current.getScreenshot());
		setPicture({
			data: videoRef.current.getScreenshot(),
			source: "webcam",
		});
	};

	const removePreview = () => {
		setImagePreview(false);
	};

	const DeleteProfilePicture = () => {
		if (profilePic) {
			const tempArray = profilePic.split("/");
			const current_key = tempArray[tempArray.length - 1];
			deleteProfilePicture(current_key);
		} else {
			notification.error({
				message: "Profile picture can not be deleted.",
				onClick: () => { },
			});
		}
	};

	return (
		<>
			<Modal
				title={t("upload_profile_pic")}
				visible={isModalVisible}
				onOk={fileUploadHandler}
				onCancel={handleCancel}
				okText="Upload"
				cancelText={t('cancel')}
				className="profile_picture_modal"
				style={{
					top: window.innerWidth < 501 ? '10px' : '',
				}}
				okButtonProps={{
					style: {
						display: picture.data !== "" ? "inline" : "none",
					},
				}}
			>
				{!imagePreview && !videoActive ? (
					<div className="current_profile_pic_sec">
						<p style={{ fontSize: "17px" }}>
							<b>{t("current_profile")}:</b>
						</p>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<img
								className="profile_picture"
								src={
									profilePic
										? `${profilePic}`
										: "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
								}
								alt="user image"
								height="120px"
								width="120px"
							/>
							{profilePic ? (
								<div
									className="profile_image_delete"
									style={{ marginTop: "20px" }}
									onClick={DeleteProfilePicture}
								>
									<FontAwesomeIcon
										icon={faTrashCan}
									/>{" "}
									{t("delete_profile_pic")}
								</div>
							) : null}
						</div>
					</div>
				) : null}
				{imagePreview ? (
					<div style={{ display: "grid", placeItems: "center" }}>
						<img
							src={imagePreview}
							alt="Error"
							className="profilePicturePreview"
							height="100%"
							width="90%"
						/>
						<div
							className="takePhotoButton"
							onClick={removePreview}
						>
							<RollbackOutlined />
						</div>
					</div>
				) : !videoActive ? (
					<>
						<div
							className="profilePictureModalText"
							onClick={() => fileInput.click()}
						>
							<input
								style={{ display: "none" }}
								type="file"
								name="profilePicture"
								id="profilePicture"
								onChange={fileSelectHandler}
								ref={(input) => (fileInput = input)}
								accept="image/*"
							/>
							<span className="highlight">
								{windowDimensions?.width > 1000
									? "Click"
									: "Tap"}
							</span>{" "}
							Here to Upload Picture
						</div>
						<div className="picture_size_message">{t("please_upload_1mb")}</div>
						{/* <div className="selfiePicContainer">
							<Button
								type="primary"
								onClick={enableSelfieVideo}
							>
								Take A Picture <CameraOutlined />
							</Button>
						</div> */}
					</>
				) : (
					// commented this out cause this works in local but does not work after deployment.
					// <div className="photoTakerVideoCont">
					// 	{videoActive && (
					// 		<Webcam
					// 			className="photoTakerVideo"
					// 			ref={videoRef}
					// 			mirrored={true}
					// 			screenshotFormat="image/jpeg"
					// 			videoConstraints={videoConstraints}
					// 		></Webcam>
					// 	)}
					// 	<div
					// 		className="takePhotoButton"
					// 		onClick={(e) => takePicture(e)}
					// 	>
					// 		<CameraOutlined />
					// 	</div>
					// </div>
					<></>
				)}
			</Modal>
		</>
	);
}
