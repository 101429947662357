import { SIGNING_IN } from "../../utils/constants";
import { ActionTypes } from "./constants";

const initialState = {
  youtubeLink: SIGNING_IN,
  loginGuide: false,
  loginAccessGuide: true,
  tourPopup: true,
  registerGuide: true,
  verifyRegisterGuide: true,
  mpinGuide: true,
  categoryGuide: true,
  requirementGuide: true,
  requirementApplyGuide: true,
  requirementRecruitGuide: true,
  profileGuide: true,
  editProfileGuide: true,
  matchGuide:true,
  editMatchGuide:true,
  dashBoardGuide:true,
  dashBoardCompletedGuide:true,
  termsGuide:true,
  userRegistrationGuide:true,
  helpGuide:true
};

const tutorialLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TUTORIAL_LINK:
      return { ...state, youtubeLink: action.payload };
    case ActionTypes.SET_LOGIN_GUIDE:
      return { ...state, loginGuide: action.payload };
    case ActionTypes.SET_LOGINACCESS_GUIDE:
      return { ...state, loginAccessGuide: action.payload };
    case ActionTypes.SET_TOUR_POPUP:
      return { ...state, tourPopup: action.payload };
    case ActionTypes.SET_REGISTER_GUIDE:
      return { ...state, registerGuide: action.payload };
    case ActionTypes.SET_VERIFY_REGISTER_GUIDE:
      return { ...state, verifyRegisterGuide: action.payload };
    case ActionTypes.SET_MPIN_GUIDE:
      return { ...state, mpinGuide: action.payload };
    case ActionTypes.SET_CATEGORY_GUIDE:
      return { ...state, categoryGuide: action.payload };
    case ActionTypes.SET_REQUIREMENT_GUIDE:
      return { ...state, requirementGuide: action.payload };
    case ActionTypes.SET_REQUIREMENT_APPLY_GUIDE:
      return { ...state, requirementApplyGuide: action.payload };
    case ActionTypes.SET_REQUIREMENT_RECRUIT_GUIDE:
      return { ...state, requirementRecruitGuide: action.payload };
    case ActionTypes.SET_PROFILE_GUIDE:
      return { ...state, profileGuide: action.payload };
    case ActionTypes.SET_EDITPROFILE_GUIDE:
      return { ...state, editProfileGuide: action.payload };
    case ActionTypes.SET_MATCH_GUIDE:
      return { ...state, matchGuide: action.payload };
    case ActionTypes.SET_EDITMATCH_GUIDE:
      return { ...state, editMatchGuide: action.payload };
    case ActionTypes.SET_DASHBOARD_GUIDE:
      return { ...state, dashBoardGuide: action.payload };
    case ActionTypes.SET_DASHBOARD_COMPLETED_GUIDE:
      return { ...state, dashBoardCompletedGuide: action.payload };
      case ActionTypes.SET_TERMS_GUIDE:
      return { ...state, termsGuide: action.payload };
      case ActionTypes.SET_USER_REGISTRATION_GUIDE:
      return { ...state, userRegistrationGuide: action.payload };
      case ActionTypes.SET_HELP_GUIDE:
      return { ...state, helpGuide: action.payload };
    default:
      return state;
  }
};

export default tutorialLinkReducer;
