import {
    getUpdatedCount,setUpdatedCount
} from "../../karmayoga-api/api/dailycountupdate";
import { ActionTypes } from "./constants";
import { notification } from "antd";


/**
 * action for getting updated count
 * @returns
 */
 export const getUpdatedCountAction = () => {
    return async (dispatch) => {
      try {
        const response = await getUpdatedCount();
        //console.log(response.data.countdata);
        dispatch({
          type: ActionTypes.GET_UPDATED_COUNT,
          payload: response.data.countdata,
        });
      } catch (err) {
        notification.error({
          message: "Fetch updated count data failed",
          description:
            err?.response?.data?.message ||
            "Please check your network connection",
          onClick: () => {},
        });
      }
    };
  };

/**
 * action for setting updated count
 * @returns
 */

  export const setUpdatedCountAction = () => {
    return async (dispatch) => {
      try {
        const response = await setUpdatedCount();
        // console.log("ll",response.data.data);
        dispatch({
          type: ActionTypes.SET_UPDATED_COUNT,
          payload: response.data.data,
        });
      } catch (err) {
        notification.error({
          message: "Fetch updated count data failed",
          description:
            err?.response?.data?.message ||
            "Please check your network connection",
          onClick: () => {},
        });
      }
    };
  };