import axios from "axios";
import constants from "../constants";

axios.defaults.baseURL = constants.API_URL;


/**
 * Get all the Rating Questions from the backend.
 * @returns
 */ 

export const getRatingQuestion = async (id) => {
    try {
      const response = await axios.get(`/api/getquestion?id=${id}&ln=${localStorage.getItem('language')}`);
      // console.log('RESSS-->>',response.data);
      return response;
    } catch (error) {
      throw error;
    }
  };


/**
 * Post all the rating data from frontend to backend
 * @returns
 */

export const postRatingData = async (payload) => {
  try {
    const response = await axios.post("/api/addrating",payload);
    //  console.log('postRating:::::::',response.data);
    return response;
  } catch (error) {
    throw error;
  }
};