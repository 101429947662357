export const LANGUAGES = [
    {
        code:"en",
        language: "English" 
    },
    {
        code:"hi",
        language: "हिन्दी" 
     },
    {
        code:"bn",
        language: "বাংলা" 
    },
    // {
    //     code:"ta",
    //     language: "தமிழ்" 
    // },
    // {
    //     code:"or",
    //     language: "ଓଡିଆ" 
    // },
    // {
    //     code:"te",
    //     language: "తెలుగు" 
    // },
    // {
    //     code:"kn",
    //     language: "ಕನ್ನಡ" 
    // },
    // {
    //     code:"as",
    //     language: "অসমীয়া" 
    // },
    // {
    //     code:"mr",
    //     language: "मराठी" 
    // }
]