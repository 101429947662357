export const mpinTour = [
  {
    selector: ".ant-form-item-control",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Please choose a personal 4 digit number to be used subsequently as
            and access code for login - you can avoid waiting for the
            verification code.{" "}
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            कृपया बाद में उपयोग करने के लिए एक व्यक्तिगत 4 अंकों की संख्या चुनें
            और लॉगिन के लिए एक्सेस कोड - आप सत्यापन कोड की प्रतीक्षा करने से बच
            सकते हैं
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            অনুগ্রহ করে পরবর্তীতে ব্যবহার করার জন্য একটি ব্যক্তিগত 4 সংখ্যার
            নম্বর বেছে নিন এবং লগইন করার জন্য কোড ব্যবহার করুন - আপনি যাচাইকরণ
            কোডের জন্য অপেক্ষা করা এড়াতে পারেন।{" "}
          </h4>
        ) : (
          <h4>
            தயவுசெய்து உள்நுழைவதற்கான தனிப்பட்ட 4 இலக்க எண்ணைத் தேர்ந்தெடுத்து,
            உள்நுழைவதற்கான குறியீட்டை அணுகவும் - சரிபார்ப்புக் குறியீட்டிற்காகக்
            காத்திருப்பதைத் தவிர்க்கலாம்.{" "}
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-btn-primary",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Click on this button to set the access code you have chosen to
            access the app on subsequent return
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            उस एक्सेस कोड को सेट करने के लिए इस बटन पर क्लिक करें जिसे आपने बाद
            में वापसी पर ऐप को एक्सेस करने के लिए चुना है
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            পরবর্তী রিটার্নে অ্যাপ অ্যাক্সেস করার জন্য আপনি যে অ্যাক্সেস কোডটি
            বেছে নিয়েছেন সেটি সেট করতে এই বোতামে ক্লিক করুন
          </h4>
        ) : (
          <h4>
            அடுத்து திரும்பும் போது ஆப்ஸை அணுக நீங்கள் தேர்ந்தெடுத்த அணுகல்
            குறியீட்டை அமைக்க இந்தப் பொத்தானைக் கிளிக் செய்யவும்
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".mpin-skip-tour",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            You can skip this step of setting up an access code. You can set an
            access code later in the profile section.{" "}
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            आप एक्सेस कोड सेट करने के इस चरण को छोड़ सकते हैं। आप बाद में
            प्रोफ़ाइल अनुभाग में एक एक्सेस कोड सेट कर सकते हैं
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            আপনি একটি অ্যাক্সেস কোড সেট আপ করার এই ধাপটি এড়িয়ে যেতে পারেন৷
            আপনি প্রোফাইল বিভাগে পরে একটি অ্যাক্সেস কোড সেট করতে পারেন।{" "}
          </h4>
        ) : (
          <h4>
            அணுகல் குறியீட்டை அமைப்பதற்கான இந்தப் படிநிலையைத் தவிர்க்கலாம்.
            சுயவிவரப் பிரிவில் பின்னர் அணுகல் குறியீட்டை அமைக்கலாம்.{" "}
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
];
