import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";
import "./DailyUpdate.css";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectUpdatedCount } from "../../redux-core/dailycountupdate/selectors";
import { getUpdatedCountAction } from "../../redux-core/dailycountupdate/actions";
import { useTranslation } from "react-i18next";

const updatedCountSelector = createSelector(
  makeSelectUpdatedCount,
  (countData) => ({
    countData,
  })
);

const actionDispatch = (dispatch) => ({
  getUpdatedCount: () => dispatch(getUpdatedCountAction()),
});

const DailyUpdate = ({ classnameprops }) => {
  const { countData } = useSelector(updatedCountSelector);
  const { getUpdatedCount } = actionDispatch(useDispatch());
  const { t, i18n } = useTranslation();

  //useEffect to get the updated count data
  useEffect(() => {
    getUpdatedCount();
  }, []);

  return (
    <div
      className={
        classnameprops == "running-data-category"
          ? "running-data-category"
          : "running-data-login"
      }
    >
      <Marquee gradient={false} gradientColor={[255, 255, 255]}>
        <span>
          <span className="running-data-container">
            {t("users")}: <b>{countData?.[0]?.number_of_users}</b>
          </span>
          <span className="running-data-container">
            {t("recruiters")}: <b>{countData?.[0]?.number_of_recruiter}</b>
          </span>{" "}
          <span className="running-data-container">
            {t("applicants")}: <b>{countData?.[0]?.number_of_applier}</b>
          </span>{" "}
          <span className="running-data-container">
            {t("transactions")}: <b>{countData?.[0]?.number_of_econtract}</b>
          </span>
        </span>
      </Marquee>
    </div>
  );
};

export default DailyUpdate;
