import React from "react";

/**
 * Terms And Condition Page in Bengali language
 * @param {*} isHeader
 * @returns
 */

const CommonTermsBengali = ({ isHeader }) => {
  return (
    <div className="CommonTermsContainer">
      <div id="page_1">
        {isHeader && (
          <div id="p1dimg1">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAocAAAAZCAIAAADMhROvAAAAgklEQVR4nO3VMQEAIAzAMMC/p1njwQM9EgX9umdmAQAB53cAAPC4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVFzU5QJv52dqgAAAAABJRU5ErkJggg=="
              id="p1img1"
            />
          </div>
        )}

        {isHeader && (
          <div id="id1_1">
            <p className="p0 ft0">ব্যবহারের শর্তাবলী</p>
          </div>
        )}

        <div id="id1_2">
          <p className="p0 ft3">
            <span className="ft1">1.</span>
            <span className="ft2">ব্যবহারকারীদের সাথে সম্পর্ক</span>
          </p>
          <p className="p1 ft6">
            <span className="ft1">1.1।</span>
            <span className="ft4">এই ব্যবহারের শর্তাবলী (&quot;</span>
            <span className="ft5">শর্তাবলী</span> &quot;) ভারতের মধ্যে থেকে, এককভাবে
            এবং/অথবা যৌথভাবে One Source Karma Yoga Pvt-এর দ্বারা উপলব্ধ
            <span className="ft5">পরিষেবা</span> হিসাবে উল্লেখ করা অ্যাপ্লিকেশন,
            প্ল্যাটফর্ম, ওয়েবসাইট, বিষয়বস্তু এবং পরিষেবাগুলির আপনার অ্যাক্সেস
            এবং ব্যবহার নিয়ন্ত্রণ করে৷ Ltd., ভারতে প্রতিষ্ঠিত একটি প্রাইভেট
            লিমিটেড কোম্পানি, যার নিবন্ধিত অফিস রয়েছে #859, 17th G Main Road,
            5th Block Rajajinagar, Bangalore 560010 (“
            <span className="ft5">কর্ম যোগ</span> ”)।
          </p>
          <p className="p2 ft6">
            <span className="ft7">1.2।</span> কর্ম যোগ দ্বারা উপলব্ধ করা
            <span className="ft5">পরিষেবাগুলি</span> , যা
            <span className="ft8">পরে</span> &quot;
            <span className="ft5">পরিষেবা</span> &quot; নামে পরিচিত, ব্যবহারকারীদের
            নিয়োগ বা ট্রেড পরিষেবার স্থাপনের জন্য তাদের প্রয়োজনীয়তাগুলি
            তালিকাভুক্ত করার জন্য উপলব্ধ করা অ্যাপ্লিকেশন/প্ল্যাটফর্ম নিয়ে
            গঠিত৷
            <span className="ft5">পরিষেবাগুলি</span> নিয়োগ এবং স্থাপনার মধ্যে একটি
            উপযুক্ত মিল খুঁজে বের করার চেষ্টা করবে৷ যেকোন ব্যক্তি বা কোম্পানী যে
            <span className="ft5">পরিষেবাগুলি</span> ব্যবহার করে তাকে পরবর্তীতে
            &quot;
            <span className="ft5">ব্যবহারকারী</span> &quot; হিসাবে উল্লেখ করা হবে
          </p>
          <p className="p3 ft6">
            <span className="ft1">1.3।</span> আপনার
            <span className="ft5">পরিষেবাগুলির</span>
            <span className="ft8">অ্যাক্সেস এবং ব্যবহার</span> এই শর্তাবলী দ্বারা
            আবদ্ধ হওয়ার জন্য আপনার চুক্তি গঠন করে, যা আপনার এবং কর্ম যোগের
            মধ্যে একটি চুক্তিভিত্তিক সম্পর্ক স্থাপন করে। আপনি যদি এই শর্তাবলীতে
            সম্মত না হন তবে আপনি
            <span className="ft5">পরিষেবাগুলি</span> অ্যাক্সেস বা ব্যবহার করতে
            পারবেন না৷ এই শর্তাবলী স্পষ্টভাবে কর্ম যোগ এবং আপনার মধ্যে পূর্বে
            লিখিত চুক্তি বাতিল করে।
          </p>
          <p className="p3 ft7">
            <span className="ft1">1.4।</span> পরিপূরক শর্তাবলী নির্দিষ্ট কিছু
            <span className="ft3">পরিষেবার</span>
            <span className="ft9">ক্ষেত্রে প্রযোজ্য হতে পারে</span> , যেমন একটি
            নির্দিষ্ট ইভেন্ট, কার্যকলাপ বা প্রচারের নীতি, এবং এই ধরনের সম্পূরক
            শর্তাবলী প্রযোজ্য
            <span className="ft3">পরিষেবাগুলির</span> সাথে আপনার কাছে প্রকাশ করা
            হবে৷ পরিপূরক শর্তাবলী প্রযোজ্য
            <span className="ft3">পরিষেবার</span> উদ্দেশ্যে শর্তাবলীর অতিরিক্ত, এবং
            একটি অংশ হিসাবে বিবেচিত হবে৷ পরিপূরক শর্তাবলী প্রযোজ্য
            <span className="ft3">পরিষেবাগুলির</span> ক্ষেত্রে বিরোধের ক্ষেত্রে এই
            শর্তগুলির উপর প্রাধান্য পাবে৷
          </p>
          <p className="p4 ft7">
            <span className="ft1">1.5।</span> কর্ম যোগা আপনাকে
            <span className="ft3">পরিষেবাগুলি</span>
            <span className="ft9">
              অ্যাক্সেস করা বা ব্যবহার করা থেকে সীমাবদ্ধ করতে পারে
            </span>{" "}
            , বা তাদের যেকোনো অংশ, অবিলম্বে, বিজ্ঞপ্তি ছাড়াই, এমন পরিস্থিতিতে
            যেখানে কর্ম যোগ যুক্তিসঙ্গতভাবে সন্দেহ করে যে:
          </p>
          <p className="p5 ft7">
            <span className="ft7">(ক)</span>
            <span className="ft10">
              আপনি এই শর্তাবলী লঙ্ঘন করেছেন বা করার সম্ভাবনা আছে; এবং/অথবা
            </span>
          </p>
          <p className="p6 ft7">
            <span className="ft7">(b)</span> আপনি
            <span className="ft3">পরিষেবাগুলি</span>
            <span className="ft11">
              অ্যাক্সেস এবং ব্যবহার করার জন্য প্রযোজ্য আইন বা কর্ম যোগের মান এবং
              নীতির অধীনে যোগ্য নন বা সম্ভবত নাও হতে পারেন
            </span>{" "}
            ৷
          </p>
          <p className="p7 ft7">
            <span className="ft7">(c)</span>
            <span className="ft12">
              আপনি একজন প্রকৃত ব্যবহারকারী নন - অর্থাৎ হয় আপনি
            </span>{" "}
            কর্ম যোগ দ্বারা প্রদত্ত
            <span className="ft3">পরিষেবাগুলি</span> লেনদেন করার জন্য আপনার সংস্থার
            দ্বারা অনুমোদিত নন বা আপনি এমন কোনও অবস্থানে নন যা আপনাকে
            <span className="ft3">পরিষেবাগুলি</span> ব্যবহার করার অনুমোদন/অনুমতি
            দেয়৷
          </p>
          <p className="p8 ft7">
            <span className="ft7">(d)</span>
            <span className="ft11">আপনার দেওয়া তথ্য মিথ্যা বা ভুল</span>
          </p>
          <p className="p9 ft6">
            <span className="ft1">1.6।</span>
            <span className="ft8">
              কর্ম যোগ এই শর্তাবলী বা আপনার সম্মানের সাথে যেকোন
            </span>
            <span className="ft5">পরিষেবা</span> বাতিল করতে পারে, অথবা সাধারণত
            <span className="ft5">পরিষেবাগুলি</span> বা এর কোনও অংশে অ্যাক্সেস
            দেওয়া বন্ধ বা অস্বীকার করতে পারে, যেখানে কর্ম যোগ সন্দেহ করে বা
            বিশ্বাস করে যে:
          </p>
          <p className="p10 ft7">
            <span className="ft7">(a)</span>
            <span className="ft10">
              আপনি এই শর্তাবলী লঙ্ঘন করেছেন, বা করার সম্ভাবনা আছে; বা
            </span>
          </p>
          <p className="p6 ft7">
            <span className="ft7">(b)</span>
            <span className="ft11">
              আপনি প্রযোজ্য আইন বা কর্ম যোগের নীতির অধীনে
            </span>
            <span className="ft3">পরিষেবাগুলি</span> অ্যাক্সেস এবং ব্যবহার করার জন্য
            যোগ্য নন বা সম্ভবত নন; বা
          </p>
          <p className="p11 ft7">
            <span className="ft1">1.7।</span>
            <span className="ft13">কর্ম যোগ এই শর্তাবলী বা যেকোনো</span>
            <span className="ft3">পরিষেবা</span> বাতিল করতে পারে আপনার কাছে 5 (পাঁচ)
            দিনের পূর্বে লিখিত নোটিশ দিয়ে, যেকোনো বৈধ ব্যবসা, আইনি বা
            নিয়ন্ত্রক কারণে, বা কারণ ছাড়াই।
          </p>
          <p className="p12 ft6">
            <span className="ft1">1.8।</span> কর্ম যোগ সময়ে সময়ে
            <span className="ft5">পরিষেবাগুলির</span>
            <span className="ft8">
              সাথে সম্পর্কিত যে কোনও নীতি বা সম্পূরক শর্তাদি সংশোধন করার অধিকারী
            </span>{" "}
            , এবং কোনও বস্তুগত পরিবর্তনের ক্ষেত্রে পরিষেবা অ্যাপ্লিকেশনে আপনাকে
            কমপক্ষে 5 (পাঁচ) দিনের পূর্বে লিখিত নোটিশ প্রদান করবে। কোনো নীতি বা
            সম্পূরক শর্তাবলী। কর্ম যোগে এই ধরনের সংশোধিত নীতি বা সম্পূরক
            শর্তাবলী প্রযোজ্য পরিষেবাতে পোস্ট করার পরে সংশোধনগুলি কার্যকর হবে৷
            এই ধরনের পোস্ট করার পরে, বা নোটিশের মেয়াদ শেষ হওয়ার পরে (যেটি পরেই
            হোক না কেন) আপনার অবিরত অ্যাক্সেস বা
            <span className="ft5">পরিষেবাগুলির</span> ব্যবহার আপনার সম্মতি গঠন করে,
            যা সংশোধিত শর্তাবলী দ্বারা আবদ্ধ হবে।
          </p>
          <p className="p13 ft3">
            <span className="ft14">2.</span>
            <span className="ft15">
              ব্যবহারের শর্তাবলী এবং গোপনীয়তা নীতির সংশোধন
            </span>
          </p>
          <p className="p9 ft7">
            <span className="ft1">2.1।</span>
            <span className="ft9">
              কর্ম যোগ অধিকার সংরক্ষণ করে, তার নিজস্ব বিবেচনার ভিত্তিতে,
              শর্তাবলী পরিবর্তন, সংশোধন বা অন্যথায় সংশোধন করার অধিকার সংরক্ষণ
              করে, এবং আইনগত এবং নিয়ন্ত্রক মেনে চলার জন্য এখানে রেফারেন্স
              দ্বারা অন্তর্ভুক্ত অন্য যেকোন নথি।
            </span>
          </p>
        </div>
      </div>
      <div id="page_2">
        <p className="p14 ft6">
          {" "}
          ফ্রেমওয়ার্ক এবং অন্যান্য বৈধ ব্যবসায়িক উদ্দেশ্যে, যে কোনো সময়ে, এবং
          Karma Yoga সংশোধিত শর্তাবলী ওয়েবসাইট
          <a href="https://www.onesourcekarmayoga.com/" target="_blank">
            “
          </a>
          <a href="https://www.onesourcekarmayoga.com/" target="_blank">
            <span className="ft16">https://www.onesourcekarmayoga.com</span>
          </a>{" "}
          ” (“ওয়েবসাইট”) এবং মোবাইল ওয়েব অ্যাপ্লিকেশন
          <a href="https://www.karmayoga.app/" target="_blank">
            “
          </a>
          <a href="https://www.karmayoga.app/" target="_blank">
            <span className="ft16">karmayoga.app</span>
          </a>
          <a href="https://www.karmayoga.app/" target="_blank">
            ”
          </a>{" "}
          ( সম্মিলিতভাবে ওয়েবসাইটের সাথে, &quot;কেওয়াই প্ল্যাটফর্ম&quot;)।
          পরিবর্তনের জন্য শর্তাবলী পর্যালোচনা করা আপনার দায়িত্ব এবং আপনাকে ঘন
          ঘন শর্তাবলী পরীক্ষা করার জন্য উৎসাহিত করা হচ্ছে
        </p>
        <p className="p2 ft6">
          <span className="ft1">2.2।</span>
          <span className="ft17">
            শর্তাবলীর সংশোধনী কার্যকর হওয়ার তারিখের পরে আপনার ওয়েবসাইট ব্যবহার
            করা এই ধরনের সংশোধিত শর্তাবলীতে আপনার সম্মতি এবং সম্মতি নির্দেশ করে।
            আপনি যদি এই বা ভবিষ্যতের কোন শর্তাবলী মেনে চলতে সম্মত না হন, তাহলে
            দয়া করে
          </span>
          <span className="ft5">পরিষেবাগুলি</span> ব্যবহার করবেন না৷
        </p>
        <p className="p15 ft3">
          <span className="ft14">3.</span>
          <span className="ft15">পরিষেবা</span>
        </p>
        <p className="p9 ft6">
          <span className="ft1">3.1।</span>
          <span className="ft5">পরিষেবাগুলি</span> শুধুমাত্র বাণিজ্যিক ব্যবহারের
          জন্য উপলব্ধ করা হয়েছে
          <span className="ft8">৷</span> আপনি স্বীকার করেন যে কর্ম যোগ এমন কোনো
          সংস্থা বা কর্মীদের দ্বারা পরিচালিত কোনো কার্যকলাপের জন্য দায়ী নয় এবং
          নয় যার সাথে আপনি কেওয়াই প্ল্যাটফর্মের মাধ্যমে বা এর মাধ্যমে সংযুক্ত,
          এবং এই ধরনের সংস্থা এবং কর্মীরা স্বাধীন তৃতীয় পক্ষ যারা কর্ম যোগ
          দ্বারা নিযুক্ত নয় বা এর যেকোন সহযোগী। এই ধরনের কর্মী এবং সংস্থাগুলি
          আপনাকে প্রদান করা পরিষেবাগুলির জন্য সম্পূর্ণরূপে দায়ী৷
        </p>
        <p className="p16 ft7">
          <span className="ft1">3.2।</span>
          <span className="ft18">
            এই শর্তাবলীর সাথে আপনার সম্মতি সাপেক্ষে, কর্ম যোগ আপনাকে একটি সীমিত,
          </span>
          <b>অ-এক্সক্লুসিভ,</b>
          <b>অ-সাব-</b> লাইসেন্সযোগ্য, প্রত্যাহারযোগ্য,
          <b>অ-হস্তান্তরযোগ্য</b> লাইসেন্স প্রদান করে:
        </p>
        <p className="p5 ft7">
          <span className="ft7">(a)</span> শুধুমাত্র আপনার
          <span className="ft3">পরিষেবাগুলির</span>
          <span className="ft10">
            ব্যবহারের সাথে সম্পর্কিত KY প্ল্যাটফর্ম অ্যাক্সেস এবং ব্যবহার করুন
          </span>{" "}
          , এবং
        </p>
        <p className="p17 ft6">
          <span className="ft7">(b)</span> শুধুমাত্র বাণিজ্যিক ব্যবহারের জন্য
          প্রতিটি ক্ষেত্রে
          <span className="ft5">পরিষেবাগুলির</span>
          <span className="ft19">
            মাধ্যমে উপলব্ধ করা যেতে পারে এমন কোনও সামগ্রী, তথ্য এবং সম্পর্কিত
            সামগ্রী অ্যাক্সেস এবং ব্যবহার করুন
          </span>{" "}
          ৷ এখানে স্পষ্টভাবে প্রদত্ত নয় এমন কোনো অধিকার কর্ম যোগ দ্বারা
          সংরক্ষিত।
        </p>
        <p className="p15 ft7">
          <span className="ft1">3.3।</span>
          <span className="ft18">আপনি হতে পারে না:</span>
        </p>
        <p className="p5 ft7">
          <span className="ft7">(ক)</span>
          <span className="ft3">পরিষেবার</span>
          <span className="ft10">
            যে কোনও অংশ থেকে কোনও কপিরাইট, ট্রেডমার্ক বা অন্যান্য মালিকানা
            বিজ্ঞপ্তিগুলি সরিয়ে ফেলুন
          </span>{" "}
          ;
        </p>
        <p className="p17 ft6">
          <span className="ft7">(b)</span> কর্ম যোগ দ্বারা স্পষ্টভাবে অনুমোদিত
          ব্যতীত
          <span className="ft5">পরিষেবাগুলির</span>
          <span className="ft19">
            পুনরুত্পাদন, সংশোধন, তৈরি করা, বন্টন, লাইসেন্স, ইজারা, বিক্রয়,
            পুনঃবিক্রয়, স্থানান্তর, সর্বজনীনভাবে প্রদর্শন, সর্বজনীনভাবে
            সঞ্চালন, প্রেরণ, প্রবাহ, সম্প্রচার বা অন্যথায় পরিষেবার শোষণ
          </span>{" "}
          ;
        </p>
        <p className="p18 ft7">
          <span className="ft7">(c)</span> প্রযোজ্য আইন দ্বারা অনুমোদিত ব্যতীত
          <span className="ft3">পরিষেবাগুলিকে</span>
          <span className="ft12">
            ডিকম্পাইল, রিভার্স ইঞ্জিনিয়ার বা বিচ্ছিন্ন
          </span>{" "}
          করা;
        </p>
        <p className="p5 ft7">
          <span className="ft7">(d)</span>
          <span className="ft3">পরিষেবাগুলির</span>
          <span className="ft11">কোনও অংশের সাথে লিঙ্ক, মিরর বা ফ্রেম</span> ;
        </p>
        <p className="p17 ft6">
          <span className="ft7">(ঙ)</span>
          <span className="ft5">
            পরিষেবাগুলির কোনও অংশ স্ক্র্যাপিং, সূচীকরণ, জরিপ বা অন্যথায় ডেটা
            মাইনিং করার উদ্দেশ্যে বা পরিষেবাগুলির
          </span>
          <span className="ft5">কোনও</span> দিক পরিচালনা এবং/অথবা কার্যকারিতাকে অযথা
          বোঝা বা বাধা
          <span className="ft20">
            দেওয়ার উদ্দেশ্যে কোনও প্রোগ্রাম বা স্ক্রিপ্টের কারণ বা লঞ্চ করা
          </span>{" "}
          ; বা
        </p>
        <p className="p19 ft7">
          <span className="ft7">(f)</span>
          <span className="ft3">পরিষেবা</span> বা এর সম্পর্কিত সিস্টেম বা
          নেটওয়ার্কগুলির
          <span className="ft21">
            কোনও দিক থেকে অননুমোদিত অ্যাক্সেস লাভ বা ক্ষতিগ্রস্থ করার চেষ্টা করা
          </span>{" "}
          ।
        </p>
        <p className="p11 ft7">
          <span className="ft1">3.4।</span> আপনি স্বীকার করেন যে
          <span className="ft3">পরিষেবাগুলির</span>
          <span className="ft18">অংশগুলি</span> স্বাধীন তৃতীয় পক্ষের সাথে সংযোগে
          উপলব্ধ করা যেতে পারে।
        </p>
        <p className="p12 ft7">
          <span className="ft1">3.5।</span>
          <span className="ft18">
            কর্ম যোগ তৃতীয় পক্ষের পরিষেবা এবং বিষয়বস্তুকে সমর্থন করে না এবং
            কোনও ক্ষেত্রেই কর্ম যোগ এই জাতীয় তৃতীয় পক্ষের কোনও পণ্য বা
            পরিষেবার জন্য দায়ী বা দায়বদ্ধ হবে না৷ উপরন্তু, Apple Inc., Google,
            Inc., Microsoft Corporation বা BlackBerry Limited এবং/অথবা তাদের
            প্রযোজ্য আন্তর্জাতিক সহায়ক এবং সহযোগীরা
          </span>{" "}
          এই চুক্তির
          <b>তৃতীয় পক্ষের</b> সুবিধাভোগী হবে যদি আপনি Apple iOS, Android,
          Microsoft Windows-এর জন্য তৈরি করা অ্যাপ্লিকেশনগুলি ব্যবহার করে
          <span className="ft3">পরিষেবাগুলি</span> অ্যাক্সেস করেন , অথবা যথাক্রমে
          <b>ব্ল্যাকবেরি-চালিত</b> মোবাইল ডিভাইস। এই তৃতীয় পক্ষের সুবিধাভোগীরা
          এই চুক্তির পক্ষ নন এবং
          <span className="ft3">পরিষেবাগুলির</span> বিধান বা সহায়তার জন্য কোনও
          উপায়ে দায়ী নয়৷ এই ডিভাইসগুলি ব্যবহার করে
          <span className="ft3">পরিষেবাগুলিতে</span> আপনার অ্যাক্সেস প্রযোজ্য তৃতীয়
          পক্ষের সুবিধাভোগীর পরিষেবার শর্তাবলীতে বর্ণিত শর্তাবলীর সাপেক্ষে৷
        </p>
        <p className="p20 ft7">
          <span className="ft1">3.6।</span>
          <span className="ft3">পরিষেবা</span> এবং
          <span className="ft18">এর</span> মধ্যে থাকা সমস্ত অধিকার কর্ম যোগের
          সম্পত্তি বা কর্ম যোগের লাইসেন্সদাতাদের সম্পত্তি। এই শর্তাবলী বা আপনার
          <span className="ft3">পরিষেবার</span> ব্যবহার আপনাকে কোনো অধিকার দেয় না
          বা দেয় না:
        </p>
        <p className="p5 ft7">
          <span className="ft7">(ক)</span> উপরে প্রদত্ত সীমিত লাইসেন্স ব্যতীত
          <span className="ft3">পরিষেবাগুলির</span>
          <span className="ft10">মধ্যে বা সম্পর্কিত</span> ; বা
        </p>
      </div>
      <div id="page_3">
        <p className="p21 ft7">
          <span className="ft7">(b)</span> কর্ম যোগের কোম্পানির নাম, লোগো, পণ্য এবং
          পরিষেবার নাম, ট্রেডমার্ক বা
          <span className="ft3">পরিষেবার</span> চিহ্ন
          <span className="ft11">যে কোনও উপায়ে ব্যবহার বা উল্লেখ করা</span> ।
        </p>
        <p className="p22 ft3">
          <span className="ft1">4.</span>
          <span className="ft15">অ্যাকাউন্ট</span>
        </p>
        <p className="p1 ft7">
          <span className="ft1">4.1।</span>
          <span className="ft3">পরিষেবাগুলি</span>
          <span className="ft9">ব্যবহার করার জন্য</span> , আপনাকে অবশ্যই একটি
          সক্রিয়
          <span className="ft3">পরিষেবা</span> অ্যাকাউন্টের জন্য নিবন্ধন করতে হবে
          এবং বজায় রাখতে হবে (“
          <span className="ft3">অ্যাকাউন্ট</span> ”)৷ আপনাকে অবশ্যই ভারতে
          অন্তর্ভুক্ত একটি কোম্পানি বা সীমিত দায়বদ্ধতা অংশীদারিত্ব হতে হবে,
          একটি অংশীদারি সংস্থা, বা একমাত্র মালিকানা, বা এমন অন্য ব্যক্তি যার
          আইনি ব্যক্তিত্ব রয়েছে, ভারতীয় আইনের অধীনে মামলা করতে বা মামলা করতে
          সক্ষম, যদি না কোনো নির্দিষ্ট পরিষেবা অন্যথায় অনুমতি দেয়, বা কর্ম যোগ
          অন্যথায় অনুমতি দেয়।
        </p>
        <p className="p12 ft7">
          <span className="ft1">4.2।</span>
          <span className="ft18">
            আপনি এতদ্বারা প্রতিনিধিত্ব করেন এবং ওয়ারেন্টি দেন যে আপনার কাছে এই
            শর্তাদি গ্রহণ করার এবং আবদ্ধ হওয়ার ক্ষমতা এবং আইনী ক্ষমতা রয়েছে
            এবং এই শর্তাদি মেনে চলা এবং মেনে চলার জন্য কোনোভাবেই সীমাবদ্ধ নয়।
          </span>
        </p>
        <p className="p23 ft6">
          <span className="ft1">4.3।</span>
          <span className="ft17">
            অ্যাকাউন্ট রেজিস্ট্রেশনের জন্য আপনাকে কর্ম যোগে জমা দিতে হবে,
            নির্দিষ্ট কিছু ব্যক্তিগত তথ্য, যেমন আপনার কোম্পানির নাম, অনুমোদিত
            ব্যক্তি নাম, ঠিকানা, মোবাইল ফোন নম্বর এবং বয়স, সিআইএন, জিএসটি,
            প্যান, আধার, প্রযোজ্য হিসাবে, পাশাপাশি অন্তত একটি বৈধ অর্থপ্রদানের
            পদ্ধতি (হয় একটি ক্রেডিট কার্ড বা স্বীকৃত অর্থপ্রদান অংশীদার)। আপনি
            আপনার অ্যাকাউন্টে সঠিক, সম্পূর্ণ এবং
          </span>
          <b>আপ-টু-</b> ডেট তথ্য বজায় রাখতে সম্মত হন। ফাইলে একটি অবৈধ বা
          মেয়াদোত্তীর্ণ অর্থপ্রদানের পদ্ধতি সহ সঠিক, সম্পূর্ণ, এবং
          <b>আপ-টু-ডেট</b> অ্যাকাউন্ট তথ্য বজায় রাখতে আপনার ব্যর্থতার ফলে
          <span className="ft5">পরিষেবাগুলি</span> অ্যাক্সেস বা ব্যবহার করতে আপনার
          অক্ষমতা হতে পারে। আপনার অ্যাকাউন্টের অধীনে ঘটে যাওয়া সমস্ত
          কার্যকলাপের জন্য আপনি দায়ী, এবং আপনি সর্বদা আপনার অ্যাকাউন্টের
          ব্যবহারকারীর নাম এবং পাসওয়ার্ডের নিরাপত্তা এবং গোপনীয়তা বজায় রাখতে
          সম্মত হন। লিখিতভাবে কর্ম যোগ দ্বারা অনুমোদিত না হলে, আপনি শুধুমাত্র
          একটি অ্যাকাউন্টের অধিকারী হতে পারেন।
        </p>
        <p className="p24 ft7">
          <span className="ft1">4.4</span>
          <span className="ft18">
            আপনি অন্য কোন ব্যক্তি বা সত্তাকে আপনার অ্যাকাউন্ট বরাদ্দ বা অন্যথায়
            স্থানান্তর করতে পারবেন না।
          </span>
          <span className="ft3">পরিষেবাগুলি</span>
          <span className="ft18">
            অ্যাক্সেস করার বা ব্যবহার করার সময় আপনি সমস্ত প্রযোজ্য আইন মেনে
            চলতে সম্মত হন
          </span>{" "}
          এবং আপনি শুধুমাত্র আইনানুগ উদ্দেশ্যে
          <span className="ft3">পরিষেবাগুলি</span> অ্যাক্সেস করতে বা ব্যবহার করতে
          পারেন৷ আপনি, আপনার
          <span className="ft3">পরিষেবাগুলির</span> ব্যবহারে, তৃতীয় পক্ষের
          ঠিকাদার/কর্মীদের বিরক্তি, বিরক্তি, অসুবিধা বা সম্পত্তির ক্ষতির কারণ
          হবেন না। কিছু কিছু ক্ষেত্রে আপনাকে
          <span className="ft3">পরিষেবাগুলি</span> অ্যাক্সেস বা ব্যবহার করার জন্য
          পরিচয়ের প্রমাণ বা পরিচয় যাচাইয়ের অন্যান্য পদ্ধতি প্রদান করতে বলা
          হতে পারে এবং আপনি সম্মত হন যে আপনি যদি পরিচয়ের প্রমাণ বা অন্য কোনো
          প্রমাণ দিতে অস্বীকার করেন তাহলে আপনাকে
          <span className="ft3">পরিষেবাগুলিতে</span> অ্যাক্সেস বা ব্যবহার থেকে
          বঞ্চিত করা হতে পারে পরিচয় যাচাইয়ের পদ্ধতি।
        </p>
        <p className="p25 ft7">
          <span className="ft1">4.5।</span>
          <span className="ft18">
            কর্ম যোগ জাতি, ধর্ম, বর্ণ, জাতীয় উত্স, অক্ষমতা, যৌন অভিমুখীতা,
            লিঙ্গ, বৈবাহিক অবস্থা, লিঙ্গ পরিচয়, বয়স বা প্রযোজ্য আইনের অধীনে
            সুরক্ষিত অন্য কোনো বৈশিষ্ট্যের ভিত্তিতে তৃতীয় পক্ষের ঠিকাদার এবং
            কর্মীদের বিরুদ্ধে বৈষম্য নিষিদ্ধ করে। এই ধরনের বৈষম্য অন্তর্ভুক্ত,
            কিন্তু সীমাবদ্ধ নয়, এই বৈশিষ্ট্যগুলির যে কোনও একটির
          </span>{" "}
          উপর ভিত্তি করে
          <span className="ft3">পরিষেবাগুলি</span> গ্রহণ করতে অস্বীকার করা৷ যদি দেখা
          যায় যে আপনি এই নিষেধাজ্ঞা লঙ্ঘন করেছেন আপনি কর্ম যোগ প্ল্যাটফর্মে
          অ্যাক্সেস হারাবেন। নির্দিষ্ট রাজ্যে প্রযোজ্য আইনের প্রয়োজন হতে পারে
          এবং/অথবা নির্দিষ্ট শ্রেণীর ব্যক্তির সুবিধার জন্য
          <span className="ft3">পরিষেবার</span> বিধানের অনুমতি দিতে পারে। এই ধরনের
          এখতিয়ারে, এই আইনগুলি এবং প্রাসঙ্গিক প্রযোজ্য শর্তাবলী মেনে প্রদত্ত
          <span className="ft3">পরিষেবাগুলি</span> অনুমোদিত৷
        </p>
        <p className="p25 ft6">
          <span className="ft1">4.6।</span>
          <span className="ft17">একটি অ্যাকাউন্ট তৈরি করে, আপনি সম্মত হন যে</span>
          <span className="ft5">পরিষেবাগুলি</span> আপনার
          <span className="ft5">পরিষেবাগুলির</span> ব্যবহারের স্বাভাবিক ব্যবসায়িক
          ক্রিয়াকলাপের অংশ হিসাবে আপনাকে পাঠ্য (SMS) এবং/অথবা WhatsApp বার্তা
          পাঠাতে পারে৷ আপনি যেকোন সময় কর্ম যোগ থেকে টেক্সট (এসএমএস) এবং/অথবা
          হোয়াটসঅ্যাপ বার্তাগুলি গ্রহণ
          <b>করা থেকে অপ্ট-আউট</b> করতে পারেন কর্ম যোগকে, লিখিতভাবে, এই ধরনের
          বার্তাগুলি গ্রহণ বন্ধ করার সিদ্ধান্ত সম্পর্কে অবহিত করে৷
        </p>
        <p className="p26 ft7">
          <span className="ft1">4.7।</span>
          <span className="ft3">পরিষেবাগুলি</span>
          <span className="ft22">
            ব্যবহার করার জন্য প্রয়োজনীয় ডেটা নেটওয়ার্ক অ্যাক্সেস পাওয়ার জন্য
            আপনি দায়ী
          </span>{" "}
          ৷ আপনার মোবাইল নেটওয়ার্কের ডেটা এবং মেসেজিং রেট এবং ফি প্রযোজ্য হতে
          পারে যদি আপনি একটি
          <b>বেতার-সক্ষম</b> ডিভাইস থেকে
          <span className="ft3">পরিষেবাগুলি</span> অ্যাক্সেস করেন বা ব্যবহার করেন
          এবং আপনি এই ধরনের হার এবং ফিগুলির জন্য দায়ী থাকবেন৷
          <span className="ft3">পরিষেবাগুলি</span> এবং কেওয়াই প্ল্যাটফর্ম অ্যাক্সেস
          এবং ব্যবহার করার জন্য প্রয়োজনীয় সামঞ্জস্যপূর্ণ হার্ডওয়্যার বা
          ডিভাইসগুলি অর্জন এবং আপডেট করার জন্য আপনি দায়ী এবং এর যেকোনো আপডেটের
          জন্য। কর্ম যোগ গ্যারান্টি দেয় না যে
          <span className="ft3">পরিষেবাগুলি</span> , বা এর কোনও অংশ, কোনও নির্দিষ্ট
          হার্ডওয়্যার বা ডিভাইসে কাজ করবে৷ এছাড়াও,
          <span className="ft3">পরিষেবাগুলি</span> ইন্টারনেট এবং ইলেকট্রনিক
          যোগাযোগের ব্যবহারে অন্তর্নিহিত ত্রুটি এবং বিলম্বের বিষয় হতে পারে।
        </p>
        <p className="p27 ft3">
          <span className="ft14">5.</span>
          <span className="ft15">ফি এবং চার্জ</span>
        </p>
        <p className="p9 ft7">
          <span className="ft1">5.1।</span>
          <span className="ft9">আপনি বোঝেন যে পরিষেবাগুলির ব্যবহারের</span>
          <span className="ft3">ফলে</span> আপনার পরিষেবাগুলির জন্য বা আপনার
          ব্যবহারের মাধ্যমে এই জাতীয় তৃতীয় পক্ষের
          <span className="ft3">পরিষেবাগুলি</span> ব্যবহার করার জন্য আপনি তৃতীয়
          পক্ষের কাছ থেকে (“
          <span className="ft3">চার্জ</span> ”) গ্রহণ করতে বেছে নিতে পারেন এমন কোনও
          পরিষেবার জন্য আপনাকে চার্জ দিতে হবে৷
        </p>
      </div>
      <div id="page_4">
        <p className="p28 ft6">
          <span className="ft1">5.2।</span> কর্ম যোগ প্ল্যাটফর্মে পরিষেবা ব্যবহার
          করার সময় যে কোনও সময়ে কর্ম যোগ দ্বারা প্রদত্ত
          <span className="ft5">পরিষেবাগুলির</span> জন্য
          <span className="ft17">আপনাকে একটি সুবিধার ফি (“</span>
          <span className="ft5">ফি</span> ”) চার্জ করবে৷ এই ফি কর্ম যোগের একমাত্র
          বিবেচনার ভিত্তিতে প্রয়োগ করা হবে। ফি প্রাপ্তির পরেই কর্ম যোগ দ্বারা
          পরিষেবাটি সম্পূর্ণ করা যেতে পারে।
        </p>
        <p className="p29 ft7">
          <span className="ft1">5.3।</span>
          <span className="ft18">
            ফি প্রযোজ্য কর ব্যতীত হবে। কর, সময়ে সময়ে প্রযোজ্য, ফি অতিরিক্ত
            চার্জ করা হবে. আপনার দ্বারা প্রদত্ত ফি চূড়ান্ত এবং
          </span>
          <b>অ-ফেরতযোগ্য,</b> যদি না অন্যথায় কর্ম যোগ দ্বারা নির্ধারিত হয়।
        </p>
        <p className="p12 ft7">
          <span className="ft1">5.4।</span>
          <span className="ft18">
            সমস্ত ফি অবিলম্বে বকেয়া আছে এবং আপনার অ্যাকাউন্টে মনোনীত পছন্দের
            অর্থপ্রদানের পদ্ধতি ব্যবহার করে কর্ম যোগ দ্বারা অর্থ প্রদানের সুবিধা
            হবে, এর পরে কর্ম যোগ আপনাকে ইমেলের মাধ্যমে একটি রসিদ পাঠাবে। যদি
            আপনার প্রাথমিক অ্যাকাউন্টের অর্থপ্রদানের পদ্ধতির মেয়াদ উত্তীর্ণ
            হওয়ার জন্য নির্ধারিত হয়, অবৈধ বা অন্যথায় চার্জ করা যাবে না, আপনি
            সম্মত হন যে কর্ম যোগ আপনার অ্যাকাউন্টে একটি সেকেন্ডারি পেমেন্ট
            পদ্ধতি ব্যবহার করতে পারে, যদি উপলব্ধ থাকে।
          </span>
        </p>
        <p className="p30 ft6">
          <span className="ft1">5.5।</span>
          <span className="ft17">আপনার এবং কর্ম যোগের মধ্যে, কর্ম যোগ</span> তার
          নিজস্ব বিবেচনার ভিত্তিতে যে কোনো সময়ে
          <span className="ft5">পরিষেবাগুলির</span> ব্যবহারের জন্য ফি প্রতিষ্ঠা,
          অপসারণ এবং/অথবা সংশোধন করার অধিকার সংরক্ষণ করে৷ আরও, আপনি স্বীকার করেন
          এবং সম্মত হন যে নির্দিষ্ট ভৌগলিক এলাকায় প্রযোজ্য চার্জ এবং ফি উচ্চ
          চাহিদার সময়ে উল্লেখযোগ্যভাবে বৃদ্ধি পেতে পারে। কর্ম যোগা আপনাকে
          প্রযোজ্য চার্জ এবং ফি সম্পর্কে অবহিত করার জন্য যুক্তিসঙ্গত প্রচেষ্টা
          ব্যবহার করবে, তবে শর্ত থাকে যে আপনি এই ধরনের চার্জ, ফি বা তার পরিমাণ
          সম্পর্কে আপনার সচেতনতা নির্বিশেষে আপনার অ্যাকাউন্টের অধীনে নেওয়া
          চার্জ এবং ফিগুলির জন্য দায়ী থাকবেন। কর্ম যোগ সময়ে সময়ে নির্দিষ্ট
          ব্যবহারকারীদের থেকে একই বা অনুরূপ
          <span className="ft5">পরিষেবাগুলির</span> জন্য বিভিন্ন পরিমাণে চার্জ করতে
          পারে যা
          <span className="ft5">পরিষেবাগুলি</span> ব্যবহার করে প্রাপ্ত হয় এবং আপনি
          সম্মত হন যে এই ধরনের চার্জগুলি আপনার
          <span className="ft5">পরিষেবাগুলির</span> ব্যবহার বা আপনার উপর প্রযোজ্য
          চার্জগুলির উপর কোন প্রভাব ফেলবে না৷ আপনি আপনার অনুরোধের সময় থেকে 2
          (দুই) ঘন্টার মধ্যে একটি তৃতীয় পক্ষ থেকে
          <span className="ft5">পরিষেবাগুলির</span> জন্য আপনার অনুরোধ বাতিল করতে
          নির্বাচন করতে পারেন, এই ক্ষেত্রে আপনাকে একটি বাতিলকরণ ফি চার্জ করা হতে
          পারে৷ অতিরিক্তভাবে, প্রযোজ্য কর (প্রত্যক্ষ বা পরোক্ষ হোক না কেন)
          যেগুলি বাতিলকরণ ফিতে চার্জ করা/কাটা করা হবে তা কর্ম যোগ দ্বারা আপনার
          কাছ থেকে নেওয়া হবে।
        </p>
        <p className="p31 ft3">
          <span className="ft14">6.</span>
          <span className="ft15">দাবিত্যাগ এবং দায়বদ্ধতার সীমাবদ্ধতা</span>
        </p>
        <p className="p32 ft7">
          <span className="ft1">6.1।</span>
          <span className="ft9">
            এই নির্দেশে সীমাবদ্ধতা এবং দাবিত্যাগ 6 দায়বদ্ধতা বা অধিকার পরিবর্তন
            করার উদ্দেশ্য নয় যা প্রযোজ্য আইনের অধীনে বাদ দেওয়া যাবে না৷
          </span>
        </p>
        <p className="p12 ft6">
          <span className="ft1">6.2।</span>
          <span className="ft17">পরিষেবাগুলি</span> &quot;যেমন আছে&quot; এবং
          &quot;যেমন উপলব্ধ&quot;
          <span className="ft5">প্রদান</span> করা হয়৷ কর্ম যোগ সমস্ত উপস্থাপনা এবং
          ওয়্যারেন্টি, এক্সপ্রেস, নিহিত বা বিধিবদ্ধ, এই শর্তাদি স্পষ্টভাবে
          নির্ধারিত নয়, কোনও নির্দিষ্ট উদ্দেশ্য এবং
          <b>
            অ-লঙ্ঘনের জন্য ফিটনেসের কোনও অন্তর্নিহিত ওয়্যারেন্টি সহ স্পষ্টভাবে
            নির্ধারিত নয়,
          </b>{" "}
          এবং কোনও প্রতিনিধিত্ব, ওয়্যারেন্টি বা গ্যারান্টি দেয় না,
          <span className="ft5">পরিষেবাগুলির</span> নির্ভরযোগ্যতা, সময়ানুবর্তিতা,
          গুণমান, উপযোগীতা বা
          <span className="ft5">পরিষেবাগুলির</span> উপলব্ধতা বা
          <span className="ft5">পরিষেবাগুলির</span> ব্যবহারের মাধ্যমে অনুরোধ করা
          কোনও পরিষেবা, বা
          <span className="ft5">পরিষেবাগুলি</span> নিরবচ্ছিন্ন হবে
          <b>৷</b> কর্ম যোগ তৃতীয় পক্ষের প্রদানকারীদের গুণমান, উপযুক্ততা,
          নিরাপত্তা বা সক্ষমতার নিশ্চয়তা দেয় না। আপনি সম্মত হন যে আপনার
          <span className="ft5">পরিষেবাগুলি</span> ব্যবহার করার ফলে উদ্ভূত সমস্ত
          ঝুঁকি এবং এর সাথে সম্পর্কিত যেকোন পরিষেবার অনুরোধ শুধুমাত্র আপনার
          সাথেই থাকবে, সর্বোচ্চ সীমা পর্যন্ত প্রযোজ্য৷
        </p>
        <p className="p33 ft7">
          <span className="ft1">6.3।</span>
          <span className="ft18">
            একটি সংবিধিবদ্ধ গ্যারান্টি মেনে চলতে ব্যর্থতার জন্য কর্ম যোগের
            দায়বদ্ধতা সীমিত:
          </span>
          <span className="ft3">এই</span> চুক্তির অধীনে
          <span className="ft3">পরিষেবার</span> অংশ হিসাবে আপনাকে সরবরাহ করা
          <span className="ft3">পরিষেবাগুলির</span> ক্ষেত্রে, দায়বদ্ধতার অনুমোদন
          কর্ম যোগে আপনার দ্বারা প্রদত্ত
          <span className="ft3">পরিষেবাগুলি</span> ৷
        </p>
        <p className="p34 ft6">
          <span className="ft1">6.4।</span>
          <span className="ft17">
            কর্মের যোগব্যায়াম কোনও পরোক্ষ, ঘটনামূলক, বিশেষ, অনুকরণীয়,
            শাস্তিমূলক বা ফলস্বরূপ ক্ষতির জন্য দায়বদ্ধ হবে না, যার মধ্যে হারানো
            লাভ, হারানো ডেটা, ব্যক্তিগত আঘাত বা সম্পত্তির সাথে সম্পর্কিত, বা
            অন্যথায় পরিষেবার কোনও ব্যবহারের ফলে, বা অন্যথায়, এর সাথে সম্পর্কিত
          </span>
          <span className="ft5">,</span>
          <span className="ft23">
            এমনকি যদি কর্ম যোগে এই ধরনের ক্ষতির সম্ভাবনার পরামর্শ দেওয়া হয়।
          </span>{" "}
          কর্ম যোগ কোন ক্ষতি, দায় বা ক্ষতির জন্য দায়ী থাকবে না: (i) আপনার
          ব্যবহার বা
          <span className="ft5">পরিষেবাগুলির উপর নির্ভরতা বা পরিষেবাগুলি</span>
          <span className="ft5">অ্যাক্সেস</span> বা ব্যবহারে আপনার অক্ষমতা; অথবা
          (ii) আপনার এবং যে কোনো তৃতীয় পক্ষের মধ্যে কোনো লেনদেন বা সম্পর্ক,
          এমনকি যদি কর্ম যোগে এই ধরনের ক্ষতির সম্ভাবনার পরামর্শ দেওয়া হয়। কোন
          অবস্থাতেই সকলের জন্য পরিষেবার সাথে সংযোগে কর্ম
          <span className="ft5">যোগের</span> সম্পূর্ণ দায়বদ্ধতা আপনার উপর থাকবে না
        </p>
      </div>
      <div id="page_5">
        <p className="p35 ft7">
          {" "}
          ক্ষয়ক্ষতি, ক্ষয়ক্ষতি এবং কর্মের কারণগুলি মাত্র
          <span className="ft24">
            এক হাজার টাকা ছাড়িয়ে গেছে (শুধুমাত্র 1,000 টাকা)
          </span>
        </p>
        <p className="p36 ft6">
          <span className="ft1">6.5।</span>
          <span className="ft17">
            কর্ম যোগ একটি অভিযোগ ব্যবস্থাপনা কাঠামো বজায় রাখবে, এবং এই কাঠামোটি
            তৃতীয় পক্ষের পরিষেবা প্রদানকারীদের পক্ষ থেকে একটি যুক্তিসঙ্গত
            উপায়ে এবং ন্যূনতম
          </span>
          <b>শর্তানুসারে পরিচালনা করবে</b> ৷
        </p>
        <p className="p3 ft7">
          <span className="ft1">৬.৬।</span>
          <span className="ft18">
            এই শর্তাবলীর মধ্যে যা কিছু থাকুক না কেন, কর্ম যোগাকে ডিফল্ট বলে গণ্য
            করা হবে না বা দেরি বা ব্যর্থতার জন্য দায়ী বলে বিবেচিত হবে না। এই
            ধরনের কাজ অন্তর্ভুক্ত হবে কিন্তু ঈশ্বরের আইন, দাঙ্গা, যুদ্ধের কাজ,
            মহামারী, মহামারী, বাণিজ্য নিষেধাজ্ঞা, আগুন, টাইফুন, ভূমিকম্প,
            অন্যান্য প্রাকৃতিক দুর্যোগের মধ্যে সীমাবদ্ধ থাকবে না৷ ইটিসি।
          </span>
        </p>
        <p className="p37 ft3">
          <span className="ft1">7.</span>
          <span className="ft25">ক্ষতিপূরণ</span>
        </p>
        <p className="p38 ft7">
          {" "}
          আপনি ক্ষতিপূরণ দিতে সম্মত হন কর্ম যোগ এবং এর সহযোগী সংস্থাগুলি এবং
          তাদের কর্মকর্তা, পরিচালক, কর্মচারী এবং এজেন্টদের যে কোনও এবং সমস্ত
          দাবি, দাবি, ক্ষতি, দায়, এবং খরচ (অ্যাটর্নি ফি সহ) থেকে বা এর সাথে
          সম্পর্কিত: ( i) আপনার
          <span className="ft3">পরিষেবাগুলির</span> ব্যবহার বা পরিষেবাগুলি আপনার
          ব্যবহারের মাধ্যমে প্রাপ্ত
          <span className="ft3">পরিষেবাগুলির</span> ব্যবহার; (ii) আপনার লঙ্ঘন বা এই
          শর্তাবলীর কোনো লঙ্ঘন; (iii) কর্ম যোগ আপনার তথ্য ব্যবহার; অথবা (iv)
          আপনার তৃতীয় পক্ষের অধিকার লঙ্ঘন (“
          <span className="ft3">ক্ষতি</span> ”)।
        </p>
        <p className="p39 ft3">
          <span className="ft14">8.</span>
          <span className="ft15">বিবাদ</span>
        </p>
        <p className="p1 ft6">
          <span className="ft1">8.1।</span>
          <span className="ft5">পরিষেবাগুলি</span> বা এই শর্তগুলির
          <span className="ft8">
            সাথে সম্পর্কিত বা বিস্তৃতভাবে উদ্ভূত যে কোনও বিরোধ, দ্বন্দ্ব, দাবি
            বা বিতর্কের সমাধান করার জন্য আপনার কাছে অনেকগুলি ব্যবস্থা উপলব্ধ
          </span>{" "}
          রয়েছে, এর বৈধতা, এর নির্মাণ বা প্রয়োগযোগ্যতা বা এর সাথে সম্পর্কিত
          বিষয়গুলি সহ তৃতীয় পক্ষের দ্বারা প্রদত্ত পরিষেবা (যেকোন “
          <span className="ft5">বিবাদ</span> ”)। কর্ম যোগা আপনাকে কর্ম যোগ বা তৃতীয়
          পক্ষের ঠিকাদার এবং কর্মীদের সম্পর্কে অভিযোগ করার অনুমতি দেওয়ার জন্য
          একটি অভিযোগ প্রক্রিয়া পরিচালনা করে, এবং কর্ম যোগ কর্ম যোগ সংক্রান্ত
          অভিযোগের ক্ষেত্রে আপনাকে ফেরত দেওয়ার ব্যবস্থাও করে। কর্ম যোগ এই
          অভিযোগ প্রক্রিয়াটিকে যুক্তিসঙ্গতভাবে পরিচালনা করবে। আপনি সম্মত হন এবং
          স্বীকার করেন যে আপনাকে অবশ্যই তৃতীয় পক্ষের ঠিকাদার/কর্মীদের দ্বারা
          প্রদত্ত পরিষেবা বা
          <span className="ft5">পরিষেবাগুলির</span> সাথে যেকোনও বিরোধ উত্থাপন করতে
          হবে যত তাড়াতাড়ি সম্ভব হবে, তবে বিরোধের উদ্ভব হওয়ার 48 (আটচল্লিশ)
          ঘন্টার পরে নয়। আপনি যদি এই ধরনের বিবাদের প্রাথমিক ঘটনার 48 (আটচল্লিশ)
          ঘন্টার মধ্যে একটি বিরোধ উত্থাপন করতে ব্যর্থ হন, তাহলে এই ধরনের বিবাদের
          সমাধান করার জন্য কর্ম যোগের কোন বাধ্যবাধকতা থাকবে না। যদি বিবাদের
          প্রাথমিক ঘটনার 48 (আটচল্লিশ) ঘণ্টারও বেশি সময় পরে একটি বিবাদের
          রিপোর্ট করা হয়, তাহলে কর্ম যোগ তার নিজস্ব বিবেচনার ভিত্তিতে বিবাদের
          সমাধান করার চেষ্টা করবে কি না তা বেছে নেবে।
        </p>
        <p className="p40 ft7">
          <span className="ft1">8.2।</span>
          <span className="ft18">
            এই শর্তাবলীতে অন্যথায় উল্লিখিত ব্যতীত, এই শর্তাদি একচেটিয়াভাবে
            ভারতের আইন দ্বারা পরিচালিত হবে এবং আইনের দ্বন্দ্ব সম্পর্কিত
            নিয়মগুলিকে বাদ দিয়ে, ভারতের আইন অনুসারে তৈরি করা হবে। বিরোধের
            ক্ষেত্রে, উভয় পক্ষই ভারতের বেঙ্গালুরুতে আদালতে একটি অ্যাকশন দায়ের
            করতে পারে বা চূড়ান্ত এবং বাধ্যতামূলক সালিসি বা পক্ষগুলির দ্বারা
            সম্মত অন্যান্য বিকল্প বিরোধ নিষ্পত্তির অনুসরণ করতে পারে। যদি
            পক্ষগুলি চূড়ান্ত এবং বাধ্যতামূলক সালিসি অনুসরণ করতে সম্মত হয়,
            তাহলে সালিশির স্থান হবে ব্যাঙ্গালোর, ভারত। যদি উভয় পক্ষ বিরোধ
            নিষ্পত্তির পদ্ধতিতে একমত হতে না পারে তবে ডিফল্ট বিকল্পটি হবে ভারতের
            ব্যাঙ্গালোরে আদালতে একটি মামলা দায়ের করা।
          </span>
        </p>
        <p className="p25 ft6">
          <span className="ft1">8.3।</span>
          <span className="ft17">
            দলগুলির দ্বারা জমা দেওয়া নথি এবং সংক্ষিপ্ত বিবরণ, একজন
            মধ্যস্থতাকারীর চিঠিপত্র এবং সালিসকারীর দ্বারা জারি করা চিঠিপত্র,
            আদেশ এবং পুরস্কার সহ যেকোন কার্যধারা কঠোরভাবে গোপনীয় থাকবে এবং অন্য
            পক্ষের স্পষ্ট লিখিত সম্মতি ব্যতীত তৃতীয় পক্ষের কাছে প্রকাশ করা হবে
            না। যদি না:
          </span>
        </p>
        <p className="p18 ft7">
          <span className="ft7">(ক)</span>
          <span className="ft10">
            মধ্যস্থতা বা সালিশি কার্যক্রম পরিচালনার ক্ষেত্রে তৃতীয় পক্ষের কাছে
            প্রকাশ যুক্তিসঙ্গতভাবে প্রয়োজন; এবং
          </span>
        </p>
        <p className="p41 ft7">
          <span className="ft7">(b)</span>
          <span className="ft11">
            তৃতীয় পক্ষ এই শর্তাবলীতে সেট করা গোপনীয়তার বাধ্যবাধকতা দ্বারা
            আবদ্ধ হতে লিখিতভাবে নিঃশর্তভাবে সম্মত হয়।
          </span>
        </p>
        <p className="p22 ft3">
          <span className="ft14">9.</span>
          <span className="ft15">অভিযোগ</span>
        </p>
        <p className="p42 ft7">
          <span className="ft1">9.1।</span>
          <span className="ft9">অভিযোগ নিষ্পত্তির পদ্ধতি নিম্নরূপ হবে:</span>
        </p>
      </div>
      <div id="page_6">
        <p className="p43 ft7">
          <span className="ft1">9.1.1।</span>
          <span className="ft26">
            সংক্ষুব্ধ পক্ষ প্রমাণের সাথে অভিযোগ উত্থাপন করে এবং কর্ম যোগে
            লিখিতভাবে জমা দেয়।
          </span>
        </p>
        <p className="p44 ft7">
          <span className="ft1">9.1.2।</span>
          <span className="ft27">
            কর্ম যোগা অভিযোগ উত্থাপিত হওয়ার 48 ঘন্টার মধ্যে একটি নিবেদিত অভিযোগ
            নিষ্পত্তি অফিসারকে অভিযোগের সমাধানের দায়িত্ব দেবে।
          </span>
        </p>
        <p className="p45 ft6">
          <span className="ft1">9.1.3।</span>
          <span className="ft28">
            অফিসার যথাযথ অধ্যবসায়ের সাথে তদন্ত করতে এগিয়ে যাবেন, অভিযোগ
            উত্থাপিত হবে এবং ব্যবহারকারী এবং কর্ম যোগের মধ্যে এই সম্পর্ক এবং
            সেইসাথে পরিষেবাটি ব্যবহার করার সাথে যুক্ত ব্যবহারকারী এবং পক্ষের
            মধ্যে সম্পর্ককে নিয়ন্ত্রণকারী শর্তগুলির উপর ভিত্তি করে একটি সমাধানে
            পৌঁছাবে।
          </span>
        </p>
        <p className="p46 ft7">
          <span className="ft1">9.1.4।</span>
          <span className="ft27">
            যদি উত্থাপিত অভিযোগটি শর্তাবলীর আওতায় না থাকে, তাহলে ভারতের আইন
            অনুযায়ী এবং কর্ণাটক রাজ্যে বিশেষভাবে প্রযোজ্য অনুযায়ী তাদের সমাধান
            করা হবে
          </span>
        </p>
        <p className="p47 ft7">
          <span className="ft1">9.1.5।</span>
          <span className="ft27">
            সংক্ষুব্ধ পক্ষকে অভিযোগের সমাধানের অগ্রগতির পাশাপাশি সমাধানের
            আনুমানিক সময় সম্পর্কে আপডেট রাখা হবে।
          </span>
        </p>
        <p className="p32 ft7">
          <span className="ft1">9.2।</span>
          <span className="ft18">
            অফিসার দ্বারা অভিযোগের কোন যুক্তিসঙ্গত সমাধান না হলে, অভিযোগটিকে
            একটি বিরোধ হিসাবে বিবেচনা করা যেতে পারে এবং এই শর্তাবলীতে থাকা
            পদ্ধতি অনুসারে সমাধান করা যেতে পারে।
          </span>
        </p>
        <p className="p22 ft3">
          <span className="ft1">10.</span>
          <span className="ft29">নোটিশ</span>
        </p>
        <p className="p48 ft6">
          {" "}
          কর্ম যোগ
          <span className="ft5">পরিষেবাগুলিতে</span> একটি সাধারণ নোটিশের মাধ্যমে,
          আপনার অ্যাকাউন্টে আপনার ইমেল ঠিকানায় ইলেকট্রনিক মেল, বা আপনার
          অ্যাকাউন্টে উল্লিখিত আপনার ঠিকানায় পাঠানো লিখিত যোগাযোগের মাধ্যমে
          নোটিশ দিতে পারে। আপনি #859, 17th G Main Road, 5th Block Rajajinagar,
          Bangalore 560010-এ CEO, Karma Yoga-কে লিখিত যোগাযোগের মাধ্যমে বা
          Notices@karmayoga.app-এ ইমেল করে কর্ম যোগের নোটিশ দিতে পারেন।
        </p>
        <p className="p42 ft3">
          <span className="ft1">11.</span>
          <span className="ft30">সাধারণ</span>
          <span className="ft7">।</span>
        </p>
        <p className="p26 ft6">
          <span className="ft1">11.1।</span>
          <span className="ft31">
            আপনি কর্ম যোগের পূর্ব লিখিত অনুমোদন ছাড়া সম্পূর্ণ বা আংশিকভাবে এই
            শর্তাদি বরাদ্দ বা স্থানান্তর করতে পারবেন না। আপনি কর্ম যোগকে আপনার
            অনুমোদন দেন যাতে এই শর্তাদি সম্পূর্ণ বা আংশিকভাবে কোনো তৃতীয় পক্ষের
            কাছে বরাদ্দ করা বা স্থানান্তর করা হয়, যার মধ্যে একটি সহায়ক,
            অধিভুক্ত, কর্ম যোগের ইক্যুইটি, ব্যবসা বা সম্পদ, উত্তরাধিকারী, বা
            অন্য কোনো অ্যাসাইনি সহ।
          </span>
        </p>
        <p className="p3 ft6">
          <span className="ft1">11.2।</span>
          <span className="ft32">
            কর্ম যোগের ভূমিকা শুধুমাত্র প্ল্যাটফর্ম হোস্ট করার মধ্যেই সীমাবদ্ধ,
            এবং প্ল্যাটফর্মে একে অপরকে খুঁজে পেতে পরিষেবার প্রয়োজন এবং অফার করা
            পক্ষগুলিকে অনুমতি দেয়৷
          </span>{" "}
          আপনার এবং কর্ম যোগের মধ্যে চুক্তি বা
          <span className="ft5">পরিষেবাগুলির</span>
          <span className="ft32">
            ব্যবহারের ফলে আপনার, কর্ম যোগ বা কোনো তৃতীয় পক্ষের ঠিকাদার/কর্মীদের
            মধ্যে কোনো যৌথ উদ্যোগ, অংশীদারিত্ব, কর্মসংস্থান বা সংস্থার সম্পর্ক
            বিদ্যমান নেই
          </span>{" "}
          ।
        </p>
        <p className="p2 ft6">
          <span className="ft1">11.3।</span>
          <span className="ft32">
            এই শর্তাবলীর কোন বিধান সম্পূর্ণ বা আংশিকভাবে, কোন আইনের অধীনে
            বেআইনি, অবৈধ বা অপ্রয়োগযোগ্য বলে ধরে নেওয়া হলে, এই ধরনের বিধান বা
            এর অংশ সেই পরিমাণে এই শর্তগুলির অংশ নয় কিন্তু বৈধতা, বৈধতা এবং এই
            শর্তাবলীর অন্যান্য বিধানের প্রয়োগযোগ্যতা প্রভাবিত হবে না। সেই
            ইভেন্টে, পক্ষগুলি বেআইনি, অবৈধ বা অপ্রয়োগযোগ্য বিধান বা এর অংশটিকে
            একটি বিধান বা এর অংশ দিয়ে প্রতিস্থাপন করবে যা আইনী, বৈধ এবং
            প্রয়োগযোগ্য এবং যা সম্ভাব্য সর্বাধিক পরিমাণে, বেআইনি, অবৈধ বা এর
            মতো একই প্রভাব ফেলবে। এই শর্তাবলীর বিষয়বস্তু এবং উদ্দেশ্য প্রদত্ত
            অপ্রয়োগযোগ্য বিধান বা এর অংশ।
          </span>
        </p>
        <p className="p3 ft7">
          <span className="ft1">11.4।</span>
          <span className="ft33">
            এই শর্তাবলী, যেকোন অন্তর্ভুক্ত নীতিগুলি সহ, এর বিষয়বস্তুর ক্ষেত্রে
            পক্ষগুলির সম্পূর্ণ চুক্তি এবং বোঝাপড়া গঠন করে এবং এই জাতীয় বিষয়
            সম্পর্কিত সমস্ত পূর্ববর্তী বা সমসাময়িক চুক্তি বা উদ্যোগগুলিকে
            প্রতিস্থাপন করে এবং প্রতিস্থাপন করে৷ এই ধারার কোনো কিছুই ভোক্তা
            হিসেবে আপনার অধিকারকে সীমিত করে না যা প্রযোজ্য আইনের অধীনে বাদ
            দেওয়া যায় না।
          </span>
        </p>
      </div>
      <div id="page_7">
        <div id="p7dimg1">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAocAAAAZCAIAAADMhROvAAAAgklEQVR4nO3VMQEAIAzAMMC/p1njwQM9EgX9umdmAQAB53cAAPC4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVFzU5QJv52dqgAAAAABJRU5ErkJggg=="
            id="p7img1"
          />
        </div>
        <p className="p49 ft0"> পরিষেবার ব্যবহারকারীদের পরিচালনার শর্তাবলী৷</p>
        <p className="p50 ft3">
          <span className="ft1">1.</span>
          <span className="ft2">ব্যবহারকারীদের মধ্যে সম্পর্ক</span>
        </p>
        <p className="p51 ft6">
          <span className="ft1">1.1।</span>
          <span className="ft4">এই ব্যবহারের শর্তাবলী (&quot;</span>
          <span className="ft5">শর্তাবলী</span> &quot;) One Source Karma Yoga Pvt-এর
          দ্বারা উপলব্ধ
          <span className="ft5">পরিষেবাগুলির</span> মাধ্যমে সংযুক্ত
          <span className="ft5">ব্যবহারকারীদের</span> মধ্যে সম্পর্ক নিয়ন্ত্রণ করে৷
          Ltd., ভারতে প্রতিষ্ঠিত একটি প্রাইভেট লিমিটেড কোম্পানি, যার নিবন্ধিত
          অফিস রয়েছে #859, 17th G Main Road, 5th Block Rajajinagar, Bangalore
          560010 (“
          <span className="ft5">কর্ম যোগ</span> ”)।
        </p>
        <p className="p52 ft7">
          <span className="ft1">1.2।</span>
          <span className="ft9">
            কর্ম যোগ দ্বারা প্রদত্ত পরিষেবা ব্যবহার করে এমন কোনও সংস্থা বা
            ব্যক্তি হল
          </span>
          <span className="ft3">&quot;ব্যবহারকারী&quot;৷</span> ব্যবহারকারী ক্রয়
          এবং/অথবা
          <b>ট্রেড-পরিষেবা</b> অফার করতে
          <span className="ft3">পরিষেবাগুলি</span> ব্যবহার করতে পারে৷
        </p>
        <p className="p53 ft7">
          <span className="ft1">1.3।</span>
          <span className="ft9">
            কর্ম যোগা শুধুমাত্র সমস্ত ব্যবহারকারীদের জন্য তাদের প্রয়োজনীয়তা
            পোস্ট করতে এবং একটি মিলিত অংশীদার খুঁজে পেতে সক্ষম করার জন্য একটি
            প্ল্যাটফর্ম প্রদান করে। অংশীদারদের মধ্যে সম্পর্ক একচেটিয়াভাবে এবং
            শুধুমাত্র ব্যবহারকারীদের দায়িত্ব। কর্ম যোগের ভূমিকা প্ল্যাটফর্ম
            প্রদান, একটি মিল খুঁজে পাওয়া এবং অংশীদারদের সংযোগ করা এবং
            ব্যবহারকারী অংশীদারদের মধ্যে একটি
          </span>
          <b>ই-চুক্তির</b> ব্যবস্থা করার মধ্যে সীমাবদ্ধ।
        </p>
        <p className="p54 ft3">
          <span className="ft14">2.</span>
          <span className="ft15">পরিষেবা</span>
        </p>
        <p className="p55 ft7">
          <span className="ft1">2.1।</span>
          <span className="ft9">
            কর্ম যোগা ব্যবহারকারীদের নিয়োগের জন্য বা বাণিজ্য- পরিষেবা প্রদানের
            জন্য তাদের প্রয়োজনীয়তা তালিকাভুক্ত করার জন্য একটি প্ল্যাটফর্ম
            প্রদান করে।
          </span>
        </p>
        <p className="p56 ft6">
          <span className="ft1">2.2।</span>
          <span className="ft17">
            ব্যবহারকারীদের প্ল্যাটফর্মে প্রবেশ করতে হবে, তাদের প্রয়োজনীয়তা
            (নিয়োগ করার জন্য বা ট্রেড- পরিষেবা দেওয়ার জন্য) (i)
          </span>
          <b>ট্রেড-সার্ভিস</b> বিভাগ (ii) অবস্থান (iii) কর্মীদের পরিমাণ এবং (iv)
          পছন্দের হার/মূল্য পরিমাপের পূর্বনির্ধারিত একক প্রতি।
        </p>
        <p className="p57 ft7">
          <span className="ft1">2.3।</span>
          <span className="ft18">ব্যবহারকারীরা যারা নিয়োগ দেয় এবং যারা</span>
          <b>
            ট্রেড-পরিষেবা অফার করে উভয়ের কাছ থেকে প্রতিযোগিতামূলক মূল্য দেখতে
            পারে।
          </b>
        </p>
        <p className="p58 ft7">
          <span className="ft1">2.4।</span> ব্যবহারকারীরা বিভিন্ন
          <b>ট্রেড-সার্ভিস</b> বিভাগ এবং অবস্থানের
          <span className="ft18">জন্য একাধিক এন্ট্রিও করতে পারে</span> ।
        </p>
        <p className="p59 ft7">
          <span className="ft1">2.5।</span>
          <span className="ft18">
            একটি মিল পাওয়া পর্যন্ত ব্যবহারকারীদের তাদের পছন্দের হার পরিবর্তন
            করার সুযোগ আছে.
          </span>
        </p>
        <p className="p60 ft7">
          <span className="ft1">2.6।</span>
          <span className="ft18">
            এই হার চার সপ্তাহের জন্য বৈধ থাকবে বা যতক্ষণ না ব্যবহারকারী তার
            এন্ট্রি পরিবর্তন করে যা আগে হয়।
          </span>
        </p>
        <p className="p56 ft7">
          <span className="ft1">2.7।</span>
          <span className="ft22">
            কারমা যোগ প্ল্যাটফর্মটি ব্যবহারকারীদের দ্বারা পোস্ট করা
            প্রয়োজনীয়তাগুলির সাথে মিল করার জন্য অ্যালগরিদম স্থাপন করবে
          </span>{" "}
          যারা তাদের ট্রেড-পরিষেবাগুলি অফার করতে চায় তাদের সাথে
          <b>ট্রেড-সার্ভিস</b> ভাড়া করতে চায়
          <b>৷</b> এটি একটি স্বয়ংক্রিয় প্রক্রিয়া এবং একবার সমস্ত ভেরিয়েবল
          মিলে গেলে, সিস্টেম দুটি ব্যবহারকারী অংশীদারের মধ্যে একটি
          <b>ই-চুক্তি</b> তৈরি করে যার প্রয়োজনীয়তা পূরণ করা হয়েছে৷
        </p>
        <p className="p54 ft3">
          <span className="ft14">3.</span>
          <span className="ft15">
            ব্যবহারকারীদের মধ্যে সম্পর্ক নিয়ন্ত্রণকারী শর্তাবলী৷
          </span>
        </p>
        <p className="p58 ft7">
          <span className="ft1">3.1।</span> কর্ম যোগ দ্বারা প্রদত্ত
          <span className="ft3">পরিষেবাগুলি</span>
          <span className="ft9">ব্যবহার করে এগিয়ে যাওয়ার মাধ্যমে</span> ,
          ব্যবহারকারী নিশ্চিত করে যে:
        </p>
        <p className="p61 ft6">
          <span className="ft1">3.1.1।</span>
          <span className="ft34">
            তারা এই সম্পূর্ণ শর্তাবলী এবং ব্যবহারের শর্তাবলী পড়েছে এবং সেগুলি
            দ্বারা আবদ্ধ রয়েছে যা এইগুলি সহ যা ব্যবহারকারীদের মধ্যে
            সম্পর্কগুলিকে নিয়ন্ত্রণ করে যাদের প্রয়োজনীয়তাগুলি &quot;
          </span>
          <span className="ft5">ব্যবহারকারী অংশীদার</span> &quot; মেলে
        </p>
        <p className="p62 ft7">
          <span className="ft1">3.1.2।</span>
          <span className="ft27">তারা প্রদত্ত তথ্যের যথার্থতা নিশ্চিত করে</span>
        </p>
        <p className="p63 ft7">
          <span className="ft1">3.1.3।</span>
          <span className="ft27">
            ব্যবহারকারী (যদি &#39;কোন প্রতিষ্ঠানের জন্য কাজ করেন&#39; বা
            &#39;প্রতিনিধি&#39;) আবেদনে তার ফার্ম নিবন্ধন করার জন্য আইনত
            অনুমোদিত এবং অ্যাপ প্রক্রিয়ার ফলাফলকে সম্মান করতে বাধ্য।
          </span>
        </p>
        <p className="p64 ft7">
          <span className="ft1">3.1.4।</span>
          <span className="ft27">
            ব্যবহারকারীকে তার প্রয়োজনীয়তা তালিকাভুক্ত করার আগে প্ল্যাটফর্মে
            নিবন্ধন করতে হবে। তাকে সংস্থার নাম, অনুমোদিত ব্যবহারকারীর নাম,
            মোবাইল নম্বর, জিএসটি নম্বর/আধার নম্বর/প্যান নম্বর (প্রযোজ্য হিসাবে)
            এবং সংস্থার আইনি সত্তা প্রতিষ্ঠার জন্য প্রয়োজন হতে পারে এমন অন্য
            কোনও তথ্য প্রদান করতে হবে বা ব্যক্তি
          </span>
        </p>
        <p className="p65 ft7">
          <span className="ft1">3.1.5।</span>
          <span className="ft27">
            ব্যবহারকারীরা স্বীকার করেন যে কর্ম যোগ প্ল্যাটফর্মে প্রদত্ত তথ্য
            যাচাই করার অধিকার সংরক্ষণ করে।
          </span>
        </p>
        <p className="p66 ft7">
          <span className="ft1">3.1.6।</span>
          <span className="ft27">প্ল্যাটফর্মে প্রবেশ করা সমস্ত তথ্য</span>
          <b>ট্রেড-পরিষেবার</b> জন্য প্রকৃত প্রয়োজনীয়তাগুলিকে উপস্থাপন করে
        </p>
      </div>
      <div id="page_8">
        <p className="p67 ft6">
          <span className="ft1">3.1.7।</span>
          <span className="ft28">
            ব্যবহারকারী স্বীকার করে যে অর্ডার ম্যাচিং প্রক্রিয়াটি একটি
            স্বয়ংক্রিয় প্রক্রিয়া এবং
          </span>
          <b>ট্রেড-সার্ভিস</b> বিভাগ, অবস্থান এবং মূল্যের শর্তগুলি মিলিত হওয়ার
          সাথে সাথে একটি ম্যাচ প্রদান করবে।
        </p>
        <p className="p68 ft7">
          <span className="ft1">3.1.8।</span> ক্রেতা এবং বিক্রেতার
          <b>অফার-পরিমাণ</b>
          <span className="ft27">মেলানোর জন্য সর্বাত্মক প্রচেষ্টা করা হবে</span> ,
          অ্যাপটি মূল্যের মিলের অগ্রাধিকারের ভিত্তিতে কাজ করে এবং এর ফলে একটি
          সম্পূর্ণ অফার-পরিমাণ মিল নাও যাওয়ার সম্ভাবনা রয়েছে।
        </p>
        <p className="p44 ft7">
          <span className="ft1">3.1.9।</span>
          <span className="ft27">পক্ষগুলি সম্মত হয় যে</span>
          <b>অফার-পরিমাণটির</b> আংশিক পূর্ণতা একটি স্বতন্ত্র সম্ভাবনা এবং এই অংশ
          পূরণের মাধ্যমে তাদের অফার বাতিল করা যাবে না।
        </p>
        <p className="p69 ft7">
          <span className="ft1">3.1.10।</span>
          <span className="ft35">ব্যালেন্স</span>
          <b>অফার-পরিমাণ</b> পোস্ট পার্ট পূরণ প্ল্যাটফর্মে ভবিষ্যতে ম্যাচের জন্য
          বিভাগ, অবস্থান এবং মূল্যের শর্তে পোস্ট করা থাকবে।
        </p>
        <p className="p47 ft7">
          <span className="ft1">3.1.11।</span>
          <span className="ft36">যখন একটি মিলিত মূল্য পাওয়া যায় (</span>{" "}
          ব্যবহারকারীর দ্বারা
          <b>করা মূল্য-অফারে</b> ), অ্যাপটি স্বয়ংক্রিয়ভাবে একটি
          <b>ই-চুক্তি</b> তৈরি করবে যা ব্যবহারকারীর সম্মানের জন্য বাধ্যতামূলক
          হবে।
        </p>
        <p className="p70 ft6">
          <span className="ft1">3.1.12।</span>
          <span className="ft37">
            শর্তাদি স্বীকার করে, ব্যবহারকারী তার নিজের ইচ্ছায় এবং কোনো জবরদস্তি
            ছাড়াই, ব্যবহারকারী অংশীদারের সাথে একটি
          </span>
          <b>ই-চুক্তি</b> করতে সম্মতি দেয়। তিনি আরও নিশ্চিত করেন যে প্রদত্ত
          তথ্য (নাম/জিএসটি নম্বর/আধার নম্বর/যোগাযোগ ব্যক্তির নাম এবং মোবাইল
          নম্বর, ইত্যাদি) একটি উপযুক্ত মিল পাওয়া গেলে একটি
          <b>ই-চুক্তিতে</b> প্রবেশ করতে ব্যবহার করা যেতে পারে।
        </p>
        <p className="p71 ft6">
          <span className="ft1">3.1.13।</span>
          <span className="ft37">
            এই শর্তাবলী স্বীকার করে, ব্যবহারকারী নিশ্চিত করে যে এই অনুমোদনটি
            ডিজিটাল স্বাক্ষর হিসাবে প্রদত্ত তথ্য ব্যবহারের জন্য একটি অনুমোদন গঠন
            করে যা
          </span>
          <b>ই-চুক্তিতে যুক্ত করা যেতে পারে।</b>
        </p>
        <p className="p72 ft6">
          <span className="ft1">3.1.14।</span>
          <span className="ft38">
            ব্যবহারকারী প্ল্যাটফর্মের স্বয়ংক্রিয় ম্যাচিং প্রক্রিয়া দ্বারা
            নির্ধারিত প্রতিপক্ষের সাথে একটি চুক্তিতে প্রবেশ করবে এবং
          </span>
          <b>ই-চুক্তির নিবন্ধগুলিকে সম্মান করবে৷</b>
          <b>ই-চুক্তির</b> নিবন্ধে সেই শর্তগুলি অন্তর্ভুক্ত থাকবে যেগুলির উপর
          ব্যবহারকারীরা একে অপরের সাথে আবদ্ধ থাকবেন যা &quot;মূল্য নির্ধারণের
          নীতি&quot; শিরোনামের নীচে তালিকাভুক্ত করা হয়েছে।
        </p>
        <p className="p2 ft7">
          <span className="ft1">3.2।</span>
          <span className="ft18">ব্যবহারকারী অংশীদার</span>
          <b>ই-চুক্তি</b> মেনে না চলার জন্য কর্ম যোগের কোনো দায় নেই এবং
          ব্যবহারকারীকে ই-চুক্তির
          <b>অ-সম্পাদনা</b> থেকে উদ্ভূত সমস্ত আইনি প্রক্রিয়া থেকে কর্ম যোগকে
          ক্ষতিপূরণ দিতে হবে
          <b>।</b>
        </p>
        <p className="p44 ft7">
          <span className="ft1">3.2.1।</span>
          <span className="ft27">এই ধরনের</span>
          <b>অ-সম্মতির ক্ষেত্রে,</b> ব্যবহারকারীকে এই ধরনের
          <b>অ-সম্মতির</b> 48 ঘন্টার মধ্যে কর্ম যোগকে অবহিত করতে হবে
        </p>
        <p className="p69 ft7">
          <span className="ft1">3.2.2।</span>
          <span className="ft39">যদিও কর্ম যোগ</span>
          <b>ই-চুক্তি কার্যকর করতে পারে না,</b> কর্ম যোগা অংশীদারদের সাথে আলোচনা
          করার চেষ্টা করবে যাতে তারা মেনে চলে
        </p>
        <p className="p47 ft7">
          <span className="ft1">3.2.3।</span>
          <span className="ft39">
            এটি ব্যর্থ হলে, কর্ম যোগ প্রয়োজনীয়তাগুলি পুনঃস্থাপন করবে এবং
            প্ল্যাটফর্মের মাধ্যমে একটি প্রাথমিক মিল খুঁজে বের করার চেষ্টা করবে
          </span>
        </p>
        <p className="p5 ft7">
          <span className="ft1">3.2.4।</span>
          <span className="ft36">
            তবে কর্ম যোগ গ্যারান্টি দিতে পারে না বা প্রতিস্থাপনের জন্য দায়ী হতে
            পারে না।
          </span>
        </p>
        <p className="p11 ft7">
          <span className="ft1">3.3।</span> ব্যবহারকারী কোনো বিরোধের ক্ষেত্রে
          <b>ই-চুক্তিতে</b>
          <span className="ft18">
            তার কাছে উপলব্ধ প্রতিকারগুলি কার্যকর করার জন্য উপযুক্ত আইনি
            কর্তৃপক্ষের কাছে যেতে পারেন
          </span>{" "}
          ।
        </p>
        <p className="p73 ft3">
          <span className="ft1">4.</span>
          <span className="ft15">মূল্য নির্ধারণের ভিত্তি এবং তথ্য</span>
        </p>
        <p className="p11 ft7">
          <span className="ft1">4.1।</span> যে কোনো
          <b>ট্রেড-সার্ভিস</b> বিভাগ এবং অবস্থানের
          <span className="ft9">
            জন্য প্ল্যাটফর্মে যে মূল্য প্রবেশ করানো হচ্ছে
          </span>{" "}
          তা হল &quot;
          <span className="ft3">দর</span> &quot; ব্যবহারকারী অফার করতে ইচ্ছুক এবং তা
          অনুসরণ করা শর্তগুলির উপর ভিত্তি করে।
        </p>
        <p className="p22 ft7">
          <span className="ft1">4.2।</span>
          <span className="ft18">
            প্ল্যাটফর্মে তার দ্বারা প্রবেশ করা মূল্যের বিষয়ে ব্যবহারকারী
            নিম্নলিখিতগুলি স্বীকার করে:
          </span>
        </p>
        <p className="p74 ft6">
          <span className="ft1">4.2.1।</span>
          <span className="ft28">
            আবেদনে রেট/মূল্য প্রবেশ করানো/সংশোধন করা বাধ্যতামূলক (ব্যবহারকারীর
            দ্বারা সংশোধন না হওয়া পর্যন্ত)। একবার একটি মিলে যাওয়া দাম পাওয়া
            গেলে, আর কোনো সংশোধন করা সম্ভব হবে না।
          </span>
        </p>
        <p className="p75 ft7">
          <span className="ft1">4.2.2।</span>
          <span className="ft36">
            নির্দিষ্ট বিভাগের জন্য অ্যাপে উল্লিখিত পরিমাপের এককের জন্য রেট/মূল্য
            প্রবেশ/সংশোধন করা হচ্ছে
          </span>
        </p>
        <p className="p76 ft7">
          <span className="ft1">4.2.3।</span>
          <span className="ft36">
            রেট/মূল্য প্রবেশ করানো/সংশোধন করা হচ্ছে বিশেষভাবে নিচে উল্লেখ করা
            দামের শর্তের উপর ভিত্তি করে
          </span>
        </p>
      </div>
      <div id="page_9">
        <p className="p77 ft7">
          <span className="ft1">4.3।</span>
          <span className="ft18">
            প্ল্যাটফর্মে যে হার দেওয়া হচ্ছে তা হল ট্রেডের জন্য বকেয়া নেট মজুরি
            (প্রদেয় বা প্রাপ্তিযোগ্য) - পরিষেবা প্রদান করা।
          </span>
        </p>
        <p className="p78 ft7">
          <span className="ft1">4.4</span>
          <span className="ft18">
            প্ল্যাটফর্মে যে নেট &quot;রেট&quot; দেওয়া হয় এবং
          </span>
          <b>ই-কন্ট্রাক্টে</b>
          <span className="ft3">এগিয়ে</span> নিয়ে যাওয়া হয় তা নিম্নোক্ত
          সুবিধাগুলি ছাড়া। নিম্নোক্ত সুবিধাগুলি
          <b>
            ট্রেড-পরিষেবার ভাড়াকারীকে অতিরিক্ত এবং বিনামূল্যে প্রদান করতে হবে।
          </b>{" "}
          প্রদান না করা হলে
          <b>ট্রেড-পরিষেবা</b> প্রদানকারী অংশীদারকে ভাড়ার দ্বারা ক্ষতিপূরণ দিতে
          হবে, যে সুবিধাগুলি প্রদান করা হয়নি তার খরচ। ক্ষতিপূরণ ব্যবহারকারী
          অংশীদারদের মধ্যে পারস্পরিক সম্মত শর্ত অনুযায়ী হতে পারে।
        </p>
        <p className="p79 ft6">
          <span className="ft1">4.4.1।</span>
          <span className="ft40">
            ওয়েদার প্রুফ আবাসন - ন্যূনতম গঠিত (ক) শক্ত মেঝে, (খ) মেঝে থেকে
            সিলিং পর্যন্ত ঢেউখেলানো জিআই শিটিং দেয়াল, (গ) জিআই শিটিংয়ের ঢালু
            সিলিং (ঘ) পৃথক শেয়ার্ড টয়লেট এবং গোসলের ব্যবস্থা (ঙ) পর্যাপ্ত
            জায়গা সহ পৃথক রান্নাঘর এবং রান্নার ব্যবস্থা (f) বৃষ্টির জল নিষ্কাশন
            ব্যবস্থা প্রাঙ্গণকে শুষ্ক রাখার জন্য (g) নিয়মিত এবং পর্যায়ক্রমে
            পরিষ্কার করা, আবর্জনা সংগ্রহ, মশা এবং অন্যান্য উপদ্রবের বিরুদ্ধে
            চিকিত্সা সহ শ্রম শিবিরের রক্ষণাবেক্ষণ এবং রক্ষণাবেক্ষণ (জ) আবর্জনা
            সংগ্রহ এবং নিষ্পত্তি (জ) জনপ্রতি স্থান সহ ভাগ করা আবাসন সর্বনিম্ন 3
            m2
          </span>
        </p>
        <p className="p80 ft6">
          <span className="ft1">4.4.2।</span>
          <span className="ft41">
            প্রকল্পের প্রয়োজনীয় ব্যক্তিগত সুরক্ষা সরঞ্জাম - প্রতি 6 মাসে একবার
            পিপিই প্রতিস্থাপন করা হবে। PPE-তে কোনো প্রতিস্থাপনের প্রয়োজন হলে,
            যে কোনো কারণে, ইস্যু হওয়ার তারিখ থেকে ছয় মাসের মধ্যে, প্রতিস্থাপন
            চার্জযোগ্য ভিত্তিতে হবে।
          </span>
        </p>
        <p className="p72 ft6">
          <span className="ft1">4.4.3।</span>
          <b>ট্রেড-পরিষেবা</b>
          <span className="ft41">
            প্রদানের জন্য প্রয়োজনীয় সমস্ত সরঞ্জাম এবং ট্যাকল এবং সরঞ্জাম
          </span>{" "}
          । বিক্রেতার দ্বারা প্রদত্ত যেকোন সরঞ্জাম এবং ট্যাকলগুলি পারস্পরিক
          চুক্তির মাধ্যমে ক্ষতিপূরণ দিতে হবে। সরঞ্জাম, ট্যাকল এবং সরঞ্জাম
          ভাড়াদারের সম্পত্তি থাকবে।
        </p>
        <p className="p81 ft7">
          <span className="ft1">4.4.4।</span>
          <b>বাণিজ্য-পরিষেবার</b>
          <span className="ft36">
            কার্যকর বিধানের জন্য প্রয়োজনীয় লিফট, উত্তোলন, ক্রেন এবং অন্যান্য
            প্ল্যান্ট এবং যন্ত্রপাতি
          </span>{" "}
          ।
        </p>
        <p className="p82 ft7">
          <span className="ft1">4.4.5।</span> অতিরিক্ত অপচয় ভাতা (আইএস কোড অফ
          প্র্যাকটিস বা CPWD নিয়ম বা সাধারণভাবে স্বীকৃত শিল্প নিয়ম অনুসারে) সহ
          <b>বাণিজ্য-পরিষেবাগুলি</b>
          <span className="ft36">সম্পাদনের জন্য প্রয়োজনীয় সমস্ত উপাদান</span>{" "}
          কাজের স্থানের 100 মিটারের মধ্যে সরবরাহ করা হয়।
        </p>
        <p className="p83 ft6">
          <span className="ft1">4.4.6।</span>
          <span className="ft41">
            কাজের স্থানের 100 মিটারের মধ্যে সংগ্রহস্থল থেকে ধ্বংসাবশেষ
            নিষ্পত্তি। এর বাইরে বা অতিরিক্ত যে কোনো গৃহস্থালির প্রয়োজনীয়তা
            পারস্পরিক চুক্তির মাধ্যমে ক্ষতিপূরণ দিতে হবে।
          </span>
        </p>
        <p className="p81 ft7">
          <span className="ft1">4.4.7।</span>
          <span className="ft39">
            আবাসন শিবির থেকে কর্মস্থলে যাতায়াত এবং দূরত্ব 1 কিলোমিটারের বেশি
            হলে।
          </span>
        </p>
        <p className="p8 ft7">
          <span className="ft1">4.4.8।</span>
          <span className="ft36">
            বর্তমান অবস্থান থেকে কর্মস্থলে টিকিট (একভাবে) বা তার খরচ
          </span>
        </p>
        <p className="p5 ft7">
          <span className="ft1">4.4.9</span>
          <span className="ft36">
            প্রভিডেন্ট ফান্ড পেমেন্ট (সংবিধিবদ্ধভাবে প্রয়োজন হিসাবে)
          </span>
        </p>
        <p className="p84 ft7">
          <span className="ft1">4.4.10।</span>
          <span className="ft21">
            স্বাস্থ্য কভারেজের জন্য কর্মচারী রাজ্য বীমা অর্থপ্রদান
            (সংবিধিবদ্ধভাবে প্রয়োজনীয়)
          </span>
        </p>
        <p className="p5 ft7">
          <span className="ft1">4.4.11।</span>
          <span className="ft42">
            অন্যান্য সমস্ত সংবিধিবদ্ধ প্রয়োজনীয়তা (BOCW, শ্রম লাইসেন্স,
            ইত্যাদি)
          </span>
        </p>
        <p className="p84 ft7">
          <span className="ft1">4.4.12।</span>
          <span className="ft21">
            GST বা অন্যান্য সংবিধিবদ্ধ শুল্ক এবং কর, যেখানে প্রযোজ্য
          </span>
        </p>
        <p className="p73 ft7">
          <span className="ft1">4.5।</span>
          <span className="ft18">নেট</span>
          <b>অফার-মূল্য</b> নিম্নলিখিত প্রয়োজনীয়তাগুলি
          <span className="ft3">সহ</span>
        </p>
        <p className="p76 ft7">
          <span className="ft1">4.5.1।</span> সংশ্লিষ্ট
          <b>ট্রেড-পরিষেবার</b>
          <span className="ft27">
            জন্য IS কোড অফ প্র্যাকটিস অনুযায়ী পরিষেবাগুলি গুণমানের
            প্রয়োজনীয়তাগুলির সাথে মেলে বা অতিক্রম করবে
          </span>{" "}
          ৷
        </p>
        <p className="p47 ft7">
          <span className="ft1">4.5.2।</span>
          <span className="ft36">
            নিজ নিজ আইএস কোড অফ প্র্যাকটিস বা সিপিডব্লিউডি নিয়ম বা প্রমিত শিল্প
            অনুশীলন অনুসারে অপচয়গুলি পরিবর্তনের সীমার মধ্যে সীমাবদ্ধ থাকবে
            (যেকোন পরিবর্তন পারস্পরিকভাবে সম্মত হতে পারে।)
          </span>
        </p>
        <p className="p70 ft6">
          <span className="ft1">4.5.3।</span>
          <span className="ft41">
            ব্যক্তিগত এবং কর্পোরেট আয়কর প্রযোজ্য (এবং বিধিবদ্ধ প্রয়োজনীয়তা
            অনুযায়ী ছাড়গুলি অর্থপ্রদানের ক্ষেত্রে প্রযোজ্য)। প্রতিষ্ঠান এবং
            ব্যক্তিদের আলাদাভাবে প্রযোজ্য ট্যাক্স নিয়ম মেনে চলতে হবে।
          </span>
        </p>
        <p className="p80 ft7">
          <span className="ft1">4.5.4।</span> খাদ্য খরচ (<b>বাণিজ্য-পরিষেবা</b>{" "}
          প্রদানকারী
          <span className="ft36">দ্বারা বহন করা হবে</span> )। যদি ভাড়াকারী কোনো
          খাবারের সুবিধা প্রদান করে তাহলে উভয় পক্ষ পারস্পরিকভাবে
          <b>অফার-মূল্য</b> থেকে বাদ দিতে সম্মত হতে পারে
        </p>
      </div>
      <div id="page_10">
        <p className="p85 ft7">
          {" "}
          খাদ্য, বিধান, রাঁধুনি,
          <b>রান্নার গ্যাস</b> এবং গ্যাসের চুলা, ইত্যাদি। খাদ্য বিধানের জন্য এই
          ধরনের খরচ গ্রহণ বা প্রত্যাখ্যান করার একমাত্র অধিকার বিক্রেতার রয়েছে।
        </p>
        <p className="p44 ft7">
          <span className="ft1">4.5.5।</span>
          <span className="ft36">
            কাজের জায়গার সাধারণ হাউসকিপিং এবং কর্মস্থলের 100 মিটারের মধ্যে
            অবস্থানে ধ্বংসাবশেষ সংগ্রহ এবং স্তুপ করা
          </span>
          <b>;</b> কিন্তু কোন বিন্দু অতিক্রম ধ্বংসাবশেষ নিষ্পত্তি না.
        </p>
        <p className="p69 ft6">
          <span className="ft1">4.5.6।</span>
          <span className="ft41">
            গ্রাউন্ড ফ্লোর লেভেলে কাজ করা +/- 4 ফ্লোর/লেভেল। নিম্ন এবং উচ্চ
            স্তরের কাজগুলি ব্যবহারকারীদের দ্বারা পারস্পরিক সম্মত হতে পারে।
          </span>
        </p>
        <p className="p29 ft6">
          <span className="ft1">4.6।</span>
          <span className="ft17">
            ব্যবহারকারী সম্মত হন যে তার হারের ভিত্তি কাজ করা এবং অর্থপ্রদানের
            হিসাব করার জন্য নিম্নলিখিত পদ্ধতির উপর ভিত্তি করে। যেকোনো বিচ্যুতি
            পারস্পরিকভাবে সম্মত হতে পারে তবে ট্রেড-সেবা প্রদানকারীর নিজস্ব
            বিবেচনার ভিত্তিতে।
          </span>
        </p>
        <p className="p81 ft7">
          <span className="ft1">4.6.1।</span>
          <span className="ft27">
            অর্থপ্রদানের পরিমাপের একক হবে প্ল্যাটফর্মে নির্দিষ্ট করা পরিমাপের
            একক এবং
          </span>
          <b>ই-চুক্তিতেও এগিয়ে নিয়ে যাওয়া হবে।</b>
        </p>
        <p className="p70 ft6">
          <span className="ft1">4.6.2।</span> পরিমাপ করা কাজের পরিমাপ নির্দিষ্ট
          <b>ট্রেড-পরিষেবার</b> পরিমাপের
          <span className="ft41">
            জন্য সংশ্লিষ্ট IS কোড অফ প্র্যাকটিস অনুযায়ী হওয়া উচিত
          </span>{" "}
          - কোনও স্থানীয় অনুশীলন সত্ত্বেও (যদি না উভয় পক্ষের দ্বারা পারস্পরিক
          সম্মত হয়)। পরিমাপ করা হবে যখন উভয় পক্ষ উপস্থিত থাকে এবং যত
          তাড়াতাড়ি ব্যবহারিক হয়, তবে কাজের সংজ্ঞায়িত অংশগুলি শেষ হওয়ার পরে
          নয়।
        </p>
        <p className="p72 ft6">
          <span className="ft1">4.6.3।</span>
          <span className="ft41">
            কাজের পরিমাণের পরিমাপ উভয় পক্ষের প্রতিনিধিদের দ্বারা যৌথভাবে করা
            হবে এবং সম্মত হবে। পরিমাপ, যেমন আগে উল্লেখ করা হয়েছে, উপযুক্ত
            আইএস-এ সেট করা নীতিগুলি মেনে চলতে হবে।
          </span>
        </p>
        <p className="p46 ft7">
          <span className="ft1">4.6.4।</span> সম্পন্ন কাজের জন্য
          <b>ট্রেড-পরিষেবা</b> প্রদানকারীর জন্য
          <span className="ft36">অর্থপ্রদান</span> সম্মত হারের পণ্য হিসাবে গণনা করা
          হবে এবং পরিমাপের একক (প্রতিটি বাণিজ্য কার্যকলাপের জন্য।)
        </p>
        <p className="p12 ft7">
          <span className="ft1">4.7।</span>
          <span className="ft22">
            বাণিজ্য সেবা প্রদানের জন্য কাজের ন্যূনতম সময়কাল হবে এক মাস। এই
            ন্যূনতম কর্মসংস্থান সময়ের উপর ভিত্তি করে হার হবে। যদি একজন
            নিয়োগকর্তা/ক্রয়কারী/ভাড়াদার
          </span>
          <b>ট্রেড-পরিষেবার</b> এক মাসের পরিষেবার সময়কাল অফার করতে না পারেন,
          তাহলে তাকে
          <b>ট্রেড-সার্ভিস</b> প্রোভাইডারকে সেই ব্যালেন্স সময়ের জন্য ক্ষতিপূরণ
          দিতে হবে যার জন্য
          <b>সার্ভিস-ট্রেড</b> প্রোভাইডার নিষ্ক্রিয়। এই ধরনের অর্থপ্রদানের গণনা
          নিষ্ক্রিয় কাজের জন্য অর্থপ্রদানের অধীনে উল্লেখ করা হবে। এই দামের যে
          কোনো পরিবর্তন পারস্পরিকভাবে সম্মত হতে পারে।
        </p>
        <p className="p86 ft7">
          <span className="ft1">4.8।</span>
          <span className="ft18">
            যদি পরিষেবা প্রদানকারী কাজের জন্য সংস্থান স্থাপন করতে অক্ষম হয়,
            (কোনও কারণে পরিষেবা প্রদানকারীর জন্য দায়ী নয়),
            নিয়োগকর্তা/ক্রয়কারী/ভাড়াদারকে অলস সময়ের জন্য পরিষেবা
            প্রদানকারীকে ফেরত দিতে হবে। অলস সময়ের জন্য অর্থপ্রদানের পরিমাণ
            (প্রতিদিনের সম্পদ প্রতি) গড় দৈনিক উৎপাদনশীলতার দ্বারা গুণিত হার
            দ্বারা নির্ধারিত হয় (নিজস্ব IS কোড অফ প্র্যাকটিস বা CPWD নিয়ম বা
            আদর্শ শিল্প অনুশীলন অনুসারে (যেকোন পরিবর্তন পারস্পরিকভাবে সম্মত হতে
            পারে) পরিষেবা প্রদানকারীকে প্রদান করা সম্পদের সংখ্যার জন্য অর্থ
            পরিশোধ করতে হবে যারা নিষ্ক্রিয় থাকে এবং সেই সময়কালের জন্য তারা
            নিষ্ক্রিয় থাকে।
          </span>
        </p>
        <p className="p87 ft7">
          <span className="ft1">4.9।</span>
          <span className="ft18">
            ব্যবহারকারী নিশ্চিত করে যে ব্যবহৃত হার নিম্নলিখিত অর্থপ্রদানের
            শর্তাবলীর উপর ভিত্তি করে।
          </span>
        </p>
        <p className="p70 ft6">
          <span className="ft1">4.9.1।</span>
          <span className="ft28">
            বেতনের অর্থ প্রদান পরিষেবা প্রদানকারীকে ভাড়াকারী দ্বারা করা হবে -
            যারা একজন ব্যক্তি, ব্যক্তিদের একটি গোষ্ঠী বা একজন ঠিকাদার হতে পারে।
            অর্থপ্রদান স্থানান্তরের মোড পারস্পরিক সম্মত হতে পারে।
          </span>
        </p>
        <p className="p10 ft7">
          <span className="ft1">4.9.2।</span>
          <span className="ft36">
            কাজের জন্য অর্থ প্রদান একাধিক পর্যায়ে করা হবে।
          </span>
        </p>
        <p className="p70 ft7">
          <span className="ft1">4.9.3।</span>
          <span className="ft36">এর মধ্যে একটি</span>
          <b>এককালীন</b> অর্থপ্রদান অন্তর্ভুক্ত থাকবে, কর্মক্ষেত্রে রিপোর্ট করার
          জন্য প্রতিশোধ হিসাবে,
          <b>ট্রেড-পরিষেবা</b> প্রদানকারীর বর্তমান অবস্থান থেকে কর্মসংস্থানের
          জায়গায় ভ্রমণের খরচ কভার করার জন্য – খরচে, একটি বৈধ ভ্রমণ নথি তৈরি
          করা। এই পেমেন্টটি করা কাজের জন্য প্রথম পেমেন্ট থেকে কাটা যেতে পারে।
        </p>
        <p className="p82 ft6">
          <span className="ft1">4.9.4।</span>
          <span className="ft41">
            পর্যায়ক্রমিক (দৈনিক, সাপ্তাহিক, পাক্ষিক, মাসিক) জীবনযাত্রার খরচ
            কভার করার জন্য অগ্রিম অর্থ প্রদান। এই পরিমাণ সময়কালের জীবনযাত্রার
            ব্যয়গুলিকে কভার করার জন্য যার জন্য মজুরি পরিশোধের জন্য বকেয়া হয়
            না। এটি সংশ্লিষ্ট সময়ের জন্য মজুরি থেকে সেট অফ/পুনরুদ্ধার করতে হবে।
          </span>{" "}
          নিয়মিত মজুরির সময়কালের উপর ভিত্তি করে উভয় পক্ষের দ্বারা
          পারস্পরিকভাবে সম্মত হওয়া
          <span className="ft41">এই ধরনের পর্যায়ক্রমিক</span>
          <b>খরচ-অফ-লিভিং</b> পেমেন্টের পরিমাণ এবং সময়কাল।
        </p>
      </div>
      <div id="page_11">
        <p className="p88 ft6">
          <span className="ft1">4.9.5।</span>
          <span className="ft41">
            পর্যায়ক্রমিক মজুরি (সাপ্তাহিক, পাক্ষিক, মাসিক) মেয়াদ শেষ হওয়ার 7
            তম দিনের মধ্যে সর্বশেষে পরিশোধ করতে হবে (চুক্তির সমাপ্তি বা সমাপ্তি
            ছাড়া, যখন চুক্তি বন্ধ হওয়ার 2 দিনের মধ্যে অর্থ প্রদান করতে হবে) -
            অথবা সংবিধিবদ্ধ কর্তৃপক্ষ দ্বারা সময়ে সময়ে নির্দিষ্ট করা হয়েছে।
          </span>
        </p>
        <p className="p89 ft6">
          <span className="ft1">4.9.6।</span>
          <span className="ft41">
            পর্যায়ক্রমিক মজুরি নির্ধারণ করা হয় (a) সময়ের মধ্যে করা কাজের
            পরিমাণ এবং (b) অ্যাপ প্ল্যাটফর্মে সম্মত এবং দুই পক্ষের মধ্যে
            চুক্তিবদ্ধ কাজের প্রতি ইউনিটের হার।
          </span>
        </p>
        <p className="p89 ft6">
          <span className="ft1">4.9.7।</span>
          <span className="ft43">
            যে কোনো পরিষেবা যা IS কোড অফ প্র্যাক্টিসের গুণমানের
            প্রয়োজনীয়তাগুলি পূরণ করে না সেগুলি পরিমাপ থেকে বাদ দেওয়া যেতে
            পারে যতক্ষণ না এই ধরনের ত্রুটিগুলি সংশোধন করা হয় এবং তারপরে পরিমাপ
            এবং অর্থপ্রদানের জন্য যোগ্য হয়৷ এটি এই শর্ত সাপেক্ষে যে এই ধরনের
            ত্রুটিগুলি সংশোধন করার জন্য ত্রুটিগুলির স্পষ্ট এবং উদ্দেশ্যমূলক
            রেকর্ড সহ পরিষেবা প্রদানকারীকে অবহিত করা হয়।
          </span>
        </p>
        <p className="p90 ft7">
          <span className="ft1">4.9.8।</span>
          <span className="ft36">
            মজুরি থেকে একমাত্র কর্তন হল সুপ্ত ত্রুটির জন্য ধরে রাখা যা ত্রুটির
            দায় সময়ের মধ্যে প্রদর্শিত হতে পারে -
          </span>
          <b>অফার-মূল্যে</b> বিবেচিত এই ত্রুটির দায় সময়কালটি প্রকল্প সমাপ্তির
          তারিখ থেকে এক বছর। (প্রকল্পের সমাপ্তি নিম্নলিখিতগুলির যেকোন একটির
          প্রথম ঘটনার তারিখ হিসাবে গণ্য করা হবে - (ক) প্রকল্পের মালিক কর্তৃক
          সমাপ্তির শংসাপত্র ইস্যু করা (খ) সংবিধিবদ্ধ সংস্থা কর্তৃক জারি করা পেশা
          শংসাপত্রের প্রাপ্তি (গ) এর আংশিক দখল প্রকল্প (d) প্রকল্পটি তার
          উদ্দিষ্ট উদ্দেশ্যে প্রস্তুত এবং বা (ঙ) প্রকল্পটি উপকারী পেশার পর্যায়ে
          পৌঁছেছে।
        </p>
        <p className="p91 ft6">
          <span className="ft1">4.9.9।</span>
          <span className="ft41">
            ধরে রাখার জন্য সর্বোচ্চ ছাড় (গুণমান যাচাইকরণ এবং ত্রুটিগুলির
            রক্ষণাবেক্ষণ মুলতুবি) গণনাকৃত মজুরির 5% এর বেশি হতে পারে না। এই
            ধরনের ধারণ প্রকল্পের সমাপ্তির এক বছরের মধ্যে পরিশোধ করতে হবে (উপরে
            উল্লিখিত হিসাবে গণনা করা হবে)। ত্রুটিগুলি সংশোধন করার জন্য যে কোনও
            খরচ পুনরুদ্ধার শুধুমাত্র তখনই করা যেতে পারে যখন পরিষেবা প্রদানকারীকে
            ত্রুটি সম্পর্কে যথাযথভাবে অবহিত করা হয় এবং এটি সংশোধন করার জন্য
            একটি সুযোগ এবং যুক্তিসঙ্গত সময় দেওয়া হয় (ন্যূনতম দুই সপ্তাহে)।
          </span>
        </p>
      </div>
    </div>
  );
};

export default CommonTermsBengali;
