import React from "react";
import CommonHeader from "../commonheaders/CommonHeader";
import './PrivacyPolicy.css'

/**
 * Privacy Policy Page in english language
 * @param {*} props 
 * @returns 
*/
const PrivacyPolicy = (props) => {
    return (
        <div className="privacy-container">
            <CommonHeader
                imageUrl="https://onesourcekarmayoga.com/wp-content/uploads/2021/05/vision_01_a-1.jpg"
                headerText={"Privacy & Cookies"}
            />
            <div className="privacy-content-container">
                <h1>Privacy Policy</h1>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer" >www.onesourcekarmayoga.com</a> is an company, operating its business through the use of the internet, the World Wide Web and the digital world. Onesource Karma Yoga Pvt Ltd,, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">www.onesourcekarmayoga.com</a> and <a href="https://www.karmayoga.app/" target="_blank" rel="noreferrer">karmayoga.app</a> are used interchangeably and together represent the company, its website and its web application. Any reference to one may be referenced to the others and vice-versa and are therefore to be considered accordingly.</p>
                <p>Please refer to the article listed below to review our privacy and ethical policy. Kindly note that whenever referred to “us, we, our” or any other references from the like, this means that we are talking about <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">“http://www.onesourcekarmayoga.com”</a> and whenever “you, your, customer” is stated, then we are talking about “you as a customer” and as the second party.</p>
                <p>By visiting our website, you accept the practices conducted as described in the Privacy Policy section here in.</p>
                <p>Availing yourself to our products will always be safe and secure.  Our privacy policy is stringently enforced so your information is always kept completely confidential. We use different safeguards techniques to assist us in preventing unauthorised access and maintaining data accuracy, including your personal information.</p>
                <p>Except that, we use some of the contact information such as name, physical address, telephone number, email address, and information volunteered by the consumer, such as survey information and/or site registrations for the business need. In fact, the information we collect is used to improve the service of our application, to notify consumers about updates to our application, and for keeping a record for the promotions and the company’s announcements.</p>
                <p>If you do not want to receive any communication from us in the form of emails, SMS, WhatsApp Messages, etc., in the future, please let us know by sending us e-mail to the email address available at the Contact Us section.</p>
                <p>With respect to security, we have appropriate security measures in place in our physical <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> facilities to protect against the loss, misuse or alteration of information that we have collected from you on our application. In fact, SSL certified and it promises secure and safe financial and information transactions. Additionally, we might collect information about your pattern of use of our website <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a>, through the application of “cookies” – which are pieces of data assigned by our web server that uniquely identify the browser on your machine.</p>
                <p>However, you have the option to disable the cookies anytime through your browser, but then you may not be able to access certain features on our website.</p>
                <p>We are committed to protecting your privacy. Any information collected from your side is for pure business enhancement and customer service; to achieve a great experience, and to help us understand our market even better.</p>
                <p>Personal Information</p>
                <p>This policy covers the following personal information <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a>  collects through your use of <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> and in connection with off-line communications. Specifically, Onesource Karmayoga Pvt. Ltd., may collect this information from offline and online registrations, forms and communications and interactions, and sales inquiries and transactions.</p>
                <p>Contact information, such as name, physical address, telephone number, email address, company, GSTIN, PAN, Aadhaar number, etc... The majority of the information collected under this category is business contact and verification information. These will remain confidential except where specifically required to be used in enabling the completion of a transaction or where required by the Laws of the country.</p>
                <p>Social media information, such as social media handles, content and other data shared with us through third-party features that you use on our Services (such as apps, tools, payment services, widgets and plugins offered by social media services like Facebook, Google , Instagram, LinkedIn, Pinterest, Twitter and YouTube) or posted on social media pages (such as our social media pages or other pages accessible to us).</p>
                <p>Transactional information, including information about the products and services you request, purchase or download.</p>
                <p>Financial/credit card and payment information, event and conference participation information, booths attended or visited during an event or conference hosted or attended by OneSource Karma Yoga Pvt Ltd. If we use electronic tracking technologies at an event, we will provide notice in the event registration materials and/or correspondence.</p>
                <p>Electronic Communications Protocols and Cookies: In addition to the information specified above, OneSource Karma Yoga Pvt. Ltd. may collect information about your use of the website <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> or the application <a href="https://www.karmayoga.app/" target="_blank" rel="noreferrer">karmayoga.app</a> through electronic communications protocols and cookies.</p>
                <p>As is true when you visit most Web sites, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may automatically receive personal information from you as part of the communication connection itself, which often consists of network routing information (where you came from), equipment information (browser type), and date and time. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> also uses cookies, embedded URLs and embedded pixels to capture additional information.</p>
                <p>Cookies: <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> server will query your computer to see if there are “cookies” previously set by the <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> Web sites. A cookie is a small piece of information sent by a Web server to store on a Web browser so it can later be read back from that browser.</p>
                <p>Cookies on the Web sites may collect a unique identifier, user preferences and profile information and membership information. www.phdnutrition.ae also uses cookies to collect general usage and volume statistical information (which does not include personally identifiable information).</p>
                <p>Some cookies may remain on the user’s computer after they leave the Web site, and the majority are set to expire within 365 days. While we do not offer an opt-out for cookies, browsers provide you information and control over cookies. You can set your Web browser to alert you when a cookie is being used and accept or reject the cookie. You can also set your browser to refuse all cookies or accept only cookies returned to the originating servers.</p>
                <p>Users can generally disable the cookie feature on their browser without affecting their ability to use the site, except in some cases where cookies are used as an essential security feature necessary for transaction completion.</p>
                <p>Embedded URLs: <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> also uses a tracking technique that employs embedded URLs to allow use of the site without cookies. Embedded URLs allow limited information to follow you as you navigate the site but is not associated with personal information and is not used beyond the session.</p>
                <p>Embedded Pixels: On the Web site, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may use embedded pixel technologies on selected pages for the purposes of identifying unique user visits to the <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> as opposed to aggregate hits. In addition, these technologies are used in emails to provide information on when the email was opened to track marketing campaign responsiveness.</p>
                <p>Third Party Cookies: <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> has engaged a third party, to track and analyse usage and volume statistical information (which does not include personal information) from visitors to the website, which sets cookies on behalf of <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> and also uses other third-party cookies to track the performance of <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> advertisements on their websites.</p>
                <p>The information provided by these third parties does not include personal information but may be re-associated with personal information once <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> receives it.</p>
                <p></p>
                <p>USE AND SHARING OF PERSONAL INFORMATION</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> uses (and, where specified, shares) your personal information for the following purposes:</p>
                <p>– To engage in transactions. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may use personal information such as name, physical address, telephone number, email address, and company to engage in transactions with you, including contacting you about your order.</p>
                <p>– To process transactions. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may use financial/credit card and payment information to process your order. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may need to share some of this information with delivery services; credit card clearing houses and other similarly situated third parties to complete the transaction.</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> will never save or share your credit card information and will always use the highest security to encrypt your information.</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> will never save (except in a hashed format) or share your Aadhar information and will always use the highest security to encrypt your information.</p>
                <p>– To provide support or other services. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may use your personal information to provide you support or other services you have ordered, as well as product updates, product patches and fixes and other similar communications.</p>
                <p>– To tailor marketing to your needs and respond to your requests. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may use information from your order, your <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> profile, or other specified requirements to provide you with notices of new product releases and service developments. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> also may use your personal information to respond directly to your requests for information, including registrations for newsletters or other specific requests, or pass your contact information to the appropriate <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> distributor or reseller to respond to your request.</p>
                <p>In addition, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may ask if you wish to have your contact information shared with other selected partners that offer complementary products or services. If you agree, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> will provide your contact information to those partners. Please review the specific partner’s privacy policy regarding any further interactions with them.</p>
                <p>– To provide online forums. Some services available on the Web sites permit you to participate in interactive discussions or post comments or opportunities to a bulletin board or exchange. Some of these services are moderated; all may be accessed for technical reasons. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> does not control the content that users post to these forums.</p>
                <p>You should carefully consider whether you wish to submit personal information to these forums and should tailor any content you submit appropriately and in accordance with the relevant terms of use.</p>
                <p>– To select content, improve quality and facilitate use of the Web Sites, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may use your personal information, including the information gathered as a result of site navigation and electronic protocols and cookies (including third party cookies), to help create and personalize Web site content, facilitate your use of the Web sites (for example, to facilitate navigation and the login process, avoid duplicate data entry, enhance security, keep track of shopping cart additions and preserve order information between sessions), improve Web site quality, track marketing campaign responsiveness, and evaluate page response rates.</p>
                <p>– To provide various <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> communities, such as partners, with relevant product alerts and updates. If you sign up for a <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> community <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> will also provide you information in which you have expressed an interest related to new product releases, alerts, updates, prices, terms, special offers and associated campaigns.</p>
                <p>– To power joint sales or product promotions. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> and its partners may engage in joint sales or product promotions. Such promotions will always reference the partners involved. Both <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> and the partner(s) will have access to that information, and either www.phdnutrition.ae or our partners may provide you the sales or product promotion information. We recommend you review the privacy policies of these partners to address your particular needs and concerns.</p>
                <p>– To contact a referral/”tell a friend”. If you elect to use our referral service to inform a friend about our site, we will ask you for the friend’s name and email address. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> will automatically send the friend a one-time email inviting them to visit the site. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> uses this personal information for the sole purpose of sending this one-time email and tracking the success of our referral program. The friend may contact <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> at info@onesourcekarmayoga.com to be removed from the referral program.</p>
                <p>– To communicate with you about a conference or event. We or our partners may communicate with you about a conference or event hosted or co-sponsored by <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> or one of our partners. This may include information about the event’s content, event logistics, payment, updates, and any additional meetings, special demonstrations or other customer facilitation that we may provide related to the event.</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may contact you after the event about the event and related products and services. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> will also permit designated partners or conference sponsors to send you up to two communications through the services of a third-party bonded warehouse.</p>
                <p>Please note that our partners or conference sponsors may directly request your personal information at their conference booths or presentations; however, providing your information is optional, and you should review their privacy policies to address your needs and concerns.</p>
                <p>– To comply with legal requirements. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may be required to disclose your personal information in order to comply with legally mandated reporting, disclosure or other legal process requirements. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> will use reasonable efforts to limit the personal information it provides for this purpose.</p>
                <p>Please note that your personal information may be transferred, accessed and stored globally as necessary for the uses stated above in accordance with this policy.</p>
                <p>REGISTRATION, CUSTOMIZATION, PREFERENCES AND OPT-OUT</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> allows you to establish an online registration profile for marketing communications at <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> You may use the profile to customize and control your preferences for receiving electronic information, including opting into (or out of) communities.</p>
                <p>You can return to the registration profile to customize it at any time to change your elections or modify your profile at <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> Please login as usual and follow the prompts to “update my profile.”</p>
                <p>Opting Out. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> enables you to opt out of some or all email marketing through an unsubscribe link. A link to this will be available in marketing emails. You can also unsubscribe to all email marketing by sending an email through our Contact Us page.</p>
                <p>Information-Sharing Choices. <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> only shares personal information with selected companies after providing users the ability to either opt out of or opt into the sharing.</p>
                <p>There are limitations to opting out of such sharing in the case of (1) joint sale or joint events where the data goes to both <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> and the other party at the same time; (2) where the customer has requested follow up which involves <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> partners; and (3) where third parties are required to accomplish the transaction or perform the service, including delivery and credit card clearing services.</p>
                <p>Third Party Permission-Based Lists.</p>
                <p>On occasion, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> may use a third-party permission-based list to send communications about <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> products and services. These lists are maintained by third parties whose users have consented to receive emails from other companies, such as <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a></p>
                <p>We do not have access to the names and contact information on these lists. We require the list owners to identify themselves as the mailers of the email and to provide appropriate opt-out mechanisms. If you wish to be removed from a third-party list, you should direct your opt-out request to the list owner, not to <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a></p>
                <p>If you have trouble opting out, or if you wish to make us aware of any issues related to a third-party mailing, you may reach us directly through our contact us page located. When third-party mailings are sent with an <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> reply address, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> also will provide a notice with information about how to opt-out of future <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> marketing communications.</p>
                <p>Advertising</p>
                <p><a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> uses some third parties to administer a limited set of <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> advertisements on its Web sites. No personal information is provided to the advertisers as part of this process, but aggregate profile information, such as user community, may be used in the selection of advertising to make sure that it has relevance to the user.</p>
                <p>On some banner ads, an embedded pixel may be present, and while it does not associate with a cookie or other personal profile information, it may return session connection information that allows advertisers to better determine how many individual users have clicked on the ad banner.</p>
                <p>Security</p>
                <p>The security of your personal information is very important to <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> We have in place physical, administrative and technical safeguards designed to protect your personal information from unauthorized access. In addition, <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> uses the highest security protocols and mechanisms to exchange the transmission of sensitive data such as credit card details.</p>
                <p>Compliance Issues</p>
                <p>If you believe your personal information has been used in a way that is not consistent with this policy or your specified preferences, or if you have further questions related to this policy, please contact us by email through the Contact Us page.</p>
                <p>Policy Updates</p>
                <p>We may update this policy from time to time. We will post a prominent notice in this section of this policy notifying users when it is updated. If we are going to use your personal information in a manner different from that stated at the time of collection, we will notify you via email, and you will have a choice as to whether or not we use your personal information in this different manner.</p>
                <p>We appreciate your comments on <a href="https://www.onesourcekarmayoga.com/" target="_blank" rel="noreferrer">http://www.onesourcekarmayoga.com</a> privacy practices. All e-mail inquiries can be made through our Contact Us page.</p>
            </div>
        </div>
    )
}

export default PrivacyPolicy