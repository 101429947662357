export const registerTour=[
    {
        selector: ".register-input-tour",
        content:()=>( <div>
           {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Enter your 10 digit mobile number</h4>: localStorage.getItem('language')=='hi'? <h4>अपना 10 अंकों का मोबाइल नंबर दर्ज करें</h4>: localStorage.getItem('language')=='bn'?<h4>আপনার 10 সংখ্যার মোবাইল নম্বর লিখুন</h4>:<h4>உங்கள் 10 இலக்க மொபைல் எண்ணை உள்ளிடவும்</h4>
        } 
        </div>),
        style:{
          backgroundColor:"#ff8d1a",
          color:"white",
          paddingTop:'30px'
        }
    },
    {
      selector: ".register-form-button",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Forgot your Access Code? Click here to get a verification code, access the app and reset your Access Code by going to the profile section from the menu bar.</h4>: localStorage.getItem('language')=='hi'? <h4>अपना एक्सेस कोड भूल गए? सत्यापन कोड प्राप्त करने के लिए यहां क्लिक करें, ऐप तक पहुंचें और मेनू बार से प्रोफ़ाइल अनुभाग में जाकर अपना एक्सेस कोड रीसेट करें।</h4>: localStorage.getItem('language')=='bn'?<h4>আপনার অ্যাক্সেস কোড ভুলে গেছেন? একটি যাচাইকরণ কোড পেতে এখানে ক্লিক করুন, অ্যাপটি অ্যাক্সেস করুন এবং মেনু বার থেকে প্রোফাইল বিভাগে গিয়ে আপনার অ্যাক্সেস কোড রিসেট করুন৷</h4>:<h4>உங்கள் அணுகல் குறியீட்டை மறந்துவிட்டீர்களா? சரிபார்ப்புக் குறியீட்டைப் பெற இங்கே கிளிக் செய்யவும், பயன்பாட்டை அணுகவும் மற்றும் மெனு பட்டியில் இருந்து சுயவிவரப் பகுதிக்குச் சென்று உங்கள் அணுகல் குறியீட்டை மீட்டமைக்கவும்.</h4>
        } 
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    },
    {
      selector: ".goBack-toLogin-tour",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)? <h4>Click here if you want to go back to the login screen and return with a different phone number. </h4>: localStorage.getItem('language')=='hi'? <h4>अगर आप लॉग इन स्क्रीन पर वापस जाना चाहते हैं और एक अलग फोन नंबर के साथ वापस जाना चाहते हैं तो यहां क्लिक करें।</h4>: localStorage.getItem('language')=='bn'?<h4>আপনি যদি লগইন স্ক্রিনে ফিরে যেতে চান এবং একটি ভিন্ন ফোন নম্বর দিয়ে ফিরতে চান তাহলে এখানে ক্লিক করুন। </h4>:<h4>நீங்கள் உள்நுழைவுத் திரைக்குச் சென்று வேறு தொலைபேசி எண்ணுடன் திரும்ப விரும்பினால் இங்கே கிளிக் செய்யவும்.</h4>
        } 
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    }
]