import { Button, Modal, Radio } from "antd";
import React, { useState } from "react";
import Tour from "reactour";
import { createSelector } from "reselect";
import { tourConfirmPopup } from "../../utils/tour_guide/tourConfirmation";
import "./TourConfirmation.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loginTour } from "../../utils/tour_guide/login/login";
import { settourGuide, setTutorialLink } from "../../redux-core/help/actions";
import { makeSetTourPopup } from "../../redux-core/help/selectors";
import { useEffect } from "react";
import { ActionTypes } from "../../redux-core/help/constants";
import { LANGUAGES } from "../../utils/languages";

const loginDispatch = (dispatch) => ({
  setTourGuide: (actiontype, payload) =>
    dispatch(settourGuide(actiontype, payload)),
});

const tourPopupSelector = createSelector(makeSetTourPopup, (tourPopup) => ({
  tourPopup,
}));

const TourConfirmation = (props) => {
  const { t, i18n } = useTranslation();

  const { tourPopup } = useSelector(tourPopupSelector);
  const { setTourGuide } = loginDispatch(useDispatch());
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectedKey, SetKey] = useState(localStorage.getItem("language"));

  const takeATourOk = () => {
    setTourGuide(ActionTypes.SET_LOGIN_GUIDE, true);
    setTourGuide(ActionTypes.SET_LOGINACCESS_GUIDE, true);
    setTourGuide(ActionTypes.SET_REGISTER_GUIDE, true);
    setTourGuide(ActionTypes.SET_VERIFY_REGISTER_GUIDE, true);
    setTourGuide(ActionTypes.SET_MPIN_GUIDE, true);
    setTourGuide(ActionTypes.SET_CATEGORY_GUIDE, true);
    setTourGuide(ActionTypes.SET_REQUIREMENT_GUIDE, true);
    setTourGuide(ActionTypes.SET_REQUIREMENT_APPLY_GUIDE, true);
    setTourGuide(ActionTypes.SET_REQUIREMENT_RECRUIT_GUIDE, true);
    setTourGuide(ActionTypes.SET_PROFILE_GUIDE, true);
    setTourGuide(ActionTypes.SET_EDITPROFILE_GUIDE, true);
    setTourGuide(ActionTypes.SET_MATCH_GUIDE, true);
    setTourGuide(ActionTypes.SET_EDITMATCH_GUIDE, true);
    setTourGuide(ActionTypes.SET_DASHBOARD_GUIDE, true);
    setTourGuide(ActionTypes.SET_DASHBOARD_COMPLETED_GUIDE, true);
    setTourGuide(ActionTypes.SET_TERMS_GUIDE, true);
    setTourGuide(ActionTypes.SET_USER_REGISTRATION_GUIDE, true);
    setTourGuide(ActionTypes.SET_HELP_GUIDE, true);
    setTourGuide(ActionTypes.SET_TOUR_POPUP, false);
  };

  const takeATourCancel = () => {
    setTourGuide(ActionTypes.SET_LOGIN_GUIDE, false);
    setTourGuide(ActionTypes.SET_TOUR_POPUP, false);
    setTourGuide(ActionTypes.SET_LOGINACCESS_GUIDE, false);
    setTourGuide(ActionTypes.SET_REGISTER_GUIDE, false);
    setTourGuide(ActionTypes.SET_VERIFY_REGISTER_GUIDE, false);
    setTourGuide(ActionTypes.SET_MPIN_GUIDE, false);
    setTourGuide(ActionTypes.SET_CATEGORY_GUIDE, false);
    setTourGuide(ActionTypes.SET_REQUIREMENT_GUIDE, false);
    setTourGuide(ActionTypes.SET_REQUIREMENT_APPLY_GUIDE, false);
    setTourGuide(ActionTypes.SET_REQUIREMENT_RECRUIT_GUIDE, false);
    setTourGuide(ActionTypes.SET_PROFILE_GUIDE, false);
    setTourGuide(ActionTypes.SET_EDITPROFILE_GUIDE, false);
    setTourGuide(ActionTypes.SET_MATCH_GUIDE, false);
    setTourGuide(ActionTypes.SET_EDITMATCH_GUIDE, false);
    setTourGuide(ActionTypes.SET_DASHBOARD_GUIDE, false);
    setTourGuide(ActionTypes.SET_DASHBOARD_COMPLETED_GUIDE, false);
    setTourGuide(ActionTypes.SET_TERMS_GUIDE, false);
    setTourGuide(ActionTypes.SET_USER_REGISTRATION_GUIDE, false);
    setTourGuide(ActionTypes.SET_HELP_GUIDE, false);
  };

  //Creating a method to change the language onChange from select box
  const changeLanguageHandler = (e) => {
    i18n.changeLanguage(e);
  };

  const onSelectMenu = (e) => {
    setShowConfirmPopup(true);
    SetKey(e.target.value);
    localStorage.setItem("language", e.target.value);
    changeLanguageHandler(e.target.value);
    props.updateLanguageState(e.target.value);
  };

  const handleLanguageButton=()=>{
    setShowConfirmPopup(true);
  }

  return (
    <div>
      <Modal
        className="tour-confirm"
        centered
        closable={false}
        visible={tourPopup}
        footer={null}
      >
        {showConfirmPopup == false ?
          <>
            <h4 className="lang-btn">{t("select_your_language")}</h4>
            <div className="lang-btn">
              <Radio.Group
                onChange={(e) => onSelectMenu(e)}
                defaultValue={selectedKey}
              >
                {LANGUAGES.map((item, i) => {
                  return (
                    <Radio.Button className="radio-btn" onClick={handleLanguageButton} value={item.code} key={i}>
                      {item.language}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </div>
          </>:
          <>
          <h4 className="lang-btn">{t("do_you_want_to_take_a_tour")}</h4>
          <div className="lang-btn">
            <Button className="takeTourBtn" onClick={takeATourCancel} key="1">
              {t("no_thanks")}
            </Button>
            <Button onClick={takeATourOk} key="3">
              {t("take_tour")}
            </Button>
          </div>
        </>
        }

        {/* // {showConfirmPopup && (
          <>
            <h4 className="lang-btn">{t("do_you_want_to_take_a_tour")}</h4>
            <div className="lang-btn">
              <Button onClick={takeATourCancel} key="1">
                {t("cancel")}
              </Button>
              <Button onClick={takeATourOk} key="3">
                {t("take_tour")}
              </Button>
            </div>
          </>
        )} */}
      </Modal>
    </div>
  );
};

export default TourConfirmation;
