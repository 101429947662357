export const dashBoardTour=[
  {
    selector: ".dashboard-pending-tour",
    content:()=>( <div>
      {
        (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Listed here are all your recruitment and application orders awaiting a match. </h4>: localStorage.getItem('language')=='hi'? <h4>यहां सूचीबद्ध आपकी सभी भर्ती और आवेदन आदेश एक मैच की प्रतीक्षा कर रहे हैं</h4>: localStorage.getItem('language')=='bn'?<h4>একটি ম্যাচের অপেক্ষায় থাকা আপনার নিয়োগ এবং আবেদনের আদেশগুলি এখানে তালিকাভুক্ত করা হয়েছে। </h4>:<h4>உங்கள் ஆட்சேர்ப்பு மற்றும் போட்டிக்கான விண்ணப்ப ஆர்டர்கள் அனைத்தும் இங்கே பட்டியலிடப்பட்டுள்ளன. </h4>
      }
    </div>),
    style:{
      backgroundColor:"#ff8d1a",
      color:"white",
      paddingTop:'30px'
    }
  },
  {
      selector: ".dashboard-completed-tour",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Listed here are all your recruitment and application orders which have found a matching counterparty. </h4>: localStorage.getItem('language')=='hi'? <h4>आपकी सभी भर्ती और आवेदन आदेश यहां सूचीबद्ध हैं जिन्हें एक समान प्रतिपक्ष पाया गया है</h4>: localStorage.getItem('language')=='bn'?<h4>এখানে আপনার সমস্ত নিয়োগ এবং আবেদনের আদেশগুলি তালিকাভুক্ত করা হয়েছে যা একটি মিলে যাওয়া প্রতিপক্ষ খুঁজে পেয়েছে৷ </h4>:<h4>உங்கள் ஆட்சேர்ப்பு மற்றும் விண்ணப்ப ஆர்டர்கள் அனைத்தும் இங்கே பட்டியலிடப்பட்டுள்ளன, அவை பொருந்தக்கூடிய எதிர் தரப்பைக் கண்டறிந்துள்ளன. </h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
  },
  {
    "selector": ".dashboard-view-tour",
    content:()=>( <div>
      {
        (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Click to lead you to the matching page where you can check the status of your order.</h4>: localStorage.getItem('language')=='hi'? <h4>मिलान पृष्ठ पर ले जाने के लिए क्लिक करें जहां आप अपने आदेश की स्थिति की जांच कर सकते हैं</h4>: localStorage.getItem('language')=='bn'?<h4>মিলন পৃষ্ঠায় আপনাকে নিয়ে যেতে ক্লিক করুন যেখানে আপনি আপনার অর্ডারের স্থিতি পরীক্ষা করতে পারেন।</h4>:<h4>உங்கள் ஆர்டரின் நிலையை நீங்கள் சரிபார்க்கக்கூடிய பொருந்தும் பக்கத்திற்கு உங்களை அழைத்துச் செல்ல கிளிக் செய்யவும்.</h4>
      }
    </div>),
    style:{
      backgroundColor:"#ff8d1a",
      color:"white",
      paddingTop:'30px'
    }
  }
]