export const termsTour = [
  {
    selector: ".ant-checkbox-wrapper-in-form-item",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Once you read all the terms & condition,Click checkbox to agree the
            terms and condition
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            एक बार जब आप सभी नियम और शर्तें पढ़ लेते हैं, तो नियम और शर्तों से
            सहमत होने के लिए चेकबॉक्स पर क्लिक करें
          </h4>
        ) : localStorage.getItem("language") == "bn" ?(
          <h4>
            একবার আপনি সমস্ত শর্তাবলী পড়ে নিলে, শর্তাবলীতে সম্মত হতে চেকবক্সে
            ক্লিক করুন
          </h4>
        ):<h4>அனைத்து விதிமுறைகளையும் நிபந்தனைகளையும் படித்தவுடன், விதிமுறைகள் மற்றும் நிபந்தனைகளை ஒப்புக்கொள்ள தேர்வுப்பெட்டியைக் கிளிக் செய்யவும் </h4>}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".accept-btn-tour",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>After clicking on checkbox,click on this accept button</h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            चेकबॉक्स पर क्लिक करने के बाद, इस स्वीकार करें बटन पर क्लिक करें
          </h4>
        ) : localStorage.getItem("language") == "bn" ?(
          <h4>চেকবক্সে ক্লিক করার পর, এই স্বীকার বোতামে ক্লিক করুন</h4>
        ):<h4>செக்பாக்ஸைக் கிளிக் செய்த பிறகு, இந்த ஏற்கும் பொத்தானைக் கிளிக் செய்யவும்</h4>}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
];
