import hireRateImg from '../../../images/hiring.gif'
import availableRateImg from '../../../images/availabel.gif'
import { Image } from 'antd'

export const editRateOpenTour=[
    {
        "selector": ".hiringRate-tour",
        content:()=>( <div>
           <Image
            width={200}
            style={{borderRadius: '10px'}}
            preview={false}
            src={hireRateImg}/>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>View the position of your order highlighted in Orange . You may amend your price - move it up the table to improve your chances of a match.</h4>: localStorage.getItem('language')=='hi'? <h4>ऑरेंज में हाइलाइट किए गए अपने ऑर्डर की स्थिति देखें। आप अपनी कीमत में संशोधन कर सकते हैं - मैच की संभावनाओं को बेहतर बनाने के लिए इसे तालिका में ऊपर ले जाएं।</h4>: localStorage.getItem('language')=='bn'?<h4>অরেঞ্জে হাইলাইট করা আপনার অর্ডারের অবস্থান দেখুন। আপনি আপনার মূল্য সংশোধন করতে পারেন - আপনার ম্যাচের সম্ভাবনা উন্নত করতে এটিকে টেবিলের উপরে নিয়ে যান৷</h4>:<h4>ஆரஞ்சு நிறத்தில் சிறப்பிக்கப்பட்டுள்ள உங்கள் ஆர்டரின் நிலையைப் பார்க்கவும். உங்கள் விலையை நீங்கள் திருத்தலாம் - போட்டிக்கான வாய்ப்புகளை மேம்படுத்த, அதை அட்டவணைக்கு மேலே நகர்த்தவும்.</h4>
        } 
          </div>),
          style:{
            backgroundColor:"#ff8d1a",
            color:"white",
            paddingTop:'30px'
          }
    },
    {
        "selector": ".availableRate-tour",
        content:()=>( <div>
          <Image
            width={200}
            style={{borderRadius: '10px'}}
            preview={false}
            src={availableRateImg}/>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>View the position of your order highlighted in Blue. You may amend your price - move it up the table to improve your chances of a match.</h4>: localStorage.getItem('language')=='hi'? <h4>नीले रंग में हाइलाइट किए गए अपने ऑर्डर की स्थिति देखें। आप अपनी कीमत में संशोधन कर सकते हैं - मैच की संभावनाओं को बेहतर बनाने के लिए इसे तालिका में ऊपर ले जाएं। </h4>: localStorage.getItem('language')=='bn'?<h4>নীল রঙে হাইলাইট করা আপনার অর্ডারের অবস্থান দেখুন। আপনি আপনার মূল্য সংশোধন করতে পারেন - আপনার ম্যাচের সম্ভাবনা উন্নত করতে এটিকে টেবিলের উপরে নিয়ে যান৷</h4>:<h4>நீல நிறத்தில் ஹைலைட் செய்யப்பட்ட உங்கள் ஆர்டரின் நிலையைப் பார்க்கவும். உங்கள் விலையை நீங்கள் திருத்தலாம் - போட்டிக்கான வாய்ப்புகளை மேம்படுத்த, அதை அட்டவணைக்கு மேலே நகர்த்தவும்.</h4>
        } 
          </div>),
          style:{
            backgroundColor:"#ff8d1a",
            color:"white",
            paddingTop:'30px'
          }
    }
]