import React from "react";
import CommonHeader from "../commonheaders/CommonHeader";
import CommonTerms from "../../terms/commonTerms";
import './TermsAndCondition.css'
import CommonTermsHindi from "../../terms/commonTermsHindi";
import CommonTermsBengali from "../../terms/commonTermsBengali";
import { t } from "i18next";

/**
 * Terms And Condition Page
 * @param {*} props 
 * @returns 
*/
const TermsAndCondition = (props) => {
    return (
        <div className="Terms-container">
            <CommonHeader
                imageUrl="https://onesourcekarmayoga.com/wp-content/uploads/2021/05/vision_01_a-1.jpg"
                headerText={t('terms_of_use')}
            />
            <div className="terms-and-condition-container">
                {/* <CommonTerms isHeader={false} /> */}
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null) ?<CommonTerms isHeader={true} />:localStorage.getItem('language')=='hi'? <CommonTermsHindi isHeader={true} />:<CommonTermsBengali isHeader={true} />
        } 
            </div>
        </div>
    )
}

export default TermsAndCondition