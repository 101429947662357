import './footer.css';
import packageJson from '../../../package.json';


const FooterComp = (props) => {
    return (
        <>
            <div className='container'>
                <span className='version'>
                    V{packageJson.version} 
                </span>
                {/* All Right Reserved Onesource Karmayoga Pvt Ltd */}
                <span className='company'> &copy;{new Date().getFullYear()} </span>
                </div>

        </>
    );
}

export default FooterComp;