export const userProfileTour = [
  {
    selector: ".edit_profile_btn",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>Click on Edit Profile button to update your profile details</h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            अपना प्रोफ़ाइल विवरण अपडेट करने के लिए प्रोफ़ाइल संपादित करें बटन पर
            क्लिक करें
          </h4>
        ) : localStorage.getItem("language") == "bn" ?(
          <h4>
            আপনার প্রোফাইলের বিবরণ আপডেট করতে প্রোফাইল সম্পাদনা বোতামে ক্লিক
            করুন
          </h4>
        ):<h4>உங்கள் சுயவிவர விவரங்களைப் புதுப்பிக்க, சுயவிவரத்தைத் திருத்து பொத்தானைக் கிளிக் செய்யவும்</h4>}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-radio-group",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>select your language </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>अपनी भाषा चुनें</h4>
        ) : localStorage.getItem("language") == "bn" ?(
          <h4>আপনার ভাষা নির্বাচন করুন </h4>
        ):<h4>உங்கள் மொழியைத் தேர்ந்தெடுக்கவும் </h4>}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
];
