export const verifyRegisterTour=[
    {
      selector: ".ant-form-item-control",
      content:()=>( <div>
        {
          (localStorage.getItem('language')=='en' || localStorage.getItem('language')==null)?<h4>Enter the 4 digit code and click on verify code button</h4>: localStorage.getItem('language')=='hi'? <h4>4 अंकों का कोड दर्ज करें और कोड सत्यापित करने के लिए बटन पर क्लिक करें</h4>: localStorage.getItem('language')=='bn'?<h4>4 সংখ্যার কোড লিখুন এবং কোড যাচাই বাটনে ক্লিক করুন</h4>:<h4>4 இலக்கக் குறியீட்டை உள்ளிட்டு, குறியீட்டைச் சரிபார்க்கவும் பொத்தானைக் கிளிக் செய்யவும்</h4>
        }
      </div>),
      style:{
        backgroundColor:"#ff8d1a",
        color:"white",
        paddingTop:'30px'
      }
    }
]