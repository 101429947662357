import React, { useEffect, useState } from "react";
import "./HelpPage.css";
import { Button, Carousel, Image, Tabs } from "antd";
import {
  PhoneOutlined,
  InfoCircleOutlined,
  WechatOutlined,
  SafetyCertificateOutlined,
  VideoCameraOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import ContactUs from "../companyprofile/contactus/ContactUs";
import CompanyProfile from "../companyprofile/CompanyProfile";
import {
  makeSetHelpGuide,
  makeSetYoutubeLink,
} from "../../redux-core/help/selectors";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import videoImage from "../../images/tutorial.png";
import aboutImage from "../../images/aboutus.png";
import faqImage from "../../images/construction_faq.png";
import phoneImage from "../../images/contractor_support.png";
import trainingImage from "../../images/training.png";
import AboutCompany from "../companyprofile/aboutCompany/AboutCompany";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Faq from "./Faq";
import Training from "./Training";
import Tour from "reactour";
import loginTour, { helpPageTour } from "../../utils/tour_guide/helpPage";
import { useTranslation } from "react-i18next";
import { settourGuide } from "../../redux-core/help/actions";
import { ActionTypes } from "../../redux-core/help/constants";

const { TabPane } = Tabs;

const actionDispatch = (dispatch) => ({
  setTourGuide: (actiontype, payload) =>
    dispatch(settourGuide(actiontype, payload)),
});

const youtubeDemoLinkSelector = createSelector(
  makeSetYoutubeLink,
  (youtubeLink) => ({
    youtubeLink,
  })
);

const helpGuideSelector = createSelector(makeSetHelpGuide, (helpGuide) => ({
  helpGuide,
}));

function HelpPage() {
  const { youtubeLink } = useSelector(youtubeDemoLinkSelector);
  const { helpGuide } = useSelector(helpGuideSelector);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const { t, i18n } = useTranslation();

  const { setTourGuide } = actionDispatch(useDispatch());

  const closeTour = () => {
    setIsTourOpen(false);
    if (isTourOpen) {
      setTourGuide(ActionTypes.SET_HELP_GUIDE, false);
    }
  };

  return (
    <div
      className="Help default_container_padding"
      style={{ paddingTop: "0px" }}
    >
      {helpGuide && (
        <Tour
          steps={helpPageTour}
          isOpen={isTourOpen}
          closeWithMask={false}
          badgeContent={(curr, tot) => `${curr}/${tot}`}
          nextButton={
            <Button style={{ padding: "0px 10px" }}>{t("next")}</Button>
          }
          prevButton={
            <Button style={{ padding: "0px 10px" }}>{t("prev")}</Button>
          }
          lastStepNextButton={
            <Button style={{ padding: "0px 10px" }}>{t("done")}</Button>
          }
          rounded={10}
          showNavigation={false}
          onRequestClose={closeTour}
        />
      )}
      <div className="page_heading">{t("help")}</div>
      <div className="fixed-help-icon-container">
        <Tabs defaultActiveKey="1"  centered>
          <TabPane
            tab={
              <figure className="category_fig first">
                <Image className="category_image" src={videoImage}  preview={false} />
                <figcaption className="fig-caption">{t("tutorial")}</figcaption>
              </figure>
            }
            key="1"
            forceRender={true}
          >
            <iframe src={youtubeLink} frameBorder="0" width="100%"></iframe>
          </TabPane>
          <TabPane
            tab={
              <figure className="category_fig second">
                <img src={faqImage} className="category_image" preview={false}/>
                <figcaption className="fig-caption">{t("faq")}</figcaption>
              </figure>
            }
            key="2"
          >
            <Faq />
          </TabPane>
          <TabPane
            tab={
              <figure className="category_fig third">
                <img src={phoneImage} className="category_image" preview={false} />
                <figcaption className="fig-caption">{t("contact")}</figcaption>
              </figure>
            }
            key="3"
          >
            <ContactUs />
          </TabPane>
          <TabPane
            tab={
              <figure className="category_fig fourth">
                <img src={aboutImage} className="category_image" preview={false} />
                <figcaption className="fig-caption">{t("aboutus")}</figcaption>
              </figure>
            }
            key="4"
          >
            {/* <CompanyProfile/> */}
            <AboutCompany />
          </TabPane>
          <TabPane
            tab={
              <figure className="help_category_fig fifth">
                <img
                  src={trainingImage}
                  className="help_category_image"
                  preview={false}
                />
                <figcaption className="fig-caption">{t("training")}</figcaption>
              </figure>
            }
            key="5"
          >
            <Training />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default HelpPage;
