import recruit from "../../images/recruit.gif";
import apply from "../../images/apply.gif";
import { Image } from "antd";

export const resourcesTour = [
  {
    selector: ".main_page_search_bar",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Type the trade category name in this search bar to find the category
            you are looking for.
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            श्रेणी खोजने के लिए इस खोज बार में व्यापार श्रेणी का नाम टाइप करें
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            আপনি যে বিভাগটি খুঁজছেন তা খুঁজে পেতে এই অনুসন্ধান বারে ট্রেড
            বিভাগের নাম টাইপ করুন৷
          </h4>
        ) : (
          <h4>
            நீங்கள் தேடும் வகையைக் கண்டறிய இந்தத் தேடல் பட்டியில் வர்த்தக வகைப்
            பெயரைத் தட்டச்சு செய்யவும்.
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-btn",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Unable to find a trade? Click on Add and request for trade you are
            looking for and we will get back to you.{" "}
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            व्यापार खोजने में असमर्थ? आप जिस व्यापार की तलाश कर रहे हैं उसके लिए
            जोड़ें और अनुरोध करें पर क्लिक करें और हम आपसे संपर्क करेंगे
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            একটি ট্রেড খুঁজে পেতে অক্ষম? আপনি খুঁজছেন ট্রেডের জন্য যোগ করুন এবং
            অনুরোধে ক্লিক করুন এবং আমরা আপনার কাছে ফিরে আসব।
          </h4>
        ) : (
          <h4>
            ஒரு வர்த்தகத்தைக் கண்டுபிடிக்க முடியவில்லையா? நீங்கள் தேடும்
            வர்த்தகத்தைச் சேர் மற்றும் கோரிக்கையைக் கிளிக் செய்யவும், நாங்கள்
            உங்களைத் தொடர்புகொள்வோம்.{" "}
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-tabs-nav-list",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>Select your category list.</h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>अपनी श्रेणी सूची का चयन करें</h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>আপনার বিভাগ তালিকা নির্বাচন করুন</h4>
        ) : (
          <h4>உங்கள் வகைப் பட்டியலைத் தேர்ந்தெடுக்கவும்.</h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".three-dot",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Click here if you want to change your selected option to
            recruit,apply or both .{" "}
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            यदि आप भर्ती में अपना चयनित विकल्प बदलना चाहते हैं तो यहां क्लिक
            करें, आवेदन करें या दोनों।
          </h4>
        ) : (
          <h4>
            আপনি যদি নিয়োগ, আবেদন বা উভয় ক্ষেত্রে আপনার নির্বাচিত বিকল্প
            পরিবর্তন করতে চান তাহলে এখানে ক্লিক করুন।
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-table-cell-fix-left-last",
    content: () => (
      <div>
        <Image style={{ borderRadius: "10px" }} preview={false} src={recruit} />
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            If you are looking to hire/recruit people, click the checkbox in
            this(orange) column. You can make multiple selections too.{" "}
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            यदि आप लोगों को नियुक्त/भर्ती करना चाहते हैं, तो इस (नारंगी) कॉलम
            में चेकबॉक्स पर क्लिक करें। आप अनेक चयन भी कर सकते हैं
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            আপনি যদি লোক নিয়োগ/নিয়োগ করতে চান তবে এই (কমলা) কলামে চেকবক্সে
            ক্লিক করুন। আপনি একাধিক নির্বাচনও করতে পারেন।
          </h4>
        ) : (
          <h4>
            நீங்கள் ஆட்களை பணியமர்த்த/சேர்க்க விரும்பினால், இந்த (ஆரஞ்சு)
            நெடுவரிசையில் உள்ள தேர்வுப்பெட்டியைக் கிளிக் செய்யவும். நீங்கள் பல
            தேர்வுகளையும் செய்யலாம்.{" "}
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-table-cell-fix-right-first",
    content: () => (
      <div>
        <Image style={{ borderRadius: "10px" }} preview={false} src={apply} />
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            If you are looking to be hired /applying for a job, click the
            checkbox in this (blue) column. You can make multiple selections
            too.{" "}
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            यदि आप नौकरी के लिए आवेदन करना चाहते हैं या नौकरी के लिए आवेदन करना
            चाहते हैं, तो इस (नीला) कॉलम में चेकबॉक्स पर क्लिक करें। आप अनेक चयन
            भी कर सकते हैं
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            আপনি যদি চাকরির জন্য নিয়োগ পেতে/আবেদন করতে চান, তাহলে এই (নীল)
            কলামে চেকবক্সে ক্লিক করুন। আপনি একাধিক নির্বাচনও করতে পারেন।
          </h4>
        ) : (
          <h4>
            நீங்கள் பணியமர்த்தப்பட விரும்பினால்/வேலைக்கு விண்ணப்பிக்க
            விரும்பினால், இந்த (நீலம்) நெடுவரிசையில் உள்ள தேர்வுப்பெட்டியைக்
            கிளிக் செய்யவும். நீங்கள் பல தேர்வுகளையும் செய்யலாம்.{" "}
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".three-dot",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Click here if you want to change your selected option in
            recruit,apply or both .{" "}
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            यदि आप भर्ती में अपना चयनित विकल्प बदलना चाहते हैं तो यहां क्लिक
            करें, आवेदन करें या दोनों।
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            আপনি যদি নিয়োগ, আবেদন বা উভয় ক্ষেত্রে আপনার নির্বাচিত বিকল্প
            পরিবর্তন করতে চান তাহলে এখানে ক্লিক করুন।
          </h4>
        ) : (
          <h4>
            ஆட்சேர்ப்பு, விண்ணப்பிக்க அல்லது இரண்டிலும் நீங்கள் தேர்ந்தெடுத்த
            விருப்பத்தை மாற்ற விரும்பினால் இங்கே கிளிக் செய்யவும்.{" "}
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-pagination-item-link",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>Click here if you want to go on the previous page</h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>अगर आप पिछले पेज पर जाना चाहते हैं तो यहां क्लिक करें</h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>পূর্ববর্তী পৃষ্ঠায় যেতে চাইলে এখানে ক্লিক করুন</h4>
        ) : (
          <h4>
            முந்தைய பக்கத்திற்குச் செல்ல விரும்பினால் இங்கே கிளிக் செய்யவும்
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".ant-pagination-next",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>Click here if you want to go on the Next page</h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>अगर आप अगले पेज पर जाना चाहते हैं तो यहां क्लिक करें</h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>পরবর্তী পৃষ্ঠায় যেতে চাইলে এখানে ক্লিক করুন</h4>
        ) : (
          <h4>
            அடுத்த பக்கத்திற்குச் செல்ல விரும்பினால் இங்கே கிளிக் செய்யவும்
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
  {
    selector: ".btn-continue",
    content: () => (
      <div>
        {localStorage.getItem("language") == "en" ||
        localStorage.getItem("language") == null ? (
          <h4>
            Once you are done selecting all the trades you are recruiting and
            applying for - click on this <b>Continue</b> button
          </h4>
        ) : localStorage.getItem("language") == "hi" ? (
          <h4>
            एक बार जब आप उन सभी ट्रेडों का चयन कर लें जिनके लिए आप भर्ती और
            आवेदन कर रहे हैं - इस <b>जारी रखें</b> बटन पर क्लिक करें
          </h4>
        ) : localStorage.getItem("language") == "bn" ? (
          <h4>
            আপনি নিয়োগ করছেন এবং আবেদন করছেন এমন সমস্ত ট্রেড নির্বাচন করা হয়ে
            গেলে - এই <b>চালিয়ে যান</b> বোতামে ক্লিক করুন
          </h4>
        ) : (
          <h4>
            நீங்கள் ஆட்சேர்ப்பு செய்யும் மற்றும் விண்ணப்பிக்கும் அனைத்து
            வர்த்தகங்களையும் தேர்ந்தெடுத்து முடித்தவுடன் - இந்த <b>தொடரவும்</b>{" "}
            பொத்தானை கிளிக் செய்யவும்
          </h4>
        )}
      </div>
    ),
    style: {
      backgroundColor: "#ff8d1a",
      color: "white",
      paddingTop: "30px",
    },
  },
];
