import { createSelector } from "reselect";

const ratingQuestionState = (state) => state.rating.ratingQuestion;
const ratingQuestionForSellerState = (state) => state.rating.ratingQuestionForSeller;

const submitRatingDataState = (state) => state.rating.submitRatingData;


/**
 * Selector for getting updated count data.
 */
 export const makeSelectRatingQuestion = createSelector(
    ratingQuestionState,
    (ratingQuestion) => ratingQuestion
  );

  export const makeSelectRatingQuestionForSeller = createSelector(
    ratingQuestionForSellerState,
    (ratingQuestionForSeller) => ratingQuestionForSeller
  );

  export const makeSubmitRatingData = createSelector(
    submitRatingDataState,
    (submitRatingData) => submitRatingData
  );