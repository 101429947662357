import { List, Menu, Radio } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { LANGUAGES } from "../../utils/languages";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import "../multilingual/LanguageMenu.css";

const LanguageMenu = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedKey, SetKey] = useState(localStorage.getItem("language"));

  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    //  alert(e);
    //const languageValue = e.target.value
    i18n.changeLanguage(e);
  };

  /**
   * onSelect menu
   */
  const onSelectMenu = (e) => {
    SetKey(e.target.value);
    localStorage.setItem("language", e.target.value);
    changeLanguageHandler(e.target.value);
  };
  
  return (
    <>
      <div className="lang-btn1">
        <Radio.Group
          onChange={(e) => onSelectMenu(e)}
          value={selectedKey ? selectedKey : props.languageupdate }
        >
          {LANGUAGES.map((item, i) => {
            return (
              <Radio.Button value={item.code} key={i}>
                {item.language}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
    </>
  );
};

export default LanguageMenu;
