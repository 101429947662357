import React from "react";

/**
 * Terms And Condition Page
 * @param {*} isHeader 
 * @returns 
*/

const CommonTerms = ({ isHeader }) => {
    return (
        <div className="CommonTermsContainer">
            <div id="page_1">
                {isHeader && <div id="p1dimg1">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAocAAAAZCAIAAADMhROvAAAAgklEQVR4nO3VMQEAIAzAMMC/p1njwQM9EgX9umdmAQAB53cAAPC4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVFzU5QJv52dqgAAAAABJRU5ErkJggg==" id="p1img1" />
                </div>}


                {isHeader && <div id="id1_1">
                    <p className="p0 ft0">TERMS OF USE</p>
                </div>}
                <div id="id1_2">
                    <p className="p0 ft3"><span className="ft1">1.</span><span className="ft2">Relationship with Users</span></p>
                    <p className="p1 ft6"><span className="ft1">1.1.</span><span className="ft4">These Terms of Use (“</span><span className="ft5">Terms</span>”) govern the your access and use, from within India, of applications, platforms, websites, content and Services hereinafter referred to singly and/or jointly as <span className="ft5">Services </span>made available by One Source Karma Yoga Pvt. Ltd., a private limited company established in India, having its registered office at #859, 17th G Main Road, 5th Block Rajajinagar, Bangalore 560010 (“<span className="ft5">Karma Yoga</span>”).</p>
                    <p className="p2 ft6"><span className="ft7">1.2.</span><span className="ft8">The </span><span className="ft5">Services </span>made available by Karma Yoga, hereinafter known as “<span className="ft5">Services</span>” consists of the application/platform made available for Users to list their requirements for hiring or deployment of trade Services. The <span className="ft5">Services </span>will attempt to find a suitable match between hiring and deployment. Any person or company who makes use of the <span className="ft5">Services </span>will hereinafter be referred to as a “<span className="ft5">User</span>”</p>
                    <p className="p3 ft6"><span className="ft1">1.3.</span><span className="ft8">Your access and use of the </span><span className="ft5">Services </span>constitutes your agreement to be bound by these Terms, which establishes a contractual relationship between you and Karma Yoga. If you do not agree to these Terms, you may not access or use the <span className="ft5">Services</span>. These Terms expressly supersede prior written agreements between Karma Yoga and you.</p>
                    <p className="p3 ft7"><span className="ft1">1.4.</span><span className="ft9">Supplemental terms may apply to certain </span><span className="ft3">Services</span>, such as policies for a particular event, activity or promotion, and such supplemental terms will be disclosed to you in connection with the applicable <span className="ft3">Services</span>. Supplemental terms are in addition to, and shall be deemed a part of, the Terms for the purposes of the applicable <span className="ft3">Services</span>. Supplemental terms shall prevail over these Terms in the event of a conflict with respect to the applicable <span className="ft3">Services</span>.</p>
                    <p className="p4 ft7"><span className="ft1">1.5.</span><span className="ft9">Karma Yoga may restrict you from accessing or using the </span><span className="ft3">Services</span>, or any part of them, immediately, without notice, in circumstances where Karma Yoga reasonably suspects that:</p>
                    <p className="p5 ft7"><span className="ft7">(a)</span><span className="ft10">you have, or are likely to, breach these Terms; and/or</span></p>
                    <p className="p6 ft7"><span className="ft7">(b)</span><span className="ft11">you do not, or are likely not to, qualify, under applicable law or the standards and policies of Karma Yoga, to access and use the </span><span className="ft3">Services</span>.</p>
                    <p className="p7 ft7"><span className="ft7">(c)</span><span className="ft12">you are not a bonafide user - that is either you are not authorized by your organization to transact the </span><span className="ft3">Services </span>offered by Karma Yoga or that you are not in a position that authorizes/permits you to use the <span className="ft3">Services</span>.</p>
                    <p className="p8 ft7"><span className="ft7">(d)</span><span className="ft11">That the information provided by you is false or inaccurate</span></p>
                    <p className="p9 ft6"><span className="ft1">1.6.</span><span className="ft8">Karma Yoga may terminate these Terms or any </span><span className="ft5">Services </span>with respect to you, or generally cease offering or deny access to the <span className="ft5">Services </span>or any portion thereof immediately, where Karma Yoga suspects or believes that:</p>
                    <p className="p10 ft7"><span className="ft7">(a)</span><span className="ft10">you have, or are likely to, materially breach these Terms; or</span></p>
                    <p className="p6 ft7"><span className="ft7">(b)</span><span className="ft11">you do not, or are likely not to, qualify, under applicable law or policies of Karma Yoga, to access and use the </span><span className="ft3">Services</span>; or</p>
                    <p className="p11 ft7"><span className="ft1">1.7.</span><span className="ft13">Karma Yoga may terminate these Terms or any </span><span className="ft3">Services </span>with 5 (five) days’ prior written notice to you, for any legitimate business, legal or regulatory reason, or without cause.</p>
                    <p className="p12 ft6"><span className="ft1">1.8.</span><span className="ft8">Karma Yoga is entitled to amend any policies or supplemental terms related to the </span><span className="ft5">Services </span>from time to time, and will provide you with at least 5 (five) days’ prior written notice, on the Service Application, in the event of a material change to any policies or supplemental terms. Amendments will be effective upon Karma Yoga posting such amended policies or supplemental terms on the applicable Service. Your continued access or use of the <span className="ft5">Services </span>after such posting, or after the expiry of the notice period (whichever is later), constitutes your consent to be bound by the Terms, as amended.</p>
                    <p className="p13 ft3"><span className="ft14">2.</span><span className="ft15">Amendments to terms of use and privacy policy</span></p>
                    <p className="p9 ft7"><span className="ft1">2.1.</span><span className="ft9">Karma Yoga reserves the right, in its sole discretion, to change, modify, or otherwise amend the Terms, and any other documents incorporated by reference herein to comply with the legal and regulatory</span></p>
                </div>
            </div>
            <div id="page_2">


                <p className="p14 ft6">framework and for other legitimate business purposes, at any time, and Karma Yoga will post the amended Terms on the website <a href="https://www.onesourcekarmayoga.com/" target="_blank">“</a><a href="https://www.onesourcekarmayoga.com/" target="_blank"><span className="ft16">https://www.onesourcekarmayoga.com</span></a>” (“Website”) and the mobile web application <a href="https://www.karmayoga.app/" target="_blank">“</a><a href="https://www.karmayoga.app/" target="_blank"><span className="ft16">karmayoga.app</span></a><a href="https://www.karmayoga.app/" target="_blank">” </a>(collectively with the Website, the “KY Platform”). It is your responsibility to review the Terms for changes and you are encouraged to check the Terms frequently</p>
                <p className="p2 ft6"><span className="ft1">2.2.</span><span className="ft17">Your use of the Website after the date that amendments to the Terms take effect signify your assent to and acceptance of such revised Terms. If you do not agree to abide by these or any future Terms, please do not use the </span><span className="ft5">Services</span>.</p>
                <p className="p15 ft3"><span className="ft14">3.</span><span className="ft15">Services</span></p>
                <p className="p9 ft6"><span className="ft1">3.1.</span><span className="ft8">The </span><span className="ft5">Services </span>are made available solely for commercial use. You acknowledge that Karma Yoga does not and is not responsible for any activities carried out by any organization or personnel to whom you are connected by or through the KY Platform, and such organizations and personnel are independent third parties who are not employed by Karma Yoga or any of its affiliates. Such personnel and organizations are solely responsible for the Services they provide to you.</p>
                <p className="p16 ft7"><span className="ft1">3.2.</span><span className="ft18">Subject to your compliance with these Terms, Karma Yoga grants you a limited, </span><b>non-exclusive,</b> <b>non-sub-</b> licensable, revocable, <b>non-transferrable</b> license to:</p>
                <p className="p5 ft7"><span className="ft7">(a)</span><span className="ft10">access and use the KY Platform solely in connection with your use of the </span><span className="ft3">Services</span>, and</p>
                <p className="p17 ft6"><span className="ft7">(b)</span><span className="ft19">access and use any content, information and related materials that may be made available through the </span><span className="ft5">Services</span>, in each case solely for commercial use. Any rights not expressly granted herein are reserved by Karma Yoga.</p>
                <p className="p15 ft7"><span className="ft1">3.3.</span><span className="ft18">You may not:</span></p>
                <p className="p5 ft7"><span className="ft7">(a)</span><span className="ft10">remove any copyright, trademark or other proprietary notices from any portion of the </span><span className="ft3">Services</span>;</p>
                <p className="p17 ft6"><span className="ft7">(b)</span><span className="ft19">reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the </span><span className="ft5">Services </span>except as expressly permitted by Karma Yoga;</p>
                <p className="p18 ft7"><span className="ft7">(c)</span><span className="ft12">decompile, reverse engineer or disassemble the </span><span className="ft3">Services </span>except as may be permitted by applicable law;</p>
                <p className="p5 ft7"><span className="ft7">(d)</span><span className="ft11">link to, mirror or frame any portion of the </span><span className="ft3">Services</span>;</p>
                <p className="p17 ft6"><span className="ft7">(e)</span><span className="ft20">cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the </span><span className="ft5">Services </span>or unduly burdening or hindering the operation and/or functionality of any aspect of the <span className="ft5">Services</span>; or</p>
                <p className="p19 ft7"><span className="ft7">(f)</span><span className="ft21">attempt to gain unauthorized access to or impair any aspect of the </span><span className="ft3">Services </span>or its related systems or networks.</p>
                <p className="p11 ft7"><span className="ft1">3.4.</span><span className="ft18">You acknowledge that portions of the </span><span className="ft3">Services </span>may be made available in connection with independent third parties.</p>
                <p className="p12 ft7"><span className="ft1">3.5.</span><span className="ft18">Karma Yoga does not endorse third party Services and content and in no event shall Karma Yoga be responsible or liable for any products or Services of such third parties. Additionally, Apple Inc., Google, Inc., Microsoft Corporation or BlackBerry Limited and/or their applicable international subsidiaries and affiliates will be </span><b>third-party</b> beneficiaries to this contract if you access the <span className="ft3">Services </span>using Applications developed for Apple iOS, Android, Microsoft Windows, or <b>Blackberry-powered</b> mobile devices, respectively. These third party beneficiaries are not parties to this contract and are not responsible for the provision or support of the <span className="ft3">Services </span>in any manner. Your access to the <span className="ft3">Services </span>using these devices is subject to terms set forth in the applicable third party beneficiary’s terms of service.</p>
                <p className="p20 ft7"><span className="ft1">3.6.</span><span className="ft18">The </span><span className="ft3">Services </span>and all rights therein are and shall remain Karma Yoga’s property or the property of Karma Yoga’s licensors. Neither these Terms nor your use of the <span className="ft3">Services </span>convey or grant to you any rights:</p>
                <p className="p5 ft7"><span className="ft7">(a)</span><span className="ft10">in or related to the </span><span className="ft3">Services </span>except for the limited license granted above; or</p>
            </div>
            <div id="page_3">


                <p className="p21 ft7"><span className="ft7">(b)</span><span className="ft11">to use or reference in any manner Karma Yoga’s company names, logos, product and service names, trademarks or </span><span className="ft3">Services </span>marks.</p>
                <p className="p22 ft3"><span className="ft1">4.</span><span className="ft15">Account</span></p>
                <p className="p1 ft7"><span className="ft1">4.1.</span><span className="ft9">In order to use the </span><span className="ft3">Services</span>, you must register for and maintain an active <span className="ft3">Services </span>account (“<span className="ft3">Account</span>”). You must be a company or limited liability partnership incorporated in India, a partnership firm, or sole proprietorship, or such other person having a legal personality, capable of suing or being sued under Indian laws, unless a specific service permits otherwise, or Karma Yoga permits otherwise.</p>
                <p className="p12 ft7"><span className="ft1">4.2.</span><span className="ft18">You hereby represent and warrant that you have the authority, and legal capacity to accept and be bound by these Terms, and are not restricted in any manner whatsoever to abide by and accept these Terms.</span></p>
                <p className="p23 ft6"><span className="ft1">4.3.</span><span className="ft17">Account registration requires you to submit to Karma Yoga, certain personal information, such as your company name, authorized individual name, address, mobile phone number and age, CIN, GST, PAN, Aadhar, as applicable, as well as at least one valid payment method (either a credit card or accepted payment partner). You agree to maintain accurate, complete, and </span><b>up-to-</b> date information in your Account. Your failure to maintain accurate, complete, and <b>up-to-date</b> Account information, including having an invalid or expired payment method on file, may result in your inability to access or use the <span className="ft5">Services</span>. You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and password at all times. Unless otherwise permitted by Karma Yoga in writing, you may only possess one Account.</p>
                <p className="p24 ft7"><span className="ft1">4.4.</span><span className="ft18">You may not assign or otherwise transfer your Account to any other person or entity. You agree to comply with all applicable laws when accessing or using the </span><span className="ft3">Services</span>, and you may only access or use the <span className="ft3">Services </span>for lawful purposes. You will not, in your use of the <span className="ft3">Services</span>, cause nuisance, annoyance, inconvenience, or property damage to the third party contractors / personnel. In certain instances you may be asked to provide proof of identity or other method of identity verification to access or use the <span className="ft3">Services</span>, and you agree that you may be denied access to or use of the <span className="ft3">Services </span>if you refuse to provide proof of identity or other method of identity verification.</p>
                <p className="p25 ft7"><span className="ft1">4.5.</span><span className="ft18">Karma Yoga prohibits discrimination against third party contractors and personnel based on race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity, age or any other characteristic that may be protected under applicable law. Such discrimination includes, but is not limited to, any refusal to accept </span><span className="ft3">Services </span>based on any of these characteristics. If it is found that you have violated this prohibition you will lose access to the Karma Yoga platform. Applicable laws in certain states may require and/or allow the provision of <span className="ft3">Services </span>by and for the benefit of a specific category of persons. In such jurisdictions, <span className="ft3">Services </span>provided in compliance with these laws and the relevant applicable terms are permissible.</p>
                <p className="p25 ft6"><span className="ft1">4.6.</span><span className="ft17">By creating an Account, you agree that the </span><span className="ft5">Services </span>may send you text (SMS) and/or WhatsApp messages as part of the normal business operation of your use of the <span className="ft5">Services</span>. You may <b>opt-out</b> of receiving text (SMS) and/or WhatsApp messages from Karma Yoga at any time by informing Karma Yoga, in writing, of your decision to stop receiving such messages.</p>
                <p className="p26 ft7"><span className="ft1">4.7.</span><span className="ft22">You are responsible for obtaining the data network access necessary to use the </span><span className="ft3">Services</span>. Your mobile network’s data and messaging rates and fees may apply if you access or use the <span className="ft3">Services </span>from a <b>wireless-enabled</b> device and you shall be responsible for such rates and fees. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the <span className="ft3">Services </span>and KY Platform and any updates thereto. Karma Yoga does not guarantee that the <span className="ft3">Services</span>, or any portion thereof, will function on any particular hardware or devices. In addition, the <span className="ft3">Services </span>may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.</p>
                <p className="p27 ft3"><span className="ft14">5.</span><span className="ft15">Fee and Charges</span></p>
                <p className="p9 ft7"><span className="ft1">5.1.</span><span className="ft9">You understand that use of the </span><span className="ft3">Services </span>will result in charges to you for any Services that you may opt to receive from a third party (“<span className="ft3">Charges</span>”), for using such third party Services for or through your use of the <span className="ft3">Services</span>.</p>
            </div>
            <div id="page_4">


                <p className="p28 ft6"><span className="ft1">5.2.</span><span className="ft17">Karma Yoga shall charge you a convenience fee (“</span><span className="ft5">Fee</span>”) for the <span className="ft5">Services </span>offered by Karma Yoga, at any time during the use of Service on the platform. This fee will be applied at the sole discretion of Karma Yoga. The Service may be completed by Karma Yoga only on receipt of the Fee.</p>
                <p className="p29 ft7"><span className="ft1">5.3.</span><span className="ft18">The Fee will be exclusive of applicable taxes. Taxes, as applicable from time to time, will be charged additional to the Fee. Fee paid by you are final and </span><b>non-refundable,</b> unless otherwise determined by Karma Yoga.</p>
                <p className="p12 ft7"><span className="ft1">5.4.</span><span className="ft18">All Fees are due immediately and payment will be facilitated by Karma Yoga using the preferred payment method designated in your Account, after which Karma Yoga will send you a receipt by email. If your primary Account payment method is determined to be expired, invalid or otherwise not able to be charged, you agree that Karma Yoga may use a secondary payment method in your Account, if available.</span></p>
                <p className="p30 ft6"><span className="ft1">5.5.</span><span className="ft17">As between you and Karma Yoga, Karma Yoga reserves the right to establish, remove and/or revise the Fee for use of the </span><span className="ft5">Services </span>at any time in its sole discretion. Further, you acknowledge and agree that Charges and Fees applicable in certain geographical areas may increase substantially during times of high demand. Karma Yoga will use reasonable efforts to inform you of Charges and Fees that may apply, provided that you will be responsible for Charges and Fees incurred under your Account regardless of your awareness of such Charges, Fees or the amounts thereof. Karma Yoga may from time to time charge certain users different amounts for the same or similar <span className="ft5">Services </span>obtained through the use of the <span className="ft5">Services</span>, and you agree that such charges shall have no bearing on your use of the <span className="ft5">Services </span>or the Charges applied to you. You may elect to cancel your request for <span className="ft5">Services </span>from a third party within 2 (two) hours’ time from the time of your request, in which case you may be charged a cancellation fee. Additionally, the applicable taxes (whether direct or indirect) that are liable to be charged/ deducted on cancellation fee will charged to you by Karma Yoga.</p>
                <p className="p31 ft3"><span className="ft14">6.</span><span className="ft15">Disclaimer and Limitation of Liability</span></p>
                <p className="p32 ft7"><span className="ft1">6.1.</span><span className="ft9">THE LIMITATIONS AND DISCLAIMER IN THIS PARAGRAPH 6 DO NOT PURPORT TO LIMIT LIABILITY OR ALTER RIGHTS WHICH CANNOT BE EXCLUDED UNDER APPLICABLE LAW.</span></p>
                <p className="p12 ft6"><span className="ft1">6.2.</span><span className="ft17">THE </span><span className="ft5">SERVICES </span>ARE PROVIDED "AS IS" AND “AS AVAILABLE.” KARMA YOGA DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND <b>NON-INFRINGEMENT,</b> AND MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE <span className="ft5">SERVICES </span>OR ANY <span className="ft5">SERVICES </span>REQUESTED THROUGH THE USE OF THE <span className="ft5">SERVICES</span>, OR THAT THE <span className="ft5">SERVICES </span>WILL BE UNINTERRUPTED OR <b>ERROR-FREE.</b> KARMA YOGA DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE <span className="ft5">SERVICES</span>, AND ANY SERVICE REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.</p>
                <p className="p33 ft7"><span className="ft1">6.3.</span><span className="ft18">KARMA YOGA'S LIABILITY FOR A FAILURE TO COMPLY WITH A STATUTORY GUARANTEE IS LIMITED TO: IN THE CASE OF </span><span className="ft3">SERVICES </span>SUPPLIED TO YOU AS PART OF THE <span className="ft3">SERVICES </span>UNDER THIS AGREEMENT, THE SUPPLY OF THE RELEVANT <span className="ft3">SERVICES </span>AGAIN, OR THE PAYMENT OF THE FEE OF SUPPLYING THE <span className="ft3">SERVICES </span>PAID BY YOU TO KARMA YOGA.</p>
                <p className="p34 ft6"><span className="ft1">6.4.</span><span className="ft17">KARMA YOGA SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE </span><span className="ft5">SERVICES</span>, <span className="ft23">EVEN IF KARMA YOGA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span> KARMA YOGA SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE <span className="ft5">SERVICES </span>OR YOUR INABILITY TO ACCESS OR USE THE <span className="ft5">SERVICES</span>; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY, EVEN IF KARMA YOGA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL KARMA YOGA’S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE <span className="ft5">SERVICES </span>FOR ALL</p>
            </div>
            <div id="page_5">


                <p className="p35 ft7">DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED RUPEES <span className="ft24">ONE THOUSAND ONLY (INR 1,000 ONLY)</span></p>
                <p className="p36 ft6"><span className="ft1">6.5.</span><span className="ft17">KARMA YOGA WILL MAINTAIN A COMPLAINTS MANAGEMENT FRAMEWORK, AND WILL MANAGE THIS FRAMEWORK ON BEHALF OF THIRD PARTY SERVICE PROVIDERS, IN A REASONABLE WAY AND IN ACCORDANCE WITH THE </span><b>NON-EXCLUDABLE</b> REQUIREMENTS OF RELEVANT APPLICABLE LAWS.</p>
                <p className="p3 ft7"><span className="ft1">6.6.</span><span className="ft18">NOTWITHSTANDING ANYTHING CONTAINED IN THESE TERMS, KARMA YOGA WILL NOT BE DEEMED TO BE IN DEFAULT OR BE RESPONSIBLE FOR DELAYS OR FAILURES IN PERFORMANCE RESULTING FROM ACTS BEYOND THE REASONABLE CONTROL OF KARMA YOGA. SUCH ACTS SHALL INCLUDE BUT NOT BE LIMITED TO ACTS OF GOD, RIOTS, ACTS OF WAR, EPIDEMICS, PANDEMICS, TRADE EMBARGOES, FIRE, TYPHOONS, EARTHQUAKES, OTHER NATURAL DISASTERS. ETC.</span></p>
                <p className="p37 ft3"><span className="ft1">7.</span><span className="ft25">Indemnity</span></p>
                <p className="p38 ft7">You agree to indemnify and hold Karma Yoga and its affiliates and their officers, directors, employees and agents harmless from any and all claims, demands, losses, liabilities, and expenses (including attorneys’ fees) arising out of or in connection with: (i) your use of the <span className="ft3">Services </span>or Services obtained through your use of the <span className="ft3">Services</span>; (ii) your breach or violation of any of these Terms; (iii) Karma Yoga’s use of your information; or (iv) your violation of the rights of third parties (“<span className="ft3">Losses</span>”).</p>
                <p className="p39 ft3"><span className="ft14">8.</span><span className="ft15">Disputes</span></p>
                <p className="p1 ft6"><span className="ft1">8.1.</span><span className="ft8">There are a number of mechanisms available to you to resolve any dispute, conflict, claim or controversy arising out of or broadly in connection with or relating to the </span><span className="ft5">Services </span>or these Terms, including those relating to its validity, its construction or its enforceability or with the Services provided by a third party (any “<span className="ft5">Dispute</span>”). Karma Yoga operates a complaints process to allow you to make complaints about Karma Yoga or third party contractors and personnel, and Karma Yoga also manages refunds to you in relation to complaints in relation to Karma Yoga. Karma Yoga will operate this complaints process in a reasonable manner. You agree and acknowledge that you must raise any Dispute with the <span className="ft5">Services </span>or the Services provided by third party contractors / personnel as soon as is practicable, but no later than 48 (forty eight) hours from the time the Dispute arises. If you fail to raise a Dispute within 48 (forty eight) hours from the initial occurrence of such Dispute, Karma Yoga shall be under no obligation to resolve such Dispute. If a Dispute is reported more than 48 (forty eight) hours after the initial occurrence of the Dispute, Karma Yoga shall in its sole discretion choose whether or not to attempt resolution of the Dispute.</p>
                <p className="p40 ft7"><span className="ft1">8.2.</span><span className="ft18">Except as otherwise set forth in these Terms, these Terms shall be exclusively governed by and construed in accordance with the laws of India, excluding its rules on conflicts of laws. In the event of a Dispute, both parties may file an action in the courts at Bangalore, India or pursue final and binding arbitration or other alternative dispute resolution as agreed upon by the parties. If the parties agree to pursue final and binding arbitration, the venue of the arbitration shall be Bangalore, India. If both parties cannot agree on the method of dispute resolution the default option will be to file an action in the courts at Bangalore, India.</span></p>
                <p className="p25 ft6"><span className="ft1">8.3.</span><span className="ft17">Any proceedings, including documents and briefs submitted by the parties, correspondence from a mediator, and correspondence, order and awards issued by an arbitrator, shall remain strictly confidential and shall not be disclosed to any third party without the express written consent from the other party unless:</span></p>
                <p className="p18 ft7"><span className="ft7">(a)</span><span className="ft10">the disclosure to the third party is reasonably required in the context of conducting the mediation or arbitration proceedings; and</span></p>
                <p className="p41 ft7"><span className="ft7">(b)</span><span className="ft11">the third party agrees unconditionally in writing to be bound by the confidentiality obligation set out in these Terms.</span></p>
                <p className="p22 ft3"><span className="ft14">9.</span><span className="ft15">Grievances</span></p>
                <p className="p42 ft7"><span className="ft1">9.1.</span><span className="ft9">The procedure for redressal of grievances will be as under:</span></p>
            </div>
            <div id="page_6">


                <p className="p43 ft7"><span className="ft1">9.1.1.</span><span className="ft26">The aggrieved party raises grievance with substantiation and submits the same in writing to Karma Yoga.</span></p>
                <p className="p44 ft7"><span className="ft1">9.1.2.</span><span className="ft27">Karma Yoga will assign the resolution of the grievance to a dedicated Grievance Redressal Officer within 48 hours of a grievance being raised.</span></p>
                <p className="p45 ft6"><span className="ft1">9.1.3.</span><span className="ft28">The officer shall proceed to investigate with due diligence, the grievance raised and arrive at a resolution based on the Terms governing this relationship between the User and Karma Yoga as well as the relationship between the User and the party connected to using the Service.</span></p>
                <p className="p46 ft7"><span className="ft1">9.1.4.</span><span className="ft27">If the grievance raised is not covered by the Terms, they will be addressed as per the Laws of India and as specifically applicable in the State of Karnataka</span></p>
                <p className="p47 ft7"><span className="ft1">9.1.5.</span><span className="ft27">The aggrieved party will be kept updated on the progress of the resolution of the grievance as well as the estimated time for resolution.</span></p>
                <p className="p32 ft7"><span className="ft1">9.2.</span><span className="ft18">If no reasonable resolution of the grievance is arrived at by the Officer, the grievance may be treated as a Dispute and proceed to be resolved as per the procedure contained in these Terms.</span></p>
                <p className="p22 ft3"><span className="ft1">10.</span><span className="ft29">Notices</span></p>
                <p className="p48 ft6">Karma Yoga may give notice by means of a general notice on the <span className="ft5">Services</span>, electronic mail to your email address in your Account, or by written communication sent to your address as set forth in your Account. You may give notice to Karma Yoga by written communication to CEO, Karma Yoga at #859, 17th G Main Road, 5th Block Rajajinagar, Bangalore 560010 or by email to Notices@karmayoga.app</p>
                <p className="p42 ft3"><span className="ft1">11.</span><span className="ft30">General</span><span className="ft7">.</span></p>
                <p className="p26 ft6"><span className="ft1">11.1.</span><span className="ft31">You may not assign or transfer these Terms in whole or in part without Karma Yoga’s prior written approval. You give your approval to Karma Yoga for it to assign or transfer these Terms in whole or in part to any third party, including to a subsidiary, affiliate, an acquirer of Karma Yoga’s equity, business or assets, successor, or any other assignee.</span></p>
                <p className="p3 ft6"><span className="ft1">11.2.</span><span className="ft32">Karma Yoga’s role is solely restricted to hosting the Platform, and allows parties requiring and offering Services to find each other on the Platform. No joint venture, partnership, employment or agency relationship exists between you, Karma Yoga or any third party contractor / personnel as a result of the contract between you and Karma Yoga or use of the </span><span className="ft5">Services</span>.</p>
                <p className="p2 ft6"><span className="ft1">11.3.</span><span className="ft32">If any provision of these Terms is held to be illegal, invalid or unenforceable, in whole or in part, under any law, such provision or part thereof shall to that extent be deemed not to form part of these Terms but the legality, validity and enforceability of the other provisions in these Terms shall not be affected. In that event, the parties shall replace the illegal, invalid or unenforceable provision or part thereof with a provision or part thereof that is legal, valid and enforceable and that has, to the greatest extent possible, a similar effect as the illegal, invalid or unenforceable provision or part thereof, given the contents and purpose of these Terms.</span></p>
                <p className="p3 ft7"><span className="ft1">11.4.</span><span className="ft33">These Terms, including any incorporated policies, constitute the entire agreement and understanding of the parties with respect to its subject matter and replaces and supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter. Nothing in this clause limits your rights as a consumer which cannot be excluded under applicable law.</span></p>
            </div>
            <div id="page_7">
                <div id="p7dimg1">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAocAAAAZCAIAAADMhROvAAAAgklEQVR4nO3VMQEAIAzAMMC/p1njwQM9EgX9umdmAQAB53cAAPC4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVLgyAFS4MgBUuDIAVFzU5QJv52dqgAAAAABJRU5ErkJggg==" id="p7img1" /></div>


                <p className="p49 ft0">TERMS GOVERNING USERS OF SERVICE</p>
                <p className="p50 ft3"><span className="ft1">1.</span><span className="ft2">Relationship between Users</span></p>
                <p className="p51 ft6"><span className="ft1">1.1.</span><span className="ft4">These Terms of Use (“</span><span className="ft5">Terms</span>”) govern the relationship between <span className="ft5">Users </span>connected through the <span className="ft5">Services </span>made available by One Source Karma Yoga Pvt. Ltd., a private limited company established in India, having its registered office at #859, 17th G Main Road, 5th Block Rajajinagar, Bangalore 560010 (“<span className="ft5">Karma Yoga</span>”).</p>
                <p className="p52 ft7"><span className="ft1">1.2.</span><span className="ft9">Any organization or person who uses the Service provided by Karma Yoga is a </span><span className="ft3">“User”. </span>The User may use the <span className="ft3">Services </span>to procure and/or offer <b>trade-Services</b></p>
                <p className="p53 ft7"><span className="ft1">1.3.</span><span className="ft9">Karma Yoga only provides a Platform for all Users to post their requirements and enable finding a matching partner. The relationship between the partners is exclusively and solely the responsibility of the Users. Karma Yoga’s role is restricted to providing the platform, finding a match and connecting the partners, and arranging an </span><b>e-contract</b> between the User partners.</p>
                <p className="p54 ft3"><span className="ft14">2.</span><span className="ft15">Services</span></p>
                <p className="p55 ft7"><span className="ft1">2.1.</span><span className="ft9">Karma Yoga provides a platform for Users to list their requirements for hiring or for offering trade- Services.</span></p>
                <p className="p56 ft6"><span className="ft1">2.2.</span><span className="ft17">Users will be required to enter, on the platform, their requirements of (for hiring or for offering trade- Services) (i) </span><b>trade-service</b> category (ii) location (iii) quantity of personnel, and (iv) preferred rate/price per predetermined unit of measurement.</p>
                <p className="p57 ft7"><span className="ft1">2.3.</span><span className="ft18">Users can view competitive prices from both those hiring and those offering </span><b>trade-Services.</b></p>
                <p className="p58 ft7"><span className="ft1">2.4.</span><span className="ft18">Users may also make multiple entries for different </span><b>trade-service</b> categories and locations.</p>
                <p className="p59 ft7"><span className="ft1">2.5.</span><span className="ft18">Until a match is found Users have the opportunity to change their preferred rate.</span></p>
                <p className="p60 ft7"><span className="ft1">2.6.</span><span className="ft18">The rate will remain valid for a period of four weeks or until the User modifies his entry whichever is earlier.</span></p>
                <p className="p56 ft7"><span className="ft1">2.7.</span><span className="ft22">The Karma Yoga platform will deploy algorithms to match requirements posted by Users wanting to hire </span><b>trade-Services</b> with those of Users wanting to offer their <b>trade-Services.</b> This is an automatic process and once all variables are matched, the system generates an <b>e-contract</b> between the two User Partners whose requirements have been met.</p>
                <p className="p54 ft3"><span className="ft14">3.</span><span className="ft15">Terms governing the relationship between Users.</span></p>
                <p className="p58 ft7"><span className="ft1">3.1.</span><span className="ft9">By proceeding with using the </span><span className="ft3">Services </span>provided by Karma Yoga, the User confirms that:</p>
                <p className="p61 ft6"><span className="ft1">3.1.1.</span><span className="ft34">They have read and are bound by these entire terms and conditions of use including these that govern the relationships between Users whose requirements are matched “</span><span className="ft5">User Partner</span>”</p>
                <p className="p62 ft7"><span className="ft1">3.1.2.</span><span className="ft27">They Warrant the accuracy of the information provided</span></p>
                <p className="p63 ft7"><span className="ft1">3.1.3.</span><span className="ft27">The user (if ‘working for’ or ‘representative of’ an organization) is legally authorized to register his firm on the application and is bound to honor the outcome of the app process.</span></p>
                <p className="p64 ft7"><span className="ft1">3.1.4.</span><span className="ft27">The user will be required to register on the Platform before he can list his requirement. He will be needed to provide information such as Name of Organization, Name of authorized User, mobile number, GST number/ Aadhar number/ PAN number (as applicable) and any other information that may be required to establish the legal entity of the organization or person.</span></p>
                <p className="p65 ft7"><span className="ft1">3.1.5.</span><span className="ft27">The users acknowledge that Karma Yoga reserves the right to validate the information provided on the platform.</span></p>
                <p className="p66 ft7"><span className="ft1">3.1.6.</span><span className="ft27">All information entered on the platform represent genuine requirements for </span><b>trade-Services</b></p>
            </div>
            <div id="page_8">


                <p className="p67 ft6"><span className="ft1">3.1.7.</span><span className="ft28">The user acknowledges that the process of order matching is an automated process and will provide a match as soon as the conditions of </span><b>trade-Services</b> category, location and price are matched.</p>
                <p className="p68 ft7"><span className="ft1">3.1.8.</span><span className="ft27">While every effort will be made to match the </span><b>offer-quantity</b> of the Buyer and Seller, the app works on the priority of price match and consequently there is a possibility that a full offer- quantity match may not become possible.</p>
                <p className="p44 ft7"><span className="ft1">3.1.9.</span><span className="ft27">The parties agree that part fulfillment of the </span><b>offer-quantity</b> is a distinct possibility and that their offer cannot be voided by this part fulfillment.</p>
                <p className="p69 ft7"><span className="ft1">3.1.10.</span><span className="ft35">The balance </span><b>offer-quantity</b> post part fulfillment will remain posted on the Platform for future matches on the conditions of category, location and price.</p>
                <p className="p47 ft7"><span className="ft1">3.1.11.</span><span className="ft36">When a matching price is found (to the </span><b>price-offer</b> made by the user), the app will automatically generate an <b>e-contract</b> which will be binding on the user to honor.</p>
                <p className="p70 ft6"><span className="ft1">3.1.12.</span><span className="ft37">By accepting the Terms and Conditions, the User consents, of his own free will and without any coercion, to enter into an </span><b>e-contract</b> with the User Partner. He also confirms that the information provided (Name/GST No/Aadhar No/Contact person name and mobile number, etc.,) can be used to enter into an <b>e-contract</b> when a suitable match has been found.</p>
                <p className="p71 ft6"><span className="ft1">3.1.13.</span><span className="ft37">By accepting these terms and conditions, the user also confirms that this approval constitutes an approval for the use of the information provided as a digital signature which can be appended to the </span><b>e-contract.</b></p>
                <p className="p72 ft6"><span className="ft1">3.1.14.</span><span className="ft38">the User will enter into a contract with the counterparty assigned by the automatic matching process of the Platform, and will honor the articles of the </span><b>e-contract.</b> The articles of the <b>e-contract</b> will include the terms on which the Users are bound to each other as listed below under the heading of “Pricing Principles”</p>
                <p className="p2 ft7"><span className="ft1">3.2.</span><span className="ft18">Karma Yoga takes no liability for the User Partner not complying with the </span><b>e-contract</b> and the user has to indemnify Karma Yoga from all legal proceedings arising out of the <b>non-performance</b> of the <b>e-contract.</b></p>
                <p className="p44 ft7"><span className="ft1">3.2.1.</span><span className="ft27">In the event of such </span><b>non-compliance,</b> the User has to notify Karma Yoga within 48 hours of such <b>non-compliance</b></p>
                <p className="p69 ft7"><span className="ft1">3.2.2.</span><span className="ft39">While Karma Yoga cannot enforce the </span><b>e-contract,</b> Karma Yoga will endeavor to negotiate with the Partner to get them to comply</p>
                <p className="p47 ft7"><span className="ft1">3.2.3.</span><span className="ft39">Failing this, Karma Yoga will reinstate the requirements and strive to find an early match through the Platform</span></p>
                <p className="p5 ft7"><span className="ft1">3.2.4.</span><span className="ft36">However Karma Yoga cannot guarantee or be held liable for replacement.</span></p>
                <p className="p11 ft7"><span className="ft1">3.3.</span><span className="ft18">The user may approach the appropriate legal authority to enforce the remedies available to him in the </span><b>e-contract</b> in case of any dispute.</p>
                <p className="p73 ft3"><span className="ft1">4.</span><span className="ft15">Pricing Basis and Information</span></p>
                <p className="p11 ft7"><span className="ft1">4.1.</span><span className="ft9">The price being entered in the platform for any </span><b>trade-service</b> category and location is the “<span className="ft3">rate</span>” the User is willing to offer and is based on the conditions that follow.</p>
                <p className="p22 ft7"><span className="ft1">4.2.</span><span className="ft18">The user acknowledges the following with regard to the pricing being entered by him in the platform:</span></p>
                <p className="p74 ft6"><span className="ft1">4.2.1.</span><span className="ft28">Rate/price being entered/amended in the application is binding (until amended by the user). Once a matching price has been found, no further amendments will be possible.</span></p>
                <p className="p75 ft7"><span className="ft1">4.2.2.</span><span className="ft36">Rate/price being entered/amended is for the unit of measurement stated in the app for the specific category</span></p>
                <p className="p76 ft7"><span className="ft1">4.2.3.</span><span className="ft36">Rate/price being entered/amended is based on the conditions of price specifically stated below</span></p>
            </div>
            <div id="page_9">


                <p className="p77 ft7"><span className="ft1">4.3.</span><span className="ft18">The Rate being offered on the platform is the net wages due (payable or receivable) for the trade- Services rendered.</span></p>
                <p className="p78 ft7"><span className="ft1">4.4.</span><span className="ft18">The net “rate” that is offered on the platform and carried forward to the </span><b>e-contract</b> is <span className="ft3">exclusive </span>of the following facilities. The below facilities have to be provided additionally and free of cost by the hirer of the <b>trade-service.</b> If not provided the partner offering the <b>trade-Services</b> will need to be compensated, by the hirer, the cost of the facilities not provided. The compensation may be as per mutually agreed terms between the User Partners.</p>
                <p className="p79 ft6"><span className="ft1">4.4.1.</span><span className="ft40">Weather proof accommodation - minimally consisting of (a) hard floor, (b) corrugated GI Sheeting walls from floor to ceiling, (c) Sloped ceiling of GI Sheeting (d) Separate shared toilets and bathing arrangements (e) Separate kitchen with adequate space and provision for cooking (f) rainwater drainage system to keep the premises dry (g) maintenance and upkeep of the labour camp including regular and periodic cleaning, garbage collection, treatment against mosquitoes and other infestation (h)garbage collection and disposal (j) Shared Accommodation with space per person being a minimum of 3 m2</span></p>
                <p className="p80 ft6"><span className="ft1">4.4.2.</span><span className="ft41">Personal Protective equipment as required by the project - replacement of PPE will be once every 6 months. If any replacement is required to PPE, for any reason whatsoever, within six months from the date of issue, the replacement will be on a chargeable basis.</span></p>
                <p className="p72 ft6"><span className="ft1">4.4.3.</span><span className="ft41">All tools and tackles and equipment required to provide the </span><b>trade-Services.</b> Any tools and tackles provided by the seller will need to be compensated by mutual agreement. The tools, tackles and equipment will remain the property of the hirer.</p>
                <p className="p81 ft7"><span className="ft1">4.4.4.</span><span className="ft36">Lifts, hoists, cranes and other plant and machinery required for the effective provision of </span><b>trade-Services.</b></p>
                <p className="p82 ft7"><span className="ft1">4.4.5.</span><span className="ft36">All material required to carry out the </span><b>trade-Services</b> including additional wastage allowances (as per IS Code of Practice or CPWD norms or commonly accepted industry norms) delivered within 100 m of the place of work.</p>
                <p className="p83 ft6"><span className="ft1">4.4.6.</span><span className="ft41">Disposal of debris from collection points within 100 m of the work space. Any housekeeping requirements beyond or in addition to this will need to be compensated by mutual agreement.</span></p>
                <p className="p81 ft7"><span className="ft1">4.4.7.</span><span className="ft39">Transportation to and from the accommodation camp to worksite if distance is more than 1 KM.</span></p>
                <p className="p8 ft7"><span className="ft1">4.4.8.</span><span className="ft36">Ticket from current location to work location (one way) or costs thereof</span></p>
                <p className="p5 ft7"><span className="ft1">4.4.9.</span><span className="ft36">Provident Fund payments (as required statutorily)</span></p>
                <p className="p84 ft7"><span className="ft1">4.4.10.</span><span className="ft21">Employee State Insurance payments for health coverage (as required statutorily)</span></p>
                <p className="p5 ft7"><span className="ft1">4.4.11.</span><span className="ft42">All other statutory requirements (BOCW, Labour Licenses, etc.)</span></p>
                <p className="p84 ft7"><span className="ft1">4.4.12.</span><span className="ft21">GST or other statutory levies and taxes, where applicable</span></p>
                <p className="p73 ft7"><span className="ft1">4.5.</span><span className="ft18">The net </span><b>offer-price</b> is <span className="ft3">inclusive </span>of the following requirements</p>
                <p className="p76 ft7"><span className="ft1">4.5.1.</span><span className="ft27">Services will match or exceed the quality requirements as per IS Codes of Practice for the respective </span><b>trade-service.</b></p>
                <p className="p47 ft7"><span className="ft1">4.5.2.</span><span className="ft36">Wastages will be restricted to variation limits as per respective IS Codes of Practice or CPWD norms or standard industry practices (any variation may be mutually agreed upon.)</span></p>
                <p className="p70 ft6"><span className="ft1">4.5.3.</span><span className="ft41">Personal and Corporate Income taxes applicable (and deductions as per statutory requirements are applicable on payment). The organization and individuals will have to separately comply with applicable tax rules.</span></p>
                <p className="p80 ft7"><span className="ft1">4.5.4.</span><span className="ft36">Food costs (to be borne by the </span><b>trade-service</b> provider). If the hirer provides any food facility the two parties may mutually agree to a deduction from the <b>offer-price</b> for provision of</p>
            </div>
            <div id="page_10">


                <p className="p85 ft7">food, provisions, cook, <b>cooking-gas</b> and gas stove, etc. The seller has the sole right to accept or reject such costs for food provision.</p>
                <p className="p44 ft7"><span className="ft1">4.5.5.</span><span className="ft36">General housekeeping of work area and collection and stacking of debris at location within 100 m of the </span><b>work-spot;</b> but not of disposing debris to any point beyond.</p>
                <p className="p69 ft6"><span className="ft1">4.5.6.</span><span className="ft41">Working at ground floor level +/- 4 floors/level. Works at lower and higher levels may be mutually agreed upon by the Users.</span></p>
                <p className="p29 ft6"><span className="ft1">4.6.</span><span className="ft17">The User agrees that the basis of his rate is based on the following method for calculation of work done and payment to be made. Any deviation may be mutually agreed but at the sole discretion of the trade- service provider.</span></p>
                <p className="p81 ft7"><span className="ft1">4.6.1.</span><span className="ft27">The unit of measurement for payment will be the unit of measurement specified on the platform and also carried forward into the </span><b>e-contract.</b></p>
                <p className="p70 ft6"><span className="ft1">4.6.2.</span><span className="ft41">Measurement of works done should be as per the respective IS Codes of Practice for the specific </span><b>trade-service</b> being measured - notwithstanding any local practice (unless otherwise mutually agreed by both the parties). Measurement shall be carried out when both parties are present and as soon as practical, but not later than after defined portions of the work are completed.</p>
                <p className="p72 ft6"><span className="ft1">4.6.3.</span><span className="ft41">The measurement of the quantum of work is to be carried out jointly by representatives of both parties and agreed. Measurements, as noted earlier, have to comply with the principles set out in the appropriate IS.</span></p>
                <p className="p46 ft7"><span className="ft1">4.6.4.</span><span className="ft36">Payment due to </span><b>trade-service</b> provider for work done will be calculated as the product of the agreed rate and the unit of measurement (for each trade activity.)</p>
                <p className="p12 ft7"><span className="ft1">4.7.</span><span className="ft22">The minimum duration of work for providing trade services will be one month. The Rate will be based on this minimum period of employment. If an Employer/procurer/hirer of </span><b>trade-services</b> cannot offer the duration of one month’s services, he will need to compensate the <b>trade-service</b> provider for the balance duration for which the <b>service-trade</b> provider is idle. The calculation for such payment will be as noted under payments for idle works. Any variation on this price can be mutually agreed upon.</p>
                <p className="p86 ft7"><span className="ft1">4.8.</span><span className="ft18">If the service provider is unable to deploy resources for work, (for any reason not attributable to the service provider), the Employer/procurer/hirer will need to reimburse the service provider for the idle time. The amount of payment (per resource per day) for idle time is to be determined by the rate multiplied by the average daily productivity (as per respective IS Codes of Practice or CPWD norms or standard industry practices (any variation may be mutually agreed upon). The service provider will need to be reimbursed for the number of resources provided who remain idle as well as the duration for which they remain idle.</span></p>
                <p className="p87 ft7"><span className="ft1">4.9.</span><span className="ft18">The user also confirms that the rate used is based on the following payment terms.</span></p>
                <p className="p70 ft6"><span className="ft1">4.9.1.</span><span className="ft28">Payment of the wages will be done by the Hirer to the service provider - who could be an individual, a group of individuals, or a contractor. The mode of payment transfer may be mutually agreed.</span></p>
                <p className="p10 ft7"><span className="ft1">4.9.2.</span><span className="ft36">Payment for work shall be made in multiple stages.</span></p>
                <p className="p70 ft7"><span className="ft1">4.9.3.</span><span className="ft36">This will include a </span><b>one-time</b> payment, as reimbursement on reporting to work, to cover the cost of travel from the current location of the <b>trade-service</b> provider to the place of employment – at cost, on the production of a valid travel document. This payment can be deducted from the first payment towards works done.</p>
                <p className="p82 ft6"><span className="ft1">4.9.4.</span><span className="ft41">Periodic (daily, weekly, fortnightly, monthly) advance payment to cover the cost of living expenses. This amount is to cover living expenses over periods for which wages do not become due for payment. This is to be set off /recovered from wages for the corresponding period. The amounts and periods of such periodic </span><b>cost-of-living</b> payments to be mutually agreed by both parties, based on the period of regular wages.</p>
            </div>
            <div id="page_11">
                <p className="p88 ft6"><span className="ft1">4.9.5.</span><span className="ft41">Periodic wages (weekly, fortnightly, monthly) to be paid latest by the 7th day post completion of the period (except at the termination or conclusion of the contract, when payment has to be made within 2 days of cessation of the contract) - or as specified from time to time by the Statutory Authority.</span></p>
                <p className="p89 ft6"><span className="ft1">4.9.6.</span><span className="ft41">The periodic wage is determined as the product of (a) the quantum of work done in the period and (b) the rate per unit of work agreed on the app platform and contracted to by the two parties.</span></p>
                <p className="p89 ft6"><span className="ft1">4.9.7.</span><span className="ft43">Any service that does not meet the quality requirements of the IS Codes of Practice may be excluded from the measurements until such defects are rectified and thereafter qualifies for measurement and payment. This is subject to the condition that such defects are notified to the service provider with clear and objective records of the defects to be rectified.</span></p>
                <p className="p90 ft7"><span className="ft1">4.9.8.</span><span className="ft36">The only deduction allowable from the wage is retention for latent defects which may appear within the defects liability period - this defects liability period considered in the </span><b>offer-price</b> is one year from the date of project completion. (Project completion to be reckoned as the date of first occurrence of any one of the following - (a) issue of completion certificate by the Owner of the Project (b) receipt of Occupation Certificate issued by the statutory body (c) Partial occupation of the project (d) the project is ready for its intended purpose and or (e) the project has reached the stage of beneficial occupation.</p>
                <p className="p91 ft6"><span className="ft1">4.9.9.</span><span className="ft41">Maximum deductions for retention (pending verification of quality and maintenance of defects) cannot exceed 5% of the calculated wage. Such retention needs to be repaid within one year of the completion of the project (to be reckoned as noted above). Any cost recovery towards rectification of defects can only be done only if the service provider has been duly notified of the defect and given an opportunity and reasonable time (at minimum two weeks) to rectify the same..</span></p>
            </div>
        </div>
    )
}

export default CommonTerms